/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { notificationContainer } from 'utils/notificationContainer';
import {
	TGroupsRequestPayload,
	TGroupsResponsePayload,
	TSetGroupControlRequestPayload,
	TUpdatesGroupRequestPayload,
	TGroupsFilterRequestPayload,
	TGroupsFilterResponsePayload,
	TGroupFilterValuesResponsePayload,
	TGroupEditRequestPayload,
} from './types';
import {
	assetsInitState,
	getGroupsRequest,
	getGroupsSuccess,
	resetGroupControlRequest,
	setGroupControlRequest,
	updateAllGroupsRequest,
	updateAllUsersRequest,
	updateGroupRequest,
	updateUsersRequest,
	getGroupsFilterRequest,
	getGroupsFilterSuccess,
	getGroupsFilterValuesRequest,
	getGroupsFilterValuesSuccess,
	editGroupRequest,
	editGroupSuccess,
} from './reducer';

function* getGroupsWorker(action: PayloadAction<TGroupsRequestPayload>) {
	const { payload } = action;

	try {
		const response: TGroupsResponsePayload = yield call(api.groups.getGroups, payload);
		yield put(getGroupsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(assetsInitState());
	}
}

function* updateGroupsWorker(action: PayloadAction<TUpdatesGroupRequestPayload>) {
	const { payload } = action;

	try {
		yield call(api.groups.updateGroup, payload);
		window.location.reload();
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(assetsInitState());
	}
}
function* updateUsersWorker(action: PayloadAction<TUpdatesGroupRequestPayload>) {
	const { payload } = action;

	try {
		yield call(api.groups.updateUsers, payload);
		// yield put(getGroupsRequest({}));
		window.location.reload();
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(assetsInitState());
	}
}
function* updateAllGroupsWorker() {
	try {
		yield call(api.groups.updateAllGroups);
		// yield put(getGroupsRequest({}));
		window.location.reload();
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(assetsInitState());
	}
}
function* updateAllUsersWorker() {
	try {
		yield call(api.groups.updateAllUsers);
		// yield put(getGroupsRequest({}));
		window.location.reload();
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(assetsInitState());
	}
}

function* setGroupControlWorker(action: PayloadAction<TSetGroupControlRequestPayload>) {
	const { payload } = action;

	try {
		yield call(api.groups.setGroupControl, payload);
		// yield put(getGroupsRequest({}));
		window.location.reload();
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(assetsInitState());
	}
}
function* resetGroupControlWorker(action: PayloadAction<TSetGroupControlRequestPayload>) {
	const { payload } = action;

	try {
		yield call(api.groups.resetGroupControl, payload);
		// yield put(getGroupsRequest({}));
		window.location.reload();
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(assetsInitState());
	}
}

function* getGroupsFilterWorker(action: PayloadAction<TGroupsFilterRequestPayload>) {
	const { payload } = action;

	try {
		const response: TGroupsFilterResponsePayload = yield call(api.groups.getGroupsFilter, payload);
		yield put(getGroupsFilterSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(assetsInitState());
	}
}

function* getGroupsFilterValuesWorker() {
	try {
		const response: TGroupFilterValuesResponsePayload = yield call(
			api.groups.getGroupsFilterValues,
		);
		yield put(getGroupsFilterValuesSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(assetsInitState());
	}
}

function* editGroupWorker(action: PayloadAction<TGroupEditRequestPayload>) {
	const { payload } = action;

	try {
		yield call(api.groups.editGroup, payload);
		yield put(editGroupSuccess());
		notificationContainer('Группа успешно изменена', 'success');
		setTimeout(() => {
			window.location.reload();
		}, 1000);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(assetsInitState());
	}
}

export function* groupsSaga() {
	yield takeEvery(getGroupsRequest.type, getGroupsWorker);
	yield takeEvery(updateGroupRequest.type, updateGroupsWorker);
	yield takeEvery(updateUsersRequest.type, updateUsersWorker);
	yield takeEvery(updateAllGroupsRequest.type, updateAllGroupsWorker);
	yield takeEvery(updateAllUsersRequest.type, updateAllUsersWorker);
	yield takeEvery(setGroupControlRequest.type, setGroupControlWorker);
	yield takeEvery(resetGroupControlRequest.type, resetGroupControlWorker);
	yield takeEvery(getGroupsFilterRequest.type, getGroupsFilterWorker);
	yield takeEvery(getGroupsFilterValuesRequest.type, getGroupsFilterValuesWorker);
	yield takeEvery(editGroupRequest.type, editGroupWorker);
}
