import { Alert, AlertColor } from '@mui/material';
import { FC } from 'react';
import { IToastifyComponent } from './types';
// ==========================================:
const ToastifyComponent: FC<IToastifyComponent> = ({ message, toastProps }) => {
	return (
		<Alert
			variant="filled"
			severity={(toastProps?.type ?? 'info') as AlertColor}
			sx={{ width: '100%', height: '100%' }}
		>
			{message}
		</Alert>
	);
};

export default ToastifyComponent;
