import { Page } from 'layouts/Page';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { getIsAuth } from 'redux/reducers/auth/selectors';
import { LoginPage } from 'pages/LoginPage';
import { NPS } from 'components/NPS';
import { Navigate } from 'react-router';

export const NPSPage: FC = () => {
	const ifAuth = useSelector(getIsAuth);

	return ifAuth ? (
		<Page title="NPSPage">
			<NPS />
		</Page>
	) : (
		<Navigate to="/login" replace />
	);
};
