import { createSelector } from '@reduxjs/toolkit';
import { TStoreState } from 'redux/types';
import {
	TGroupsStore,
	TGroupsData,
	TGroupsFilterResponsePayload,
	TGroupFilterValuesResponsePayload,
} from './types';

// ==========================================:
const getGroupsState = (state: TStoreState): TGroupsStore => state.groups;
export const getGroups = createSelector([getGroupsState], (groups: TGroupsStore) => groups);

// ====================================================:
export const getGroupsIsLoad = createSelector(
	[getGroups],
	(groups: TGroupsStore): boolean => groups.groupsLoader,
);

// ====================================================:
export const getGroupsList = createSelector(
	[getGroups],
	(groups: TGroupsStore): TGroupsData => groups.groups,
);

// ====================================================:

export const getGroupsFilterIsLoad = createSelector(
	[getGroups],
	(groups: TGroupsStore): boolean => groups.groupsFilterLoader,
);

// ====================================================:

export const getGroupsFilterList = createSelector(
	[getGroups],
	(groups: TGroupsStore): TGroupsFilterResponsePayload => groups.groupsFilter,
);
// ==========================================

export const getGroupsFilterValues = createSelector(
	[getGroups],
	(groups: TGroupsStore): TGroupFilterValuesResponsePayload => groups.groupFilterValues,
);
