import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import {
	TNewTelegramConnectionRequest,
	TNewTelegramConnectionResponseData,
} from 'redux/reducers/userProfile/types';
import { TUserProfileApi } from './types';

export const userProfile: TUserProfileApi = {
	getConnectedTelegram: () =>
		http.get(endpoint.userProfile.CONNECTED_TELEGRAM).then((response) => response.data),
	getNewTelegramConnection: (params) =>
		http
			.post(endpoint.userProfile.NEW_TELEGRAM_CONNECTION, null, {
				params,
			})
			.then((response) => response.data),
	deleteTelegramConnection: () => http.delete(endpoint.userProfile.DELETE_TELEGRAM_CONNECTION),
	getTelegramConnectionStatus: (params) =>
		http
			.get(endpoint.userProfile.TELEGRAM_CONNECTION_STATUS, {
				params,
			})
			.then((response) => response.data),
	telegramDoLogin: (payload) => http.post(endpoint.userProfile.TELEGRAM_DO_LOGIN, payload),
};
