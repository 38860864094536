/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import {
	getOnboardingMessagesRequest,
	onboardingMessagesTrySendRequest,
	postOnboardingMessagesRequest,
} from 'redux/reducers/onboardingMessages/reducer';
import { useDispatch, useSelector } from 'react-redux';
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive';
import { useLocation, useParams } from 'react-router';
import queryString from 'query-string';
import {
	getOnboardingMessages,
	trySendMessage,
	trySendMessageIsLoad,
} from 'redux/reducers/onboardingMessages/selectors';
import { Chip, FormControl, MenuItem, Select } from '@mui/material';
import { TPostOnboardingMessagesRequestType } from 'redux/reducers/onboardingMessages/types';
import ReplyIcon from '@mui/icons-material/Reply';
import { history } from 'routes/history';
import { escapeMarkdown, unEscapeMarkdown } from './utils';
import { TextEditor } from './TextEditor';
import { TrySendDialog } from './TrySendDialog';
import { ConvertMarkdownToHtml } from './ConvertMarkdownToHtml';

export const OnboardingMessagesEditor: FC = () => {
	const params = useParams<{ id: string }>();
	const messagesId = params.id;

	const { search } = useLocation();
	const { type } = queryString.parse(search);
	const dispatch = useDispatch();
	const [text, setText] = useState('');
	const [title, setTitle] = useState('');
	const onboardingMessages = useSelector(getOnboardingMessages);
	const [id, setId] = useState(0);
	const trySendMessages = useSelector(trySendMessage);
	const trySendMessagesIsLoad = useSelector(trySendMessageIsLoad);
	const [messageRecipient, setMessageRecipient] = useState<TPostOnboardingMessagesRequestType>(
		Number(type) as TPostOnboardingMessagesRequestType,
	);

	const handleSetMessageRecipient = (recipient: TPostOnboardingMessagesRequestType) => {
		setMessageRecipient(recipient);
	};

	useEffect(() => {
		if (messagesId) {
			setId(+messagesId);
			const message = onboardingMessages.onboardingMessages?.forNewUsers?.find(
				(item) => item.id === +messagesId,
			);
			setText(unEscapeMarkdown(message?.body ?? ''));
			setTitle(unEscapeMarkdown(message?.title ?? ''));
		} else setId(0);
	}, [messagesId]);

	useEffect(() => {
		dispatch(getOnboardingMessagesRequest({}));
	}, [dispatch]);

	const sendMessageHandler = () => {
		dispatch(
			postOnboardingMessagesRequest({
				id,
				title: escapeMarkdown(title),
				body: escapeMarkdown(text),
				fullBody: `${escapeMarkdown(title)} ${escapeMarkdown(text)}`,
				type: messageRecipient,
			}),
		);
	};

	// const escaping = (txt: string) => {
	// 	const regStr = '\\d\\.\\d';
	// 	const regexp = new RegExp(regStr);
	// 	let tmp;
	// 	// let tmp = txt.replaceAll('.', '\\.');
	// 	tmp = tmp.replaceAll('-', '\\-');
	// 	tmp = tmp.replaceAll('(', '\\(');
	// 	tmp.replaceAll(')', '\\)');
	// 	return tmp;
	// };

	const [openTrySendDialog, setOpenTrySendDialog] = useState(false);
	const handleClosTrySendDialog = () => {
		setOpenTrySendDialog(false);
	};

	const trySendMessageHandler = () => {
		dispatch(
			onboardingMessagesTrySendRequest({
				messageText: `*${escapeMarkdown(title)}*\n\n${escapeMarkdown(text)}`,
			}),
		);
		setOpenTrySendDialog(true);
	};

	const handleReplayHistory = () => {
		history.back();
	};

	return (
		<>
			<div>
				<Chip
					onClick={handleReplayHistory}
					icon={<ReplyIcon color="primary" />}
					label="Назад"
					sx={{ mb: '20px', my: 2, bgcolor: '#00507a', color: '#66cbff' }}
				/>
				<h1 style={{ color: '#66cbff', marginBottom: '10px' }}>Редактор сообщения онбординга</h1>
			</div>

			<div className="basic-section">
				<div className="container">
					<div className="voting">
						<div className="make-proposal">
							<div className="make-proposal-content">
								<div className="make-proposal-block">
									<div className="make-proposal-block__title">
										<p>Заголовок</p>
									</div>
									<div className="input input--type3">
										<input
											className="input-item"
											type="text"
											placeholder=""
											onChange={(e) => setTitle(e.target.value)}
											value={(title && unEscapeMarkdown(title)) || ''}
										/>
									</div>
								</div>
								<TextEditor setText={setText} text={text} />
								<ConvertMarkdownToHtml textPreview={text} titlePreview={title} />
								<div />
								<div
									style={{
										display: 'grid',
										gridGap: '20px',
										gridTemplateColumns: '1fr 1fr 1fr',
										marginTop: '30px',
									}}
								>
									<FormControl size="small">
										<Select
											labelId="demo-select-small"
											id="demo-select-small"
											value={messageRecipient}
											label="Age"
											onChange={(e) =>
												handleSetMessageRecipient(
													Number(e.target.value) as TPostOnboardingMessagesRequestType,
												)
											}
											style={{
												color: 'white',
												backgroundColor: '#0078b6',
												borderRadius: '12px',
												height: '48px',
												fontWeight: '900',
											}}
										>
											<MenuItem value={0}>Новым</MenuItem>
											<MenuItem value={1}>Существующим</MenuItem>
										</Select>
									</FormControl>
									<button
										type="button"
										className="button button--full-width button--medium-height"
										onClick={sendMessageHandler}
									>
										Сохранить <SendAndArchiveIcon style={{ marginLeft: '20px' }} />
									</button>
									<button
										type="button"
										className="button button--full-width button--medium-height"
										onClick={trySendMessageHandler}
									>
										Проверить в боте
										<SendIcon style={{ marginLeft: '20px' }} />
									</button>
								</div>

								{/* <VotingOptionsByDefaultSet /> */}
							</div>
							{/* <div className="make-proposal-bar">
								<VotingActions text={text} title={title} />
							</div> */}
						</div>
					</div>
				</div>
			</div>
			<TrySendDialog
				text={trySendMessages?.message ?? ''}
				open={openTrySendDialog && !trySendMessagesIsLoad && !!trySendMessages?.message}
				setClose={handleClosTrySendDialog}
			/>
		</>
	);
};
