import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
// import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { notificationContainer } from 'utils/notificationContainer';
import { api, responseErrors } from 'services';
import {
	loginRequest,
	loginSuccess,
	logoutRequest,
	authInitState,
	getPermissionsRequest,
	getPermissionsSuccess,
	telegramLoginRequest,
} from './reducer';
import {
	IAdminAuthUserResponseData,
	ILoginRequestPayload,
	IAdminLogoutData,
	TAuthPermissionsResponsePayload,
	TTelegramAuthRequestPayload,
} from './types';

// =============================================================:
function* loginRequestWorker(action: PayloadAction<ILoginRequestPayload>) {
	const { payload } = action;

	try {
		// yield put(showLoading());
		const response: IAdminAuthUserResponseData = yield call(api.auth.login, payload);

		yield put(loginSuccess(response));
		yield put(getPermissionsRequest());
	} catch (error) {
		console.log('loginRequestWorker error', error);

		if (axios.isAxiosError(error)) {
			if (error?.response?.data?.errors?.totp) {
				const code: string = error?.response?.data?.errors?.totp[0];
				notificationContainer(code, 'info');
				yield put(authInitState());
			} else {
				notificationContainer('The login or password is incorrect ', 'error');
				// responseErrors(error);
				yield put(authInitState());
			}
		}
	} finally {
		// yield put(hideLoading());
	}
}

// =============================================================:

function* telegramLoginRequestWorker(action: PayloadAction<TTelegramAuthRequestPayload>) {
	const { payload } = action;

	try {
		// yield put(showLoading());
		const response: IAdminAuthUserResponseData = yield call(api.auth.telegramLogin, payload);

		yield put(loginSuccess(response));
		yield put(getPermissionsRequest());
	} catch (error) {
		if (axios.isAxiosError(error)) responseErrors(error);
		yield put(authInitState());
	} finally {
		// yield put(hideLoading());
	}
}

// =============================================================:
function* logoutRequestWorker(action: PayloadAction<IAdminLogoutData>) {
	const { payload } = action;

	try {
		// yield put(showLoading());
		yield call(api.auth.logout);
		yield put(authInitState());
		payload.history.push({
			search: '',
		});
	} catch (error) {
		if (axios.isAxiosError(error)) responseErrors(error);
		yield put(authInitState());
	} finally {
		// yield put(hideLoading());
	}
}
// =============================================================:

function* getPermissionsWorker() {
	try {
		const response: TAuthPermissionsResponsePayload = yield call(api.auth.permissions);
		yield put(getPermissionsSuccess(response));

		// setTimeout(() => {
		// 	window.location.reload();
		// }, 1000);
	} catch (error) {
		if (axios.isAxiosError(error)) responseErrors(error);
		yield put(authInitState());
	}
}

// =============================================================:
export function* authSaga() {
	yield takeEvery(loginRequest.type, loginRequestWorker);
	yield takeEvery(telegramLoginRequest.type, telegramLoginRequestWorker);
	yield takeEvery(logoutRequest.type, logoutRequestWorker);
	yield takeEvery(getPermissionsRequest.type, getPermissionsWorker);
}
