import { Page } from 'layouts/Page';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { getIsAuth } from 'redux/reducers/auth/selectors';
import { LoginPage } from 'pages/LoginPage';
import { UserGroups } from 'components/UserGroups';

export const UserGroupsPage: FC = () => {
	const ifAuth = useSelector(getIsAuth);

	return ifAuth ? (
		<Page title="User Groups">
			<UserGroups />
		</Page>
	) : (
		<LoginPage />
	);
};
