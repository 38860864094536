import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { TUsersApi } from './types';

export const users: TUsersApi = {
	getUsers: (params) =>
		http.get(endpoint.users.GET_USERS, { params }).then((response) => response.data),
	getUser: (params) =>
		http.get(endpoint.users.GET_USER(params.userId), { params }).then((response) => response.data),
	getUserRandomCoffeePairs: (params) =>
		http
			.get(endpoint.users.GET_USER_RANDOM_COFFEE_PAIRS(params.userId), { params })
			.then((response) => response.data),
	setUserRandomCoffeePairs: (payload) =>
		http.post(endpoint.users.POST_USER_RANDOM_COFFEE_PAIRS(payload.originalUserId), payload),
	getUserRandomCoffeePairsFeature: (params) =>
		http
			.get(endpoint.users.GET_USER_RANDOM_COFFEE_PAIRS_FEATURE(params.userId), { params })
			.then((response) => response.data),
	deleteUserRandomCoffeePairsFeature: (params) =>
		http.delete(endpoint.users.DELETE_USER_RANDOM_COFFEE_PAIRS_FEATURE(params.id), { params }),
	changeRandomCoffee: (params) =>
		http
			.post(endpoint.users.CHANGE_RANDOM_COFFEE(params.userId), params)
			.then((response) => response.data),
	getUserGroups: (params) =>
		http
			.get(endpoint.users.GET_USER_GROUPS(params.userId), { params })
			.then((response) => response.data),
	deleteUserGroup: (params) =>
		http.delete(endpoint.users.DELETE_USER_GROUP(params.userId, params.groupId), { params }),
	getCommunityManagerList: () =>
		http.get(endpoint.users.COMMUNITY_MANAGER_LIST).then((response) => response.data),
	getChapterList: () => http.get(endpoint.users.CHAPTER_LIST).then((response) => response.data),
};
