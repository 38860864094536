import { FC, useRef, KeyboardEvent, useState } from 'react';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import { IconButton } from '@mui/material';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import StrikethroughSIcon from '@mui/icons-material/StrikethroughS';
import TextureIcon from '@mui/icons-material/Texture';
import LinkIcon from '@mui/icons-material/Link';
import { TTextEditorProps } from './types';

export const TextEditor: FC<TTextEditorProps> = ({ setText, text }) => {
	const textareaRef = useRef<any>();
	const [unorderedListMode, setUnorderedListMode] = useState(false);
	const [orderedListMode, setOrderedListMode] = useState(false);
	const [orderedListCounter, setOrderedListCounter] = useState(0);

	const [enterEnter, setEnterEnter] = useState(false);

	const setBoldHandler = () => {
		if (textareaRef?.current?.selectionStart === textareaRef?.current?.selectionEnd) {
			textareaRef.current.setRangeText(`**`);
			textareaRef.current.selectionStart += 1;
			textareaRef.current.focus();
			return;
		}
		const selected: string = textareaRef.current.value.slice(
			textareaRef.current.selectionStart,
			textareaRef.current.selectionEnd,
		);
		textareaRef.current.setRangeText(`*${selected}*`);
		textareaRef.current.selectionEnd += 1;
		textareaRef.current.selectionStart = textareaRef.current.selectionEnd;
		textareaRef.current.focus();

		setText(textareaRef.current.value);
	};

	const setItalicHandler = () => {
		if (textareaRef?.current?.selectionStart === textareaRef?.current?.selectionEnd) {
			textareaRef.current.setRangeText(`__`);
			textareaRef.current.selectionStart += 1;
			textareaRef.current.focus();
			return;
		}
		const selected: string = textareaRef.current.value.slice(
			textareaRef.current.selectionStart,
			textareaRef.current.selectionEnd,
		);
		textareaRef.current.setRangeText(`_${selected}_`);
		textareaRef.current.selectionEnd += 1;
		textareaRef.current.selectionStart = textareaRef.current.selectionEnd;
		textareaRef.current.focus();

		setText(textareaRef.current.value);
	};

	const setUnderlineHandler = () => {
		if (textareaRef?.current?.selectionStart === textareaRef?.current?.selectionEnd) {
			textareaRef.current.setRangeText(`___`);
			textareaRef.current.selectionStart += 2;
			textareaRef.current.focus();
			return;
		}
		const selected: string = textareaRef.current.value.slice(
			textareaRef.current.selectionStart,
			textareaRef.current.selectionEnd,
		);
		textareaRef.current.setRangeText(`__${selected}__`);
		textareaRef.current.selectionEnd += 1;
		textareaRef.current.selectionStart = textareaRef.current.selectionEnd;
		textareaRef.current.focus();
		setText(textareaRef.current.value);
	};

	const setStrikethroughHandler = () => {
		if (textareaRef?.current?.selectionStart === textareaRef?.current?.selectionEnd) {
			textareaRef.current.setRangeText(`~~`);
			textareaRef.current.selectionStart += 2;
			textareaRef.current.focus();
			return;
		}
		const selected: string = textareaRef.current.value.slice(
			textareaRef.current.selectionStart,
			textareaRef.current.selectionEnd,
		);
		textareaRef.current.setRangeText(`~${selected}~`);
		textareaRef.current.selectionEnd += 1;
		textareaRef.current.selectionStart = textareaRef.current.selectionEnd;
		textareaRef.current.focus();

		setText(textareaRef.current.value);
	};

	const setSpoilerHandler = () => {
		if (textareaRef?.current?.selectionStart === textareaRef?.current?.selectionEnd) {
			textareaRef.current.setRangeText(`||||`);
			textareaRef.current.selectionStart += 2;
			textareaRef.current.focus();
			return;
		}
		const selected: string = textareaRef.current.value.slice(
			textareaRef.current.selectionStart,
			textareaRef.current.selectionEnd,
		);
		textareaRef.current.setRangeText(`||${selected}||`);
		textareaRef.current.selectionEnd += 1;
		textareaRef.current.selectionStart = textareaRef.current.selectionEnd;
		textareaRef.current.focus();

		setText(textareaRef.current.value);
	};

	const setBlockQuotesHandler = () => {
		if (textareaRef?.current?.selectionStart === textareaRef?.current?.selectionEnd) {
			textareaRef.current.setRangeText(`\n>`);
			textareaRef.current.selectionStart += 2;
			textareaRef.current.focus();
			return;
		}
		const selected: string = textareaRef.current.value.slice(
			textareaRef.current.selectionStart,
			textareaRef.current.selectionEnd,
		);
		textareaRef.current.setRangeText(`\n>${selected}`);
		textareaRef.current.selectionStart = textareaRef.current.selectionEnd;
		textareaRef.current.focus();

		setText(textareaRef.current.value);
	};

	const setLinkHandler = () => {
		if (textareaRef?.current?.selectionStart === textareaRef?.current?.selectionEnd) {
			textareaRef.current.setRangeText(`[]()`);
			textareaRef.current.selectionStart += 1;
			textareaRef.current.focus();
			return;
		}
		const selected: string = textareaRef.current.value.slice(
			textareaRef.current.selectionStart,
			textareaRef.current.selectionEnd,
		);
		textareaRef.current.setRangeText(`[${selected}]()`);
		textareaRef.current.selectionEnd -= 1;
		textareaRef.current.selectionStart = textareaRef.current.selectionEnd;
		textareaRef.current.focus();
		setText(textareaRef.current.value);
	};

	// eslint-disable-next-line consistent-return
	function handleKeyPress(e: KeyboardEvent<HTMLTextAreaElement>) {
		const { key } = e;
		if (unorderedListMode && key === 'Enter') {
			if (enterEnter) {
				setEnterEnter(false);
				setUnorderedListMode(false);
				textareaRef.current.selectionStart -= 2;
				textareaRef.current.setRangeText(`\n`);
			} else textareaRef.current.setRangeText(`\n- `);
			textareaRef.current.selectionStart += 3;
			e.preventDefault();
			textareaRef.current.focus();
			setEnterEnter(true);
		} else if (orderedListMode && key === 'Enter') {
			if (enterEnter) {
				setEnterEnter(false);
				setOrderedListMode(false);
				textareaRef.current.selectionStart -= 3;
				textareaRef.current.setRangeText(`\n`);
				setOrderedListCounter(0);
			} else setOrderedListCounter(orderedListCounter + 1);
			textareaRef.current.setRangeText(`\n${orderedListCounter + 2}. `);
			textareaRef.current.selectionStart += 4;
			e.preventDefault();
			textareaRef.current.focus();
			setEnterEnter(true);
		} else setEnterEnter(false);
	}

	const setUnorderedListHandler = () => {
		setUnorderedListMode(true);
		if (textareaRef?.current?.selectionStart === textareaRef?.current?.selectionEnd) {
			textareaRef.current.setRangeText(`\n- `);
			textareaRef.current.selectionStart += 2;
			textareaRef.current.focus();
			return;
		}
		const selected: string = textareaRef.current.value.slice(
			textareaRef.current.selectionStart,
			textareaRef.current.selectionEnd,
		);
		textareaRef.current.setRangeText(`- ${selected}`);
		textareaRef.current.selectionEnd += 2;
		textareaRef.current.selectionStart = textareaRef.current.selectionEnd;
		textareaRef.current.focus();

		setText(textareaRef.current.value);
	};

	const setOrderedListHandler = () => {
		setOrderedListMode(true);
		if (textareaRef?.current?.selectionStart === textareaRef?.current?.selectionEnd) {
			textareaRef.current.setRangeText(`\n1. `);
			textareaRef.current.selectionStart += 3;
			textareaRef.current.focus();
			return;
		}
		const selected: string = textareaRef.current.value.slice(
			textareaRef.current.selectionStart,
			textareaRef.current.selectionEnd,
		);
		textareaRef.current.setRangeText(`1. ${selected}`);
		textareaRef.current.selectionEnd += 3;
		textareaRef.current.selectionStart = textareaRef.current.selectionEnd;
		textareaRef.current.focus();

		setText(textareaRef.current.value);
	};

	return (
		<div className="make-proposal-block">
			<div className="make-proposal-block__title">
				<p>Сообщение</p>
			</div>
			<div className="input">
				<p className="input__name input__name--textarea">Совет: пишите в формате Markdown!</p>
				<p className="input__name input__name--textarea">
					{/* Символы <span className="input__name input__name--character">*</span>{' '}
					<span className="input__name input__name--character">[</span>{' '}
					<span className="input__name input__name--character">]</span>{' '} */}
					{/* 
					<span className="input__name input__name--character">-</span>{' '}
					<span className="input__name input__name--character">+</span>{' '}
					<span className="input__name input__name--character">.</span>{' '}
					<span className="input__name input__name--character">,</span>{' '}
					<span className="input__name input__name--character">!</span>{' '}
					<span className="input__name input__name--character">=</span>{' '}
					<span className="input__name input__name--character">#</span>{' '}
					<span className="input__name input__name--character">-</span>{' '} */}
					{/* <span className="input__name input__name--character">(</span>{' '}
					<span className="input__name input__name--character">)</span>{' '} */}
					{/* <span className="input__name input__name--character">`</span>{' '}
					<span className="input__name input__name--character">{'>'}</span>{' '}
					<span className="input__name input__name--character">|</span>{' '}
					<span className="input__name input__name--character">{'{'}</span>{' '}
					<span className="input__name input__name--character">{'}'}</span>{' '}
					<span className="input__name input__name--character">~</span> */}
					{/* должны быть экранированы символом{' '}
					<span className="input__name input__name--character">\</span> */}
					{/* <p className="input__name input__name--textarea">
						Например: <span className="input__name input__name--character">Миру \- мир\!</span>
					</p> */}
				</p>
				<div className="input-wrapper">
					<div className="textarea textarea--make-proposal">
						<div className="textarea-edit">
							<IconButton sx={{ color: '#66cbff' }} onClick={setBoldHandler}>
								<FormatBoldIcon />
							</IconButton>
							<IconButton sx={{ color: '#66cbff' }} onClick={setItalicHandler}>
								<FormatItalicIcon />
							</IconButton>
							<IconButton sx={{ color: '#66cbff' }} onClick={setUnderlineHandler}>
								<FormatUnderlinedIcon />
							</IconButton>
							<IconButton sx={{ color: '#66cbff' }} onClick={setStrikethroughHandler}>
								<StrikethroughSIcon />
							</IconButton>
							<IconButton sx={{ color: '#66cbff' }} onClick={setSpoilerHandler}>
								<TextureIcon />
							</IconButton>
							<IconButton sx={{ color: '#66cbff' }} onClick={setLinkHandler}>
								<LinkIcon />
							</IconButton>
						</div>
						<textarea
							ref={textareaRef}
							placeholder=""
							onChange={(e) => setText(e.target.value)}
							value={text}
							onKeyPress={(e) => handleKeyPress(e)}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
