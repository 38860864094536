import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { TOnboardingMessagesApi } from './types';

export const onboardingMessages: TOnboardingMessagesApi = {
	getOnboardingMessages: (params) =>
		http
			.get(endpoint.onboardingMessages.GET_ONBOARDING_MESSAGES, { params })
			.then((response) => response.data),
	postOnboardingMessages: (payload) =>
		http
			.post(endpoint.onboardingMessages.ADD_ONBOARDING_MESSAGES, payload)
			.then((response) => response.data),
	deleteOnboardingMessages: (params) =>
		http.delete(endpoint.onboardingMessages.DELETE_ONBOARDING_MESSAGES(params.messageId)),
	onboardingMessagesTrySend: (params) =>
		http
			.post(endpoint.onboardingMessages.ONBOARDING_MESSAGES_TRYSEND, params)
			.then((response) => response.data),
	onboardingMessagesUp: (params) =>
		http.post(endpoint.onboardingMessages.ONBOARDING_MESSAGE_UP(params.messageId)),
	onboardingMessagesDown: (params) =>
		http.post(endpoint.onboardingMessages.ONBOARDING_MESSAGE_DOWN(params.messageId)),
};
