import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { FC, useEffect, useState } from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { useSelector } from 'react-redux';
import { TFilterProps } from './types';

export const Filter: FC<TFilterProps> = ({ name, items, selectedIndex, onChange }) => {
	const [options, setOptions] = useState<string[]>([]);
	useEffect(() => {
		setOptions(items);
	}, [items]);

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	// const [selectedIndex, setSelectedIndex] = useState(0);
	const open = Boolean(anchorEl);
	const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
		onChange(index);
		setAnchorEl(null);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	// useEffect(() => {
	// 	const index = options.findIndex((i) => i === selected);
	// 	setSelectedIndex(index);
	// }, [selected, options]);

	return (
		<div style={{ width: '200px', marginLeft: '10px' }}>
			<List
				component="nav"
				sx={{
					bgcolor: '#003a58',
					borderRadius: '5px',
					height: '100%',
					m: 0,
					p: '0px 5px 4px 5px',
					w: '100%',
					color: '#3683ac',
				}}
			>
				<div
					style={{
						color: '#3683ac',
					}}
				>
					{name}
				</div>

				<ListItem
					aria-expanded={open ? 'true' : undefined}
					onClick={handleClickListItem}
					sx={{ m: '-6px 0 0 0', p: 0 }}
				>
					<ListItemText primary={options[selectedIndex]} sx={{ color: 'primary.main' }} />{' '}
					{selectedIndex === 0 ? <FilterListOffIcon /> : <FilterListIcon />}
				</ListItem>
			</List>
			<Menu
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'lock-button',
					role: 'listbox',
				}}
				sx={{ left: '-4px', top: '7px', minWidth: '130px' }}
			>
				{options.map((option, index) => (
					<MenuItem
						sx={{ minWidth: '150px' }}
						key={option}
						selected={index === selectedIndex}
						onClick={(event) => handleMenuItemClick(event, index)}
					>
						{option}
					</MenuItem>
				))}
			</Menu>
		</div>
	);
};
