import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import CloseIcon from '@mui/icons-material/Close';
import { FC, useState } from 'react';
import { Loader } from 'ui/Loader';
import { CircularProgress } from '@mui/material';
import { TSearchComponentProps } from './types';

export const SearchComponent: FC<TSearchComponentProps> = ({
	onChange,
	search,
	array,
	num,
	isLoading,
	setSelectIndex,
	selectIndex,
}) => {
	// const [selectIndex, setSelectIndex] = useState<number | null>(null);
	// const handleSelect = (indx: number) => {
	// 	setSelectIndex(indx);
	// };

	return (
		<Paper component="form" sx={{ background: '#073049' }}>
			<div
				style={{
					padding: '2px 4px',
					display: 'flex',
					alignItems: 'center',
					width: 560,
					height: '60px',
				}}
			>
				<SearchIcon />{' '}
				<InputBase
					sx={{ ml: 1, flex: 1 }}
					placeholder="Поиск"
					inputProps={{ 'aria-label': 'search' }}
					value={search}
					onChange={(e) => {
						onChange(e.target.value);
					}}
				/>
				{/* <IconButton sx={{ p: '10px' }} aria-label="search">
				<SearchIcon />
			</IconButton> */}
				<Divider sx={{ height: 38 }} orientation="vertical" />
				<IconButton
					color="primary"
					sx={{ p: '10px' }}
					aria-label="directions"
					onClick={() => {
						onChange('');
						setSelectIndex(null);
					}}
					disabled={!search}
				>
					{isLoading ? <CircularProgress color="primary" size={24} /> : <CloseIcon />}
				</IconButton>
			</div>
			{!isLoading && search && (
				// <hr style={{ margin: '2px 20px', borderColor: 'rgba(0, 0, 0, 0.12)' }} />
				<Divider sx={{ m: '2px 20px' }} orientation="horizontal" />
			)}
			{!isLoading &&
				search &&
				(array?.length ?? 0) > 0 &&
				array?.map((item, index) => (
					<div
						style={{
							padding: '10px 20px',
							cursor: 'pointer',
							backgroundColor: selectIndex === index ? '#0a2535' : '#073049',
						}}
						onClick={() => setSelectIndex(index)}
					>
						{item}
					</div>
				))}
			{!isLoading && array?.length === 0 && (
				<div
					style={{
						padding: '10px 20px',
						color: '#66cbff',
						fontSize: '14px',
					}}
				>
					Никого не найдено
				</div>
			)}

			{!isLoading && search && num > 5 && (
				<div
					style={{
						padding: '10px 20px',
						color: '#66cbff',
						fontSize: '13px',
					}}
				>
					и еще {num - 5}
				</div>
			)}
			{/* {!!isLoading && (
				<div
					style={{
						padding: '10px 20px',
						color: 'gray',
						fontSize: '14px',
					}}
				>
					<CircularProgress color="primary" />
				</div>
			)} */}
		</Paper>
	);
};
