import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { FC, useEffect, useState } from 'react';
import { DialogContent } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { TUsersRequestPayload } from 'redux/reducers/users/types';
import { useDispatch, useSelector } from 'react-redux';
import { getUsersRequest, setUserRandomCoffeePairsRequest } from 'redux/reducers/users/reducer';
import { useDebounce } from 'use-debounce';
import { getUsersIsLoad, getUsersList } from 'redux/reducers/users/selectors';
import { TRandomCoffeeDialogProps } from './types';
import { SearchComponent } from './SearchComponent';

export const RandomCoffeeDialog: FC<TRandomCoffeeDialogProps> = ({ open, setClose }) => {
	const { id } = useParams<{ id: string }>();
	const [blockArray, setBlockArray] = useState(true);

	const users = useSelector(getUsersList);
	const usersIsLoad = useSelector(getUsersIsLoad);
	const [selectIndex, setSelectIndex] = useState<number | null>(null);

	const dispatch = useDispatch();

	const [searchStr, setSearchStr] = useState('');
	const [searchStrDebounce] = useDebounce(searchStr, 600);
	const handleSearchStr = (value: string) => {
		setSearchStr(value);
		if (value === '') {
			setBlockArray(true);
			setSelectIndex(null);
		}
		// const params: TUsersRequestPayload = {
		// 	pageNumber: 1,
		// 	pageSize: 5,
		// 	searchString: searchStrDebounce || undefined,
		// 	isUseRadndomCoffee: true,
		// 	isActive: true,
		// };
		// dispatch(getUsersRequest(params));
	};

	useEffect(() => {
		if (!id || !searchStrDebounce) return;
		if (!usersIsLoad) setBlockArray(false);
	}, [dispatch, id, searchStrDebounce, usersIsLoad]);

	useEffect(() => {
		if (!id || !searchStrDebounce) return;
		const params: TUsersRequestPayload = {
			pageNumber: 1,
			pageSize: 5,
			searchString: searchStrDebounce || undefined,
			isUseRadndomCoffee: true,
			isActive: true,
		};
		dispatch(getUsersRequest(params));
	}, [dispatch, id, searchStrDebounce]);

	const handleSendScheduled = () => {
		if (selectIndex === null || !users || !id) return;
		dispatch(
			setUserRandomCoffeePairsRequest({
				originalUserId: +id,
				userId: users?.data[selectIndex].id,
				sendimmediately: false,
			}),
		);
		setSearchStr('');
		setBlockArray(true);
		setSelectIndex(null);

		setClose();
	};

	const handleSendNow = () => {
		if (selectIndex === null || !users || !id) return;
		dispatch(
			setUserRandomCoffeePairsRequest({
				originalUserId: +id,
				userId: users?.data[selectIndex].id,
				sendimmediately: true,
			}),
		);
		setSearchStr('');
		setBlockArray(true);
		setSelectIndex(null);

		setClose();
	};

	return (
		<Dialog
			open={open}
			onClose={setClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			color="primary"
			sx={{
				'.MuiDialog-paper': {
					backgroundColor: '#041d2b',
					color: '#66cbff',
					borderRadius: '10px',
					backgroundImage: 'none',
					minHeight: '460px',
				},
			}}
		>
			<DialogTitle id="alert-dialog-title">Выберите пару для знакомства</DialogTitle>
			<DialogContent>
				<SearchComponent
					onChange={handleSearchStr}
					search={searchStr}
					array={!blockArray ? users?.data?.map((item) => item.nameInMobileApp) : undefined}
					num={!blockArray ? users?.totalCount ?? 0 : 0}
					setSelectIndex={setSelectIndex}
					selectIndex={selectIndex}
					isLoading={usersIsLoad}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={setClose}>Отмена</Button>
				<Button disabled={selectIndex === null} onClick={handleSendNow}>
					Отправить сразу
				</Button>
				<Button disabled={selectIndex === null} onClick={handleSendScheduled} autoFocus>
					Отправить по расписанию
				</Button>
			</DialogActions>
		</Dialog>
	);
};
