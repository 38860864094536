import { OnboardingMessagesEditor } from 'components/OnboardingMessagesEditor';
import { Page } from 'layouts/Page';
import { LoginPage } from 'pages/LoginPage';
import { FC } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { Navigate } from 'react-router';
import { getIsAuth } from 'redux/reducers/auth/selectors';

export const OnboardingMessagesEditorPage: FC = () => {
	const ifAuth = useSelector(getIsAuth);

	return ifAuth ? (
		<Page title="Redactor">
			<OnboardingMessagesEditor />
		</Page>
	) : (
		<Navigate to="/login" replace />
	);

	// return (
	// 	<Page title="Order">
	// 		<Order />
	// 	</Page>
	// );
};
