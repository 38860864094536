import { createRoot } from 'react-dom/client';
import './index.css';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { persistor, store } from 'redux/store';
import { ToastContainer } from 'react-toastify';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'react-toastify/dist/ReactToastify.css';
import './assets/styles/index.scss';

// Sentry.init({
// 	dsn: 'https://da330dbf47fd4770858306f5472f4116@sentry.atlanty.ru/4',
// 	integrations: [new BrowserTracing()],

// 	// Set tracesSampleRate to 1.0 to capture 100%
// 	// of transactions for performance monitoring.
// 	// We recommend adjusting this value in production
// 	tracesSampleRate: 1.0,
// });

const rootElement = document.getElementById('root') as HTMLElement;

const root = createRoot(rootElement); // createRoot(container!) if you use TypeScript

const theme = createTheme({
	palette: {
		mode: 'dark',
		primary: {
			// main: '#00507a',
			main: '#66cbff',
			contrastText: '#fff',
		},
		secondary: {
			main: '#fff',
			contrastText: '#fff',
		},
		// success: {
		// 	main: '#7ea13a',
		// 	contrastText: '#fff',
		// },
		info: {
			main: '#6ec1e4',
		},
	},
});

root.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<ThemeProvider theme={theme}>
				<App />
			</ThemeProvider>
			<ToastContainer />
		</PersistGate>
	</Provider>,
);
reportWebVitals();
