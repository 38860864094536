import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Box, DialogContent, Menu, MenuItem } from '@mui/material';
import { TEditDialogProps } from './types';

export const EditDialog: FC<TEditDialogProps> = ({
	open,
	setClose,
	handleEdit,
	text,
	items,
	selected,
}) => {
	const anchorEl = useRef<HTMLButtonElement>(null);
	const [openMenu, setOpenMenu] = useState(false);
	const [selectedIndex, setSelectedIndex] = useState(selected);
	const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement>, index: number) => {
		setSelectedIndex(index);
		setOpenMenu(false);
	};

	useEffect(() => {
		setSelectedIndex(selected);
	}, [selected, open]);

	return (
		<Dialog
			open={open}
			onClose={setClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			sx={{
				'& .MuiDialog-paper': {
					width: '400px',
					backgroundImage: 'none',
					backgroundColor: '#016193',
				},
			}}
		>
			<DialogTitle id="alert-dialog-title">{text}</DialogTitle>
			<DialogContent>
				<Button ref={anchorEl} onClick={() => setOpenMenu(true)}>
					{items[selectedIndex]}
				</Button>
				<Menu
					anchorEl={anchorEl.current}
					open={openMenu}
					onClose={() => setOpenMenu(false)}
					MenuListProps={{
						'aria-labelledby': 'lock-button',
						role: 'listbox',
					}}
					sx={{ left: '-4px', top: '7px', minWidth: '130px' }}
				>
					{items.map((option, index) => (
						<MenuItem
							sx={{ minWidth: '150px' }}
							key={option}
							selected={index === selectedIndex}
							onClick={(event) => handleMenuItemClick(event, index)}
						>
							{option}
						</MenuItem>
					))}
				</Menu>
			</DialogContent>
			<DialogActions>
				<Button onClick={setClose}>Отмена</Button>
				<Button onClick={() => handleEdit(selectedIndex)} autoFocus>
					Изменить
				</Button>
			</DialogActions>
		</Dialog>
	);
};
