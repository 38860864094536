import storage from 'redux-persist/lib/storage';
// import { encryptTransform } from 'redux-persist-transform-encrypt';

const persistConfig = {
	key: 'itq-tb-f',
	storage,
	transforms: [
		// encryptTransform({
		// 	secretKey: 'rT2FZ5y9f9',
		// 	onError: (error) => {
		// 		// eslint-disable-next-line no-console
		// 		console.log(error);
		// 	},
		// }),
	],
	whitelist: ['auth'],
};
export { persistConfig };
