import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { FC, useState } from 'react';
import { npsChapters, npsChaptersIsLoad } from 'redux/reducers/onboardingMessages/selectors';
import { useSelector } from 'react-redux';
import { TMultiSelectProps } from './types';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

export const MultiSelect: FC<TMultiSelectProps> = ({
	chapters,
	selectedChapters,
	setSelectedChapters,
}) => {
	// const chapters = useSelector(npsChapters);
	// const chaptersIsLoad = useSelector(npsChaptersIsLoad);
	// const [personName, setPersonName] = useState<string[]>([]);

	// const [selectedChapters, setSelectedChapters] = useState<string[]>([]);

	const handleChange = (event: SelectChangeEvent<typeof selectedChapters>) => {
		const {
			target: { value },
		} = event;
		setSelectedChapters(
			// On autofill we get a stringified value.
			typeof value === 'string' ? value.split(',') : value,
		);
	};

	return (
		<div style={{ marginTop: '20px' }}>
			<FormControl sx={{ width: '100%' }}>
				<InputLabel>Chapters</InputLabel>
				<Select
					multiple
					value={selectedChapters}
					onChange={handleChange}
					input={<OutlinedInput label="Chapters" />}
					renderValue={(selected) => selected.join(', ')}
					MenuProps={MenuProps}
				>
					{chapters?.map?.((chapter) => (
						<MenuItem key={chapter} value={chapter}>
							<Checkbox checked={selectedChapters.indexOf(chapter) > -1} />
							<ListItemText primary={chapter} />
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
};
