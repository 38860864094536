/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';
import SendIcon from '@mui/icons-material/Send';
import {
	getMailingListMessageRequest,
	onboardingMessagesTrySendRequest,
	postMailingListRequest,
	sendToAllRequest,
} from 'redux/reducers/onboardingMessages/reducer';

import { useDispatch, useSelector } from 'react-redux';
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive';
import { useParams } from 'react-router';
import {
	mailingListMessage,
	trySendMessage,
	trySendMessageIsLoad,
} from 'redux/reducers/onboardingMessages/selectors';
import { TextEditor } from 'components/OnboardingMessagesEditor/TextEditor';
import { TrySendDialog } from 'components/OnboardingMessagesEditor/TrySendDialog';
import { escapeMarkdown } from 'components/OnboardingMessagesEditor/utils';
import { ConvertMarkdownToHtml } from 'components/OnboardingMessagesEditor/ConvertMarkdownToHtml';
import { Chip, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import ReplyIcon from '@mui/icons-material/Reply';
import { history } from 'routes/history';
import {
	MAILING_LIST_SENDER_BOT,
	MAILING_LIST_SENDER_USER,
	TMailingListSender,
} from 'redux/reducers/onboardingMessages/types';
import { DateTime } from 'luxon';

export const ToAllMessageEditor: FC = () => {
	const params = useParams<{ messageId: string }>();
	const { messageId } = params;

	const dispatch = useDispatch();
	const [dateTime, setDateTime] = useState<DateTime | null>(DateTime.now());
	const [text, setText] = useState('');
	const [title, setTitle] = useState('');
	const [id, setId] = useState(0);
	const trySendMessages = useSelector(trySendMessage);
	const trySendMessagesIsLoad = useSelector(trySendMessageIsLoad);

	const [sender, setSender] = useState<TMailingListSender>(MAILING_LIST_SENDER_BOT);

	const message = useSelector(mailingListMessage);

	useEffect(() => {
		if (!messageId || messageId === '0') return;
		if (message) {
			setText(message?.body ?? '');
			setTitle(message?.title ?? '');
			setSender(message?.sender ?? MAILING_LIST_SENDER_BOT);
			if (message?.dateToSend) {
				setDateTime(DateTime.fromISO(message.dateToSend));
			} else setDateTime(DateTime.now());
		} else setId(0);
	}, [message]);

	useEffect(() => {
		if (!messageId || messageId === '0') {
			setText('');
			setTitle('');
			setSender(MAILING_LIST_SENDER_BOT);
			setDateTime(DateTime.now());
			return;
		}
		dispatch(
			getMailingListMessageRequest({
				mailListId: +messageId,
			}),
		);
	}, [dispatch, messageId]);

	const sendMessageHandler = () => {
		if (!dateTime || !title || !text) return;
		const date = dateTime.toISO();
		if (!date) return;
		const payload = {
			id: 0,
			title: escapeMarkdown(title),
			body: escapeMarkdown(text),
			fullBody: `${escapeMarkdown(title)} ${escapeMarkdown(text)}`,
			dateToSend: date,
			sender,
		};
		dispatch(postMailingListRequest(payload));
	};

	const [openTrySendDialog, setOpenTrySendDialog] = useState(false);
	const handleClosTrySendDialog = () => {
		setOpenTrySendDialog(false);
	};

	const trySendMessageHandler = () => {
		dispatch(
			onboardingMessagesTrySendRequest({
				messageText: `*${escapeMarkdown(title)}*\n\n${escapeMarkdown(text)}`,
			}),
		);
		setOpenTrySendDialog(true);
	};

	const handleReplayHistory = () => {
		setTitle('');
		setText('');
		history.back();
	};

	return (
		<>
			<div>
				<Chip
					onClick={handleReplayHistory}
					icon={<ReplyIcon color="primary" />}
					label="Назад"
					sx={{ mb: '20px', my: 2, bgcolor: '#00507a', color: '#66cbff' }}
				/>
				<h1 style={{ color: '#66cbff', marginBottom: '10px' }}>
					Редактор сообщений для всех пользователей
				</h1>
			</div>

			<div className="basic-section ">
				<div className="container">
					<div className="voting">
						<RadioGroup
							row
							aria-labelledby="demo-row-radio-buttons-group-label"
							name="row-radio-buttons-group"
							sx={{ color: '#66cbff' }}
							value={sender}
							onChange={(e) => setSender(+e.target.value as TMailingListSender)}
						>
							<FormControlLabel
								value={MAILING_LIST_SENDER_BOT}
								control={
									<Radio
										sx={{
											color: '#66cbff',
											'&.Mui-checked': {
												color: '#66cbff',
											},
										}}
									/>
								}
								label="От имени бота"
							/>
							<FormControlLabel
								value={MAILING_LIST_SENDER_USER}
								control={
									<Radio
										sx={{
											color: '#66cbff',
											'&.Mui-checked': {
												color: '#66cbff',
											},
										}}
									/>
								}
								label="От имени пользователя"
							/>
						</RadioGroup>
						<div className="make-proposal">
							<div className="make-proposal-content">
								<div className="make-proposal-block">
									<LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="ru">
										<StaticDateTimePicker
											orientation="landscape"
											value={dateTime}
											onChange={(newValue) => {
												setDateTime(newValue);
											}}
											slots={undefined}
										/>
									</LocalizationProvider>
								</div>
								<div className="make-proposal-block">
									<div className="make-proposal-block__title">
										<p>Заголовок</p>
									</div>
									<div className="input input--type3">
										<input
											className="input-item"
											type="text"
											placeholder=""
											onChange={(e) => setTitle(e.target.value)}
											value={title}
										/>
									</div>
								</div>
								<TextEditor setText={setText} text={text} />
								<ConvertMarkdownToHtml textPreview={text} titlePreview={title} />
								<div style={{ display: 'grid', gridGap: '20px', gridTemplateColumns: '1fr 1fr' }}>
									<button
										type="button"
										className="button button--full-width button--medium-height"
										style={{ marginTop: '30px' }}
										onClick={sendMessageHandler}
									>
										Отправить всем <SendAndArchiveIcon style={{ marginLeft: '20px' }} />
									</button>
									<button
										type="button"
										className="button button--full-width button--medium-height"
										style={{ marginTop: '30px' }}
										onClick={trySendMessageHandler}
									>
										Проверить в боте
										<SendIcon style={{ marginLeft: '20px' }} />
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<TrySendDialog
				text={trySendMessages?.message ?? ''}
				open={openTrySendDialog && !trySendMessagesIsLoad && !!trySendMessages?.message}
				setClose={handleClosTrySendDialog}
			/>
		</>
	);
};
