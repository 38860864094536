import { createSelector } from '@reduxjs/toolkit';
import { TStoreState } from 'redux/types';
import {
	TOnboardingMessagesStore,
	TOnboardingMessagesData,
	TOnboardingMessagesTrySendData,
	TGetNPSData,
	TNPSChaptersData,
	TMailingListData,
	TMailingListMessageData,
	TMailingListHistoryData,
} from './types';

// ==========================================:
const getOnboardingMessagesState = (state: TStoreState): TOnboardingMessagesStore =>
	state.onboardingMessages;
export const getOnboardingMessages = createSelector(
	[getOnboardingMessagesState],
	(onboardingMessages: TOnboardingMessagesStore) => onboardingMessages,
);

// ====================================================:
export const getOnboardingMessagesIsLoad = createSelector(
	[getOnboardingMessages],
	(onboardingMessages: TOnboardingMessagesStore): boolean =>
		onboardingMessages.onboardingMessagesLoader,
);

// ====================================================:
export const getOnboardingMessagesList = createSelector(
	[getOnboardingMessages],
	(onboardingMessages: TOnboardingMessagesStore): TOnboardingMessagesData =>
		onboardingMessages.onboardingMessages,
);

// ====================================================:
export const trySendMessage = createSelector(
	[getOnboardingMessages],
	(onboardingMessages: TOnboardingMessagesStore): TOnboardingMessagesTrySendData =>
		onboardingMessages.onboardingMessagesTrySend,
);
// ====================================================:
export const trySendMessageIsLoad = createSelector(
	[getOnboardingMessages],
	(onboardingMessages: TOnboardingMessagesStore): boolean =>
		onboardingMessages.onboardingMessagesTrySendLoader,
);

// ====================================================:
export const getNPS = createSelector(
	[getOnboardingMessages],
	(onboardingMessages: TOnboardingMessagesStore): TGetNPSData => onboardingMessages.getNPS,
);

// ====================================================:

export const getNPSLoader = createSelector(
	[getOnboardingMessages],
	(onboardingMessages: TOnboardingMessagesStore): boolean => onboardingMessages.getNPSLoader,
);

// ====================================================:

export const npsChaptersIsLoad = createSelector(
	[getOnboardingMessages],
	(onboardingMessages: TOnboardingMessagesStore): boolean => onboardingMessages.npsChaptersLoader,
);

// ====================================================:

export const npsChapters = createSelector(
	[getOnboardingMessages],
	(onboardingMessages: TOnboardingMessagesStore): TNPSChaptersData =>
		onboardingMessages.npsChapters,
);

// ====================================================:

export const mailingListIsLoad = createSelector(
	[getOnboardingMessages],
	(onboardingMessages: TOnboardingMessagesStore): boolean => onboardingMessages.mailingListLoader,
);

// ====================================================:

export const mailingList = createSelector(
	[getOnboardingMessages],
	(onboardingMessages: TOnboardingMessagesStore): TMailingListData =>
		onboardingMessages.mailingList,
);

// ====================================================:

export const mailingListMessageIsLoad = createSelector(
	[getOnboardingMessages],
	(onboardingMessages: TOnboardingMessagesStore): boolean =>
		onboardingMessages.mailingListMessageLoader,
);

// ====================================================:

export const mailingListMessage = createSelector(
	[getOnboardingMessages],
	(onboardingMessages: TOnboardingMessagesStore): TMailingListMessageData =>
		onboardingMessages.mailingListMessage,
);

// ====================================================:

export const mailingListHistoryIsLoad = createSelector(
	[getOnboardingMessages],
	(onboardingMessages: TOnboardingMessagesStore): boolean =>
		onboardingMessages.mailingListHistoryLoader,
);

// ====================================================:

export const mailingListHistory = createSelector(
	[getOnboardingMessages],
	(onboardingMessages: TOnboardingMessagesStore): TMailingListHistoryData =>
		onboardingMessages.mailingListHistory,
);
