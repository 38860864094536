/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import {
	getNPSRequest,
	getOnboardingMessagesRequest,
	onboardingMessagesTrySendRequest,
	setNPSRequest,
} from 'redux/reducers/onboardingMessages/reducer';
import { useDispatch, useSelector } from 'react-redux';
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive';
import { useLocation, useParams } from 'react-router';
import queryString from 'query-string';
import {
	getNPS,
	getOnboardingMessages,
	trySendMessage,
	trySendMessageIsLoad,
} from 'redux/reducers/onboardingMessages/selectors';
import { TextEditor } from 'components/OnboardingMessagesEditor/TextEditor';
import { TrySendDialog } from 'components/OnboardingMessagesEditor/TrySendDialog';
import { escapeMarkdown, unEscapeMarkdown } from 'components/OnboardingMessagesEditor/utils';
import { ConvertMarkdownToHtml } from 'components/OnboardingMessagesEditor/ConvertMarkdownToHtml';
import { Chip } from '@mui/material';
import ReplyIcon from '@mui/icons-material/Reply';
import { history } from 'routes/history';
// import nps from '../NPS/nps.json';

export const NPSEditor: FC = () => {
	const params = useParams<{ id: string; index: string }>();
	const { id, index } = params;

	const dispatch = useDispatch();
	const [text, setText] = useState('');
	const trySendMessages = useSelector(trySendMessage);
	const trySendMessagesIsLoad = useSelector(trySendMessageIsLoad);

	const nps = useSelector(getNPS);

	useEffect(() => {
		dispatch(getNPSRequest());
	}, [dispatch]);

	useEffect(() => {
		if (index) {
			const message = nps?.[+index];
			setText(unEscapeMarkdown(message?.text ?? ''));
		}
	}, [index]);

	const sendMessageHandler = () => {
		dispatch(
			setNPSRequest({
				npsQuestionId: +(id ?? 0),
				text: escapeMarkdown(text),
			}),
		);
	};

	// const escaping = (txt: string) => {
	// 	const regStr = '\\d\\.\\d';
	// 	const regexp = new RegExp(regStr);
	// 	let tmp;
	// 	// let tmp = txt.replaceAll('.', '\\.');
	// 	tmp = tmp.replaceAll('-', '\\-');
	// 	tmp = tmp.replaceAll('(', '\\(');
	// 	tmp.replaceAll(')', '\\)');
	// 	return tmp;
	// };

	const [openTrySendDialog, setOpenTrySendDialog] = useState(false);
	const handleClosTrySendDialog = () => {
		setOpenTrySendDialog(false);
	};

	const trySendMessageHandler = () => {
		dispatch(
			onboardingMessagesTrySendRequest({
				messageText: escapeMarkdown(text),
			}),
		);
		setOpenTrySendDialog(true);
	};

	const handleReplayHistory = () => {
		history.back();
	};

	return (
		<>
			<div>
				<Chip
					onClick={handleReplayHistory}
					icon={<ReplyIcon color="primary" />}
					label="Назад"
					sx={{ mb: '20px', my: 2, bgcolor: '#00507a', color: '#66cbff' }}
				/>
				<h1 style={{ color: '#66cbff', marginBottom: '10px' }}>
					Редактор сообщения обратной связи
				</h1>
			</div>

			<div className="basic-section">
				<div className="container">
					<div className="voting">
						<div className="make-proposal">
							<div className="make-proposal-content">
								<TextEditor setText={setText} text={text} />
								<ConvertMarkdownToHtml textPreview={text} titlePreview="" />
								<div style={{ display: 'grid', gridGap: '20px', gridTemplateColumns: '1fr 1fr' }}>
									<button
										type="button"
										className="button button--full-width button--medium-height"
										style={{ marginTop: '30px' }}
										onClick={sendMessageHandler}
									>
										Сохранить <SendAndArchiveIcon style={{ marginLeft: '20px' }} />
									</button>
									<button
										type="button"
										className="button button--full-width button--medium-height"
										style={{ marginTop: '30px' }}
										onClick={trySendMessageHandler}
									>
										Проверить в боте
										<SendIcon style={{ marginLeft: '20px' }} />
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<TrySendDialog
				text={trySendMessages?.message ?? ''}
				open={openTrySendDialog && !trySendMessagesIsLoad && !!trySendMessages?.message}
				setClose={handleClosTrySendDialog}
			/>
		</>
	);
};
