/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/extensions */
import { GroupsTablePage } from 'pages/GroupsPage';
import { OnboardingMessagesPage } from 'pages/OnboardingMessagesPage';
import { LoginPage } from 'pages/LoginPage';
import { NPSEditorPage } from 'pages/NPSEditorPage';
import { NPSPage } from 'pages/NPSPage';
import { RandomCoffeePairsPage } from 'pages/RandomCoffeePairsPage';
import { OnboardingMessagesEditorPage } from 'pages/OnboardingMessagesEditorPage';
import { ToAllMessagePage } from 'pages/ToAllMessagePage';
import { UserPage } from 'pages/UserPage';
import { UsersPage } from 'pages/UsersPage';
import { Navigate, useRoutes } from 'react-router';
import { RandomCoffeePairsFeaturePage } from 'pages/RandomCoffeePairsFeaturePage';
import { useSelector } from 'react-redux';
import { getIsAuth, getPermission } from 'redux/reducers/auth/selectors';
import { TPermissionsItem } from 'redux/reducers/auth/types';
import { useEffect, useState } from 'react';
import { MessageToUserPage } from 'pages/MessageToUserPage';
import { UserGroupsPage } from 'pages/UserGroupsPage';
import { ToAllMessageEditorPage } from 'pages/ToAllMessageEditorPage';
import { ToAllMessageStatisticPage } from 'pages/ToAllMessageStatisticPage';
import { GroupsFilteredTablePage } from 'pages/GroupsFilteredPage';
import { TelegramLoginPage } from 'pages/TelegramLoginPage';
import { TelegramCodePage } from 'pages/TelegramCodePage';
import { PermissionsItems, Routes } from './constants';

export const NavigateList = () => {
	const navArray = useSelector(getPermission)?.permissions;
	const ifAuth = useSelector(getIsAuth);

	const navArrayRoutes = [
		{
			permissionItem: PermissionsItems.onboarding,
			routesItem: {
				path: Routes.onboardingMessages,
				element: <OnboardingMessagesPage />,
			},
		},
		{
			permissionItem: PermissionsItems.onboarding,
			routesItem: {
				path: Routes.onboardingMessagesId,
				element: <OnboardingMessagesEditorPage />,
			},
		},
		{
			permissionItem: PermissionsItems.groups,
			routesItem: {
				path: Routes.groupsOld,
				element: <GroupsTablePage />,
			},
		},
		{
			permissionItem: PermissionsItems.groups,
			routesItem: {
				path: Routes.groups,
				element: <GroupsFilteredTablePage />,
			},
		},

		{
			permissionItem: PermissionsItems.sendAll,
			routesItem: {
				path: Routes.toAll,
				element: <ToAllMessagePage />,
			},
		},
		{
			permissionItem: PermissionsItems.sendAll,
			routesItem: {
				path: Routes.toAllMessageIdStatistic,
				element: <ToAllMessageStatisticPage />,
			},
		},
		{
			permissionItem: PermissionsItems.sendAll,
			routesItem: {
				path: Routes.toAllMessageIdEditor,
				element: <ToAllMessageEditorPage />,
			},
		},

		{
			permissionItem: PermissionsItems.users,
			routesItem: {
				path: Routes.messageToUserUserId,
				element: <MessageToUserPage />,
			},
		},
		{
			permissionItem: PermissionsItems.users,
			routesItem: {
				path: Routes.users,
				element: <UsersPage />,
			},
		},
		{
			permissionItem: PermissionsItems.users,
			routesItem: {
				path: Routes.usersId,
				element: <UserPage />,
			},
		},
		{
			permissionItem: PermissionsItems.users,
			routesItem: {
				path: Routes.usersIdRandomCoffeePairs,
				element: <RandomCoffeePairsPage />,
			},
		},
		{
			permissionItem: PermissionsItems.users,
			routesItem: {
				path: Routes.usersIdRandomCoffeePairsFeature,
				element: <RandomCoffeePairsFeaturePage />,
			},
		},
		{
			permissionItem: PermissionsItems.users,
			routesItem: {
				path: Routes.usersIdGroups,
				element: <UserGroupsPage />,
			},
		},
		{
			permissionItem: PermissionsItems.nps,
			routesItem: {
				path: Routes.nps,
				element: <NPSPage />,
			},
		},
		{
			permissionItem: PermissionsItems.nps,
			routesItem: {
				path: Routes.npsIdIndex,
				element: <NPSEditorPage />,
			},
		},
	];

	const [routesArray, setRoutesArray] = useState<any[]>([]);
	// console.log('routesArray', routesArray);

	useEffect(() => {
		setRoutesArray([
			{
				path: '/',
				element: <Navigate to="/login" replace />,
			},
			{
				path: `/no-telegram-auth`,
				element: <LoginPage />,
			},
			{
				path: '/login',
				element: <TelegramLoginPage />,
			},
			{
				path: `/telegram-auth`,
				element: <TelegramCodePage />,
			},
			{
				path: `*`,
				element: <Navigate to="/login" replace />,
			},
		]);
	}, []);

	useEffect(() => {
		if (!navArray || !ifAuth) return;
		const routesArrayTmp = navArrayRoutes
			.filter((item) => navArray?.includes(item.permissionItem as TPermissionsItem))
			.map((item) => item.routesItem);

		const defaultRoute =
			ifAuth && navArray
				? {
						path: '/',
						element: <Navigate to={routesArray[0]?.path ?? '/login'} replace />,
				  }
				: {
						path: '/',
						element: <Navigate to="/login" replace />,
				  };

		// routesArray.push(defaultRoute);
		routesArrayTmp.push(
			...[
				{
					path: Routes.home,
					element: <Navigate to={routesArrayTmp[0]?.path ?? '/login'} replace />,
				},
				{
					path: Routes.noTelegramAuth,
					element: <LoginPage />,
				},
				{
					path: Routes.login,
					element: <TelegramLoginPage />,
				},
				{
					path: Routes.telegramAuth,
					element: <TelegramCodePage />,
				},
				{
					path: Routes.other,
					element: <Navigate to={routesArrayTmp[0]?.path ?? '/login'} replace />,
				},
			],
		);
		setRoutesArray(routesArrayTmp);
	}, [ifAuth, navArray]);

	const routes = useRoutes(routesArray);

	return routes;
};
