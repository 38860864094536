/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import {
	getOnboardingMessagesRequest,
	onboardingMessagesTrySendRequest,
	sendToUserRequest,
} from 'redux/reducers/onboardingMessages/reducer';
import { useDispatch, useSelector } from 'react-redux';
import SendAndArchiveIcon from '@mui/icons-material/SendAndArchive';
import { useLocation, useParams } from 'react-router';
import queryString from 'query-string';
import {
	getOnboardingMessages,
	trySendMessage,
	trySendMessageIsLoad,
} from 'redux/reducers/onboardingMessages/selectors';
import { TextEditor } from 'components/OnboardingMessagesEditor/TextEditor';
import { TrySendDialog } from 'components/OnboardingMessagesEditor/TrySendDialog';
import { escapeMarkdown } from 'components/OnboardingMessagesEditor/utils';
import { ConvertMarkdownToHtml } from 'components/OnboardingMessagesEditor/ConvertMarkdownToHtml';
import { Chip } from '@mui/material';
import ReplyIcon from '@mui/icons-material/Reply';
import { history } from 'routes/history';
import { getUserRequest } from 'redux/reducers/users/reducer';
import { getUser } from 'redux/reducers/users/selectors';

export const MessageToIUser: FC = () => {
	const { userId } = useParams<{ userId: string }>();
	const dispatch = useDispatch();
	const [text, setText] = useState('');
	const [title, setTitle] = useState('');
	const trySendMessages = useSelector(trySendMessage);
	const trySendMessagesIsLoad = useSelector(trySendMessageIsLoad);

	const userData = useSelector(getUser);

	useEffect(() => {
		if (!userId) return;
		dispatch(getUserRequest({ userId: +userId }));
	}, [dispatch, userId]);

	const sendMessageHandler = () => {
		if (!userId) return;
		dispatch(
			sendToUserRequest({
				userId: +userId,
				message: `${escapeMarkdown(title)} ${escapeMarkdown(text)}`,
			}),
		);
	};

	const [openTrySendDialog, setOpenTrySendDialog] = useState(false);
	const handleClosTrySendDialog = () => {
		setOpenTrySendDialog(false);
	};

	const trySendMessageHandler = () => {
		dispatch(
			onboardingMessagesTrySendRequest({
				messageText: `*${escapeMarkdown(title)}*\n\n${escapeMarkdown(text)}`,
			}),
		);
		setOpenTrySendDialog(true);
	};

	const handleReplayHistory = () => {
		history.back();
	};

	return (
		<>
			<div>
				<Chip
					onClick={handleReplayHistory}
					icon={<ReplyIcon color="primary" />}
					label="Назад"
					sx={{ mb: '20px', my: 2, bgcolor: '#00507a', color: '#66cbff' }}
				/>
				<h1 style={{ marginBottom: '10px', color: '#66cbff' }}>
					Редактор сообщения для пользователя {userData?.telegramName ?? ''}
				</h1>
			</div>

			<div className="basic-section">
				<div className="container">
					<div className="voting">
						<div className="make-proposal">
							<div className="make-proposal-content">
								<div className="make-proposal-block">
									<div className="make-proposal-block__title">
										<p>Заголовок</p>
									</div>
									<div className="input input--type3">
										<input
											className="input-item"
											type="text"
											placeholder=""
											onChange={(e) => setTitle(e.target.value)}
											value={title}
										/>
									</div>
								</div>
								<TextEditor setText={setText} text={text} />
								<ConvertMarkdownToHtml textPreview={text} titlePreview={title} />
								<div style={{ display: 'grid', gridGap: '20px', gridTemplateColumns: '1fr 1fr' }}>
									<button
										type="button"
										className="button button--full-width button--medium-height"
										style={{ marginTop: '30px' }}
										onClick={sendMessageHandler}
									>
										Отправить пользователю <SendAndArchiveIcon style={{ marginLeft: '20px' }} />
									</button>
									<button
										type="button"
										className="button button--full-width button--medium-height"
										style={{ marginTop: '30px' }}
										onClick={trySendMessageHandler}
									>
										Проверить в боте
										<SendIcon style={{ marginLeft: '20px' }} />
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<TrySendDialog
				text={trySendMessages?.message ?? ''}
				open={openTrySendDialog && !trySendMessagesIsLoad && !!trySendMessages?.message}
				setClose={handleClosTrySendDialog}
			/>
		</>
	);
};
