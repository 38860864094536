/* eslint-disable @typescript-eslint/naming-convention */
import { LoginButton } from '@telegram-auth/react';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router';
import { getIsAuth, getPermission } from 'redux/reducers/auth/selectors';
import queryString from 'query-string';
import { get } from 'http';
import { telegramLoginRequest } from 'redux/reducers/auth/reducer';
import { TTelegramAuthRequestPayload } from 'redux/reducers/auth/types';
import { PERMISSIONS_ITEM_LINKS, Routes } from 'routes/constants';

export const TelegramCodePage: FC = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const param = useParams();
	const { search } = useLocation();
	const { id, first_name, username, photo_url, auth_date, hash } = queryString.parse(search);
	const navArray = useSelector(getPermission)?.permissions;

	useEffect(() => {
		if (!id || !first_name || !username || !photo_url || !auth_date || !hash) return;
		dispatch(
			telegramLoginRequest({
				id: id as unknown as number,
				first_name: first_name as string,
				username: username as string,
				photo_url: photo_url as string,
				auth_date: auth_date as unknown as number,
				hash: hash as string,
			}),
		);
	}, [auth_date, dispatch, first_name, hash, id, photo_url, username]);

	const ifAuth = useSelector(getIsAuth);
	useEffect(() => {
		if (ifAuth && navArray) navigate(PERMISSIONS_ITEM_LINKS[navArray[0]] ?? Routes.home);
	}, [ifAuth, navigate, navArray]);

	return <div />;
};
