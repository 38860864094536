import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { TMessagesApi } from './types';

export const messages: TMessagesApi = {
	sendToAll: (params) => http.post(endpoint.message.SEND_TO_ALL, params),
	sendToUser: (params) => http.post(endpoint.message.SEND_TO_USER(params.userId), params),
	getNPS: () => http.get(endpoint.message.GET_NPS).then((response) => response.data),
	setNPS: (params) => http.post(endpoint.message.SET_NPS(params.npsQuestionId), params),
	startNewSurvey: (params) => http.post(endpoint.message.START_NEW_SURVEY, params),
	sendNotification: (params) => http.post(endpoint.message.SEND_NOTIFICATION, params),
	getNpsChapters: () =>
		http.get(endpoint.message.GET_NPS_CHAPTERS).then((response) => response.data),
	getMailingList: (params) =>
		http.get(endpoint.message.GET_MAILING_LIST, { params }).then((response) => response.data),
	getMailingListMessage: (params) =>
		http
			.get(endpoint.message.GET_MAILING_LIST_MESSAGE(params.mailListId))
			.then((response) => response.data),
	postMailingList: (params) =>
		http.post(endpoint.message.POST_MAILING_LIST, params).then((response) => response.data),

	deleteMailingList: (params) =>
		http
			.delete(endpoint.message.DELETE_MAILING_LIST(params.mailListId))
			.then((response) => response.data),
	getMailingListHistory: (params) =>
		http
			.get(endpoint.message.GET_MAILING_LIST_HISTORY(params.mailListId), { params })
			.then((response) => response.data),
};
