/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { LightTooltip, StyledTableCell, StyledTableRow } from 'ui/StyledTable';
import { getGroups, getGroupsIsLoad } from 'redux/reducers/groups/selectors';
import { TGroupsRequestPayload } from 'redux/reducers/groups/types';
import {
	getGroupsRequest,
	resetGroupControlRequest,
	setGroupControlRequest,
	updateAllGroupsRequest,
	updateAllUsersRequest,
	updateGroupRequest,
	updateUsersRequest,
} from 'redux/reducers/groups/reducer';
import { Button, Container, IconButton, Stack, Switch, Tooltip } from '@mui/material';
import { Loader } from 'ui/Loader';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import GroupWorkIcon from '@mui/icons-material/GroupWork';

export const GroupsTable: FC = () => {
	const dispatch = useDispatch();
	const groups = useSelector(getGroups);
	const groupsIsLoad = useSelector(getGroupsIsLoad);

	useEffect(() => {
		const params: TGroupsRequestPayload = {};
		dispatch(getGroupsRequest(params));
	}, [dispatch]);
	const groupsUpdateHandler = (groupId: number) => {
		dispatch(updateGroupRequest({ groupId }));

		setTimeout(() => dispatch(getGroupsRequest({})), 5000);
	};
	const usersUpdateHandler = (groupId: number) => {
		dispatch(updateUsersRequest({ groupId }));

		// dispatch(getGroupsRequest({}));
	};
	const allUsersUpdateHandler = () => {
		dispatch(updateAllUsersRequest());

		// dispatch(getGroupsRequest({}));
	};
	const allGroupsUpdateHandler = () => {
		dispatch(updateAllGroupsRequest());

		// dispatch(getGroupsRequest({}));
	};
	const setGroupControlHandler = (groupId: number) => {
		dispatch(setGroupControlRequest({ groupId }));
	};
	const resetGroupControlHandler = (groupId: number) => {
		dispatch(resetGroupControlRequest({ groupId }));
	};

	return (
		<>
			{!((groups.groups?.length ?? 0) > 0) && !groupsIsLoad && (
				<div className="empty-data">
					<p className="empty__text">Сообщения не найдены...</p>
				</div>
			)}
			{!!groupsIsLoad && (
				<div
					style={{
						minHeight: '300px',
						minWidth: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						backgroundColor: '#016193',
					}}
				>
					<Loader />
				</div>
			)}
			{(groups.groups?.length ?? 0) > 0 && !groupsIsLoad && (
				<>
					<Stack spacing={2} direction="row" style={{ marginBottom: '20px' }}>
						<Button
							variant="contained"
							onClick={allGroupsUpdateHandler}
							sx={{ color: 'white', backgroundColor: '#00507a' }}
						>
							Обновить все группы <GroupWorkIcon sx={{ ml: '20px' }} />
						</Button>
						<Button
							variant="contained"
							onClick={allUsersUpdateHandler}
							sx={{ color: 'white', backgroundColor: '#00507a' }}
						>
							Обновить всех пользователей <SupervisedUserCircleIcon sx={{ ml: '20px' }} />
						</Button>
					</Stack>
					<Paper>
						<TableContainer>
							<Table stickyHeader aria-label="sticky table">
								<TableHead>
									<TableRow>
										<StyledTableCell align="center" colSpan={1} style={{ minWidth: '3%' }}>
											id
										</StyledTableCell>
										<StyledTableCell align="left" colSpan={2} style={{ minWidth: '30%' }}>
											Название
										</StyledTableCell>
										<StyledTableCell align="center" colSpan={1} style={{ minWidth: '30%' }}>
											Кол-во пользователей
										</StyledTableCell>
										<StyledTableCell align="center" colSpan={2} style={{ minWidth: '30%' }}>
											Обновлено
										</StyledTableCell>

										<StyledTableCell align="left" colSpan={4} style={{ minWidth: '40%' }}>
											Invite Link
										</StyledTableCell>
										<StyledTableCell align="center" colSpan={1} style={{ minWidth: '10%' }}>
											Контроль
										</StyledTableCell>

										<StyledTableCell align="center" colSpan={1} style={{ minWidth: '10%' }}>
											Разрешения
										</StyledTableCell>

										<StyledTableCell align="center" colSpan={3} style={{ minWidth: '20%' }}>
											Обновить
										</StyledTableCell>
									</TableRow>
								</TableHead>
								<TableBody style={{ background: '#00679eba' }}>
									{groups.groups?.map?.((item) => (
										<StyledTableRow key={item.id}>
											<StyledTableCell align="center" colSpan={1}>
												{item.id}
											</StyledTableCell>
											<StyledTableCell align="left" colSpan={2}>
												{item.name}
											</StyledTableCell>
											<StyledTableCell align="center" colSpan={1}>
												{item.userCount}
											</StyledTableCell>
											<StyledTableCell align="center" colSpan={2}>
												{item.updatedAt}
											</StyledTableCell>

											<StyledTableCell align="left" colSpan={4}>
												{item.inviteLink ? (
													<a
														target="_blank"
														href={item.inviteLink}
														rel="noreferrer"
														style={{ color: '#66cbff' }}
													>
														{item.inviteLink}
													</a>
												) : (
													''
												)}
											</StyledTableCell>
											<StyledTableCell align="center" colSpan={1}>
												<Switch
													color="secondary"
													checked={item.isControlledByBot}
													disabled={!item.isControlledByBot && !item.isBotHasRequiredPermissions}
													onChange={() => {
														item.isControlledByBot
															? resetGroupControlHandler(item.id)
															: setGroupControlHandler(item.id);
													}}
												/>
											</StyledTableCell>

											<StyledTableCell align="center" colSpan={1}>
												{item.isBotHasRequiredPermissions ? (
													<DoneAllIcon color="secondary" />
												) : (
													<RemoveDoneIcon color="primary" />
												)}
											</StyledTableCell>

											<StyledTableCell align="center" colSpan={3}>
												<LightTooltip title="Обновить информацию о группах" placement="top" arrow>
													<IconButton
														color="secondary"
														aria-label="button"
														component="label"
														onClick={() => groupsUpdateHandler(item.id)}
													>
														<GroupWorkIcon />
													</IconButton>
												</LightTooltip>
												<LightTooltip
													title="Обновить информацию о пользователях"
													placement="top"
													arrow
												>
													<IconButton
														color="secondary"
														aria-label="button"
														component="label"
														onClick={() => usersUpdateHandler(item.id)}
													>
														<SupervisedUserCircleIcon />
													</IconButton>
												</LightTooltip>
											</StyledTableCell>
										</StyledTableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Paper>
				</>
			)}
		</>
	);
};
