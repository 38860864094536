import Header from 'layouts/Header';
import { FC } from 'react';
import { Container } from '@mui/material';
import { TPageProps } from './types';

// ==========================================:
export const Page: FC<TPageProps> = ({ children, title }) => {
	return (
		<div>
			<Header />
			<main className="main">
				<div className="main-content">{children}</div>
			</main>
		</div>
	);
};
