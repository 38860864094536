import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { FC, useState } from 'react';
import { DialogContent, TextField } from '@mui/material';
import { TMonths, TYears } from 'redux/reducers/onboardingMessages/types';
import { TDateDialogProps } from './types';
import { MultiSelect } from './MultiSelect';

export const DateDialog: FC<TDateDialogProps> = ({
	open,
	setClose,
	handle,
	text,
	chapters,
	selectedChapters,
	setSelectedChapters,
}) => {
	const [selectedDate, setSelectedDate] = useState<{ month: TMonths; year: TYears }>({
		month: 1,
		year: 2023,
	});

	const handleMonthChange = (month: number) => {
		if (month > 12) return;
		if (month < 1) return;
		const monthTmp = month as TMonths;
		setSelectedDate({ month: monthTmp, year: selectedDate.year });
	};

	const handleYearChange = (year: number) => {
		if (year < 2023) return;
		if (year > 2033) return;
		const yearTmp = year as TYears;
		setSelectedDate({ month: selectedDate.month, year: yearTmp });
	};

	return (
		<Dialog
			open={open}
			onClose={setClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{text}</DialogTitle>
			<DialogContent>
				<TextField
					id="outlined-basic"
					label="Месяц"
					variant="outlined"
					fullWidth
					type="number"
					sx={{ marginTop: '20px' }}
					onChange={(e) => handleMonthChange(Number(e.target.value))}
					value={selectedDate.month}
				/>
				<TextField
					id="outlined-basic"
					label="Год"
					variant="outlined"
					fullWidth
					type="number"
					sx={{ marginTop: '20px' }}
					onChange={(e) => handleYearChange(Number(e.target.value))}
					value={selectedDate.year}
				/>
				<MultiSelect
					chapters={chapters}
					selectedChapters={selectedChapters}
					setSelectedChapters={setSelectedChapters}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={setClose}>Cancel</Button>
				<Button onClick={() => handle(selectedDate.month, selectedDate.year)} autoFocus>
					Применить
				</Button>
			</DialogActions>
		</Dialog>
	);
};
