import { Box, IconButton } from '@mui/material';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { getGroupsFilterValuesRequest } from 'redux/reducers/groups/reducer';
import { getGroupsFilterValues } from 'redux/reducers/groups/selectors';
import { Filter } from 'ui/Filter';
import { SearchComponent } from 'ui/SearchComponent';
import queryString from 'query-string';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';

export const GroupsFilterPanel: FC = () => {
	const { search } = useLocation();
	const { chapter, category, unit, purpose, searchStr } = queryString.parse(search);
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();

	const groupsFilterValues = useSelector(getGroupsFilterValues);

	const handleChapterFilter = (index: number) => {
		navigate({
			pathname: location.pathname,
			search: queryString.stringify({
				...queryString.parse(location.search),
				chapter: index === 0 ? undefined : index - 1,
				pageNumber: 1,
			}),
		});
	};

	const handleCategoryFilter = (index: number) => {
		navigate({
			pathname: location.pathname,
			search: queryString.stringify({
				...queryString.parse(location.search),
				category: index === 0 ? undefined : index - 1,
				pageNumber: 1,
			}),
		});
	};

	const handleUnitFilter = (index: number) => {
		navigate({
			pathname: location.pathname,
			search: queryString.stringify({
				...queryString.parse(location.search),
				unit: index === 0 ? undefined : index - 1,
				pageNumber: 1,
			}),
		});
	};

	const handlePurposeFilter = (index: number) => {
		navigate({
			pathname: location.pathname,
			search: queryString.stringify({
				...queryString.parse(location.search),
				purpose: index === 0 ? undefined : index - 1,
				pageNumber: 1,
			}),
		});
	};

	const handleSearchStr = (value: string) => {
		navigate({
			pathname: location.pathname,
			search: queryString.stringify({
				...queryString.parse(location.search),
				searchStr: value,
				pageNumber: 1,
			}),
		});
	};

	const handleClearAll = () => {
		navigate({
			pathname: location.pathname,
			search: queryString.stringify({
				...queryString.parse(location.search),
				chapter: undefined,
				category: undefined,
				unit: undefined,
				purpose: undefined,
				searchStr: undefined,
				pageNumber: 1,
			}),
		});
	};

	useEffect(() => {
		dispatch(getGroupsFilterValuesRequest());
	}, [dispatch]);

	return (
		<Box>
			<Box
				style={{
					display: 'flex',
					justifyContent: 'end',
					alignItems: 'center',
					backgroundColor: '#016193',
					color: '#66cbff',
					paddingLeft: '10px',
				}}
			>
				<Box
					style={{
						background: '#016193',
						padding: '10px 10px 10px 10px',
						display: 'flex',
						justifyContent: 'end',
						height: '70px',
					}}
				>
					{searchStr || chapter || category || unit || purpose ? (
						<IconButton onClick={handleClearAll}>
							<FilterListOffIcon style={{ color: '#66cbff' }} fontSize="large" />
						</IconButton>
					) : (
						''
					)}
					<SearchComponent onChange={handleSearchStr} search={String(searchStr ?? '')} />
				</Box>
			</Box>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'end',
					alignItems: 'center',
					backgroundColor: '#016193',
					color: '#fff',
					paddingRight: '10px',
					// pt: '10px',
				}}
			>
				<Filter
					name="Назначение"
					items={['все', ...(groupsFilterValues?.purposes.map((item) => item.value) || [])]}
					onChange={handlePurposeFilter}
					selectedIndex={purpose ? +purpose + 1 : 0}
				/>
				<Filter
					name="Чаптер"
					items={['все', ...(groupsFilterValues?.chapters.map((item) => item.value) || [])]}
					onChange={handleChapterFilter}
					selectedIndex={chapter ? +chapter + 1 : 0}
				/>
				<Filter
					name="Категория"
					items={['все', ...(groupsFilterValues?.categories.map((item) => item.value) || [])]}
					onChange={handleCategoryFilter}
					selectedIndex={category ? +category + 1 : 0}
				/>
				<Filter
					name="Юнит"
					items={['все', ...(groupsFilterValues?.units.map((item) => item.value) || [])]}
					onChange={handleUnitFilter}
					selectedIndex={unit ? +unit + 1 : 0}
				/>
			</Box>
		</Box>
	);
};
