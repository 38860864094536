export enum PermissionsItems {
	groups = 'groups',
	users = 'users',
	nps = 'nps',
	onboarding = 'onboarding',
	sendAll = 'sendAll',
}

export enum Routes {
	other = '*',
	home = '/',
	auth = '/auth',
	login = '/login',
	noTelegramAuth = `/no-telegram-auth`,
	telegramAuth = `/telegram-auth`,
	onboardingMessages = '/onboarding-messages',
	onboardingMessagesId = '/onboarding-messages/:id',
	groupsOld = '/groups-old',
	groups = '/groups',
	toAll = '/to-all',
	toAllMessageIdStatistic = '/to-all/:messageId/statistic',
	toAllMessageIdEditor = '/to-all/:messageId/editor',
	messageToUserUserId = '/message-to-user/:userId',
	users = '/users',
	usersId = '/users/:id',
	usersIdRandomCoffeePairs = '/users/:id/randomCoffeePairs',
	usersIdRandomCoffeePairsFeature = '/users/:id/randomCoffeePairsFeature',
	usersIdGroups = '/users/:id/groups',
	nps = '/nps',
	npsIdIndex = '/nps/:id/:index',
}

export const PERMISSIONS_ITEM_LINKS = {
	[PermissionsItems.onboarding]: Routes.onboardingMessages,
	[PermissionsItems.groups]: Routes.groups,
	[PermissionsItems.users]: Routes.users,
	[PermissionsItems.sendAll]: Routes.toAll,
	[PermissionsItems.nps]: Routes.nps,
};
