import { Page } from 'layouts/Page';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { getIsAuth } from 'redux/reducers/auth/selectors';
import { LoginPage } from 'pages/LoginPage';
import { NPS } from 'components/NPS';
import { NPSEditor } from 'components/NPSEditor';

export const NPSEditorPage: FC = () => {
	const ifAuth = useSelector(getIsAuth);

	return ifAuth ? (
		<Page title="NPSEditorPage">
			<NPSEditor />
		</Page>
	) : (
		<LoginPage />
	);
};
