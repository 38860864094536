/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { LightTooltip, StyledTableCell, StyledTableRow } from 'ui/StyledTable';
import {
	getGroups,
	getGroupsFilterIsLoad,
	getGroupsFilterList,
	getGroupsFilterValues,
	getGroupsIsLoad,
} from 'redux/reducers/groups/selectors';
import {
	TGroupEditRequestPayload,
	TGroupsFilterRequestPayload,
	TGroupsRequestPayload,
} from 'redux/reducers/groups/types';
import {
	editGroupRequest,
	getGroupsFilterRequest,
	getGroupsFilterValuesRequest,
	getGroupsRequest,
	resetGroupControlRequest,
	setGroupControlRequest,
	updateAllGroupsRequest,
	updateAllUsersRequest,
	updateGroupRequest,
	updateUsersRequest,
} from 'redux/reducers/groups/reducer';
import { Button, Container, IconButton, Pagination, Stack, Switch, Tooltip } from '@mui/material';
import { Loader } from 'ui/Loader';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import { DateTime } from 'luxon';
import { PAGE_SIZE } from 'redux/reducers/users/constants';
import { useLocation, useNavigate } from 'react-router';
import queryString from 'query-string';
import { EditDialog } from 'ui/EditDialog';
import { Edit } from '@mui/icons-material';
import { EditTextDialog } from 'ui/EditTextDialog';
import { GroupsFilterPanel } from './GroupsFilterPanel';

export const GroupsFilteredTable: FC = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { search } = useLocation();
	const { pageNumber, chapter, purpose, category, unit, searchStr } = queryString.parse(search);
	const dispatch = useDispatch();
	const groupsFilter = useSelector(getGroupsFilterList);
	const groupsFilterIsLoad = useSelector(getGroupsFilterIsLoad);
	const groupsFilterValues = useSelector(getGroupsFilterValues);

	const [editedGroupId, setEditedGroupId] = useState<number | null>(null);
	const [openChapterEditDialog, setOpenChapterEditDialog] = useState(false);
	const [openPurposeEditDialog, setOpenPurposeEditDialog] = useState(false);
	const [openCategoryEditDialog, setOpenCategoryEditDialog] = useState(false);
	const [openUnitEditDialog, setOpenUnitEditDialog] = useState(false);
	const [openCommentEditDialog, setOpenCommentEditDialog] = useState(false);
	const setClose = () => {
		setOpenChapterEditDialog(false);
		setOpenPurposeEditDialog(false);
		setOpenCategoryEditDialog(false);
		setOpenUnitEditDialog(false);
		setOpenCommentEditDialog(false);
	};
	const handleChapterEdit = (index: number) => {
		if (
			editedGroupId === null ||
			groupsFilterValues?.chapters[index] === undefined ||
			groupsFilter === null
		)
			return;
		const groupIndex = groupsFilter?.data?.findIndex((i) => i.id === editedGroupId);
		if (groupIndex === -1) return;

		const params: TGroupEditRequestPayload = {
			id: editedGroupId,
			chapter: index,
			purpose: groupsFilter.data[groupIndex].purpose.key,
			category: groupsFilter.data[groupIndex].category.key,
			unit: groupsFilter.data[groupIndex].unit.key,
			comment: groupsFilter.data[groupIndex].comment,
		};
		dispatch(editGroupRequest(params));
		setOpenChapterEditDialog(false);
	};

	const handlePurposeEdit = (index: number) => {
		if (
			editedGroupId === null ||
			groupsFilterValues?.purposes[index] === undefined ||
			groupsFilter === null
		)
			return;
		const groupIndex = groupsFilter?.data?.findIndex((i) => i.id === editedGroupId);
		if (groupIndex === -1) return;

		const params: TGroupEditRequestPayload = {
			id: editedGroupId,
			chapter: groupsFilter.data[groupIndex].chapter.key,
			purpose: index,
			category: groupsFilter.data[groupIndex].category.key,
			unit: groupsFilter.data[groupIndex].unit.key,
			comment: groupsFilter.data[groupIndex].comment,
		};
		dispatch(editGroupRequest(params));
		setOpenPurposeEditDialog(false);
	};

	const handleCategoryEdit = (index: number) => {
		if (
			editedGroupId === null ||
			groupsFilterValues?.categories[index] === undefined ||
			groupsFilter === null
		)
			return;
		const groupIndex = groupsFilter?.data?.findIndex((i) => i.id === editedGroupId);
		if (groupIndex === -1) return;

		const params: TGroupEditRequestPayload = {
			id: editedGroupId,
			chapter: groupsFilter.data[groupIndex].chapter.key,
			purpose: groupsFilter.data[groupIndex].purpose.key,
			category: index,
			unit: groupsFilter.data[groupIndex].unit.key,
			comment: groupsFilter.data[groupIndex].comment,
		};
		dispatch(editGroupRequest(params));
		setOpenCategoryEditDialog(false);
	};

	const handleUnitEdit = (index: number) => {
		if (
			editedGroupId === null ||
			groupsFilterValues?.units[index] === undefined ||
			groupsFilter === null
		)
			return;
		const groupIndex = groupsFilter?.data?.findIndex((i) => i.id === editedGroupId);
		if (groupIndex === -1) return;

		const params: TGroupEditRequestPayload = {
			id: editedGroupId,
			chapter: groupsFilter.data[groupIndex].chapter.key,
			purpose: groupsFilter.data[groupIndex].purpose.key,
			category: groupsFilter.data[groupIndex].category.key,
			unit: index,
			comment: groupsFilter.data[groupIndex].comment,
		};
		dispatch(editGroupRequest(params));
		setOpenUnitEditDialog(false);
	};

	const handleCommentEdit = (value: string) => {
		if (editedGroupId === null || groupsFilter === null) return;
		const groupIndex = groupsFilter?.data?.findIndex((i) => i.id === editedGroupId);
		if (groupIndex === -1) return;

		const params: TGroupEditRequestPayload = {
			id: editedGroupId,
			chapter: groupsFilter.data[groupIndex].chapter.key,
			purpose: groupsFilter.data[groupIndex].purpose.key,
			category: groupsFilter.data[groupIndex].category.key,
			unit: groupsFilter.data[groupIndex].unit.key,
			comment: value,
		};
		dispatch(editGroupRequest(params));
		setOpenCommentEditDialog(false);
	};

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		navigate({
			pathname: location.pathname,
			search: queryString.stringify({
				...queryString.parse(location.search),
				pageNumber: value,
			}),
		});
	};

	useEffect(() => {
		const paramsFilter: TGroupsFilterRequestPayload = {
			searchString: searchStr === undefined ? null : searchStr?.toString() ?? null,
			chapter: chapter === undefined ? null : +(chapter ?? 0),
			purpose: purpose === undefined ? null : +(purpose ?? 0),
			category: category === undefined ? null : +(category ?? 0),
			unit: unit === undefined ? null : +(unit ?? 0),
			ownerId: null,
			pageSize: 20,
			pageNumber: +(pageNumber ?? 0),
		};
		dispatch(getGroupsFilterRequest(paramsFilter));
	}, [dispatch, pageNumber, chapter, purpose, category, unit, searchStr]);
	const groupsUpdateHandler = (groupId: number) => {
		dispatch(updateGroupRequest({ groupId }));

		setTimeout(() => dispatch(getGroupsRequest({})), 5000);
	};
	const usersUpdateHandler = (groupId: number) => {
		dispatch(updateUsersRequest({ groupId }));

		// dispatch(getGroupsRequest({}));
	};
	const allUsersUpdateHandler = () => {
		dispatch(updateAllUsersRequest());

		// dispatch(getGroupsRequest({}));
	};
	const allGroupsUpdateHandler = () => {
		dispatch(updateAllGroupsRequest());

		// dispatch(getGroupsRequest({}));
	};
	const setGroupControlHandler = (groupId: number) => {
		dispatch(setGroupControlRequest({ groupId }));
	};
	const resetGroupControlHandler = (groupId: number) => {
		dispatch(resetGroupControlRequest({ groupId }));
	};

	return (
		<>
			<Stack spacing={2} direction="row" style={{ marginBottom: '20px' }}>
				<Button
					variant="contained"
					onClick={allGroupsUpdateHandler}
					sx={{ color: 'white', backgroundColor: '#00507a' }}
				>
					Обновить все группы <GroupWorkIcon sx={{ ml: '20px' }} />
				</Button>
				<Button
					variant="contained"
					onClick={allUsersUpdateHandler}
					sx={{ color: 'white', backgroundColor: '#00507a' }}
				>
					Обновить всех пользователей <SupervisedUserCircleIcon sx={{ ml: '20px' }} />
				</Button>
			</Stack>
			<Paper
				sx={{
					borderRadius: '0 0 5px 5px',
					// backgroundColor: '#016193',
				}}
			>
				<GroupsFilterPanel />
				<TableContainer
					sx={{
						backgroundColor: '#00679e',
						borderRadius: '0 0 5px 5px',
						overflow: 'auto',
					}}
				>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow>
								<StyledTableCell align="center" colSpan={1} style={{ minWidth: '3%' }}>
									id
								</StyledTableCell>
								<StyledTableCell align="left" colSpan={2} style={{ minWidth: '30%' }}>
									Название
								</StyledTableCell>
								<StyledTableCell align="left" colSpan={2} style={{ minWidth: '30%' }}>
									Чаптер
								</StyledTableCell>
								<StyledTableCell align="left" colSpan={2} style={{ minWidth: '30%' }}>
									Назначение
								</StyledTableCell>
								<StyledTableCell align="left" colSpan={2} style={{ minWidth: '30%' }}>
									Категория
								</StyledTableCell>

								<StyledTableCell align="center" colSpan={1} style={{ minWidth: '30%' }}>
									Кол-во пользователей
								</StyledTableCell>
								<StyledTableCell align="center" colSpan={1} style={{ minWidth: '30%' }}>
									Владелец группы
								</StyledTableCell>

								<StyledTableCell align="center" colSpan={2} style={{ minWidth: '30%' }}>
									Обновлено
								</StyledTableCell>

								<StyledTableCell align="left" colSpan={4} style={{ minWidth: '40%' }}>
									Ссылка на группу
								</StyledTableCell>
								<StyledTableCell align="left" colSpan={2} style={{ minWidth: '40%' }}>
									Юнит
								</StyledTableCell>
								<StyledTableCell align="left" colSpan={4} style={{ minWidth: '40%' }}>
									Комментарий
								</StyledTableCell>

								<StyledTableCell align="center" colSpan={1} style={{ minWidth: '10%' }}>
									Контроль
								</StyledTableCell>

								<StyledTableCell align="center" colSpan={1} style={{ minWidth: '10%' }}>
									Разрешения
								</StyledTableCell>

								<StyledTableCell align="center" colSpan={3} style={{ minWidth: '20%' }}>
									Обновить
								</StyledTableCell>
							</TableRow>
						</TableHead>
						{(groupsFilter?.data?.length ?? 0) > 0 && !groupsFilterIsLoad && (
							<TableBody style={{ background: '#00679eba' }}>
								{groupsFilter?.data?.map?.((item) => (
									<StyledTableRow key={item.id}>
										<StyledTableCell align="center" colSpan={1}>
											{item.id}
										</StyledTableCell>
										<StyledTableCell align="left" colSpan={2}>
											{item.name}
										</StyledTableCell>
										<StyledTableCell
											align="left"
											colSpan={2}
											sx={{
												cursor: 'pointer',
												color: '#66cbff!important',
												'&:hover': {
													backgroundColor: '#00000020',
													color: '#66cbff',
												},
											}}
											onClick={() => {
												setEditedGroupId(item.id);
												setOpenChapterEditDialog(true);
											}}
										>
											{item.chapter.value}
										</StyledTableCell>
										<StyledTableCell
											align="left"
											colSpan={2}
											sx={{
												cursor: 'pointer',
												color: '#66cbff!important',
												'&:hover': {
													backgroundColor: '#00000020',
													color: '#66cbff',
												},
											}}
											onClick={() => {
												setEditedGroupId(item.id);
												setOpenPurposeEditDialog(true);
											}}
										>
											{item.purpose.value}
										</StyledTableCell>
										<StyledTableCell
											align="left"
											colSpan={2}
											sx={{
												cursor: 'pointer',
												color: '#66cbff!important',
												'&:hover': {
													backgroundColor: '#00000020',
													color: '#66cbff',
												},
											}}
											onClick={() => {
												setEditedGroupId(item.id);
												setOpenCategoryEditDialog(true);
											}}
										>
											{item.category.value}
										</StyledTableCell>
										<StyledTableCell align="center" colSpan={1}>
											{item.userCount}
										</StyledTableCell>
										<StyledTableCell align="center" colSpan={1}>
											{item.owner}
										</StyledTableCell>
										<StyledTableCell align="center" colSpan={2}>
											{DateTime.fromISO(item.updatedAt).toFormat('dd.MM.yyyy HH:mm:ss')}
										</StyledTableCell>

										<StyledTableCell align="left" colSpan={4}>
											{item.inviteLink ? (
												<a
													target="_blank"
													href={item.inviteLink}
													rel="noreferrer"
													style={{ color: '#66cbff' }}
												>
													{item.inviteLink}
												</a>
											) : (
												''
											)}
										</StyledTableCell>
										<StyledTableCell
											align="center"
											colSpan={2}
											sx={{
												cursor: 'pointer',
												color: '#66cbff!important',
												'&:hover': {
													backgroundColor: '#00000020',
													color: '#66cbff',
												},
											}}
											onClick={() => {
												setEditedGroupId(item.id);
												setOpenUnitEditDialog(true);
											}}
										>
											{item.unit.value}
										</StyledTableCell>
										<StyledTableCell
											align="center"
											colSpan={4}
											sx={{
												cursor: 'pointer',
												color: '#66cbff!important',
												'&:hover': {
													backgroundColor: '#00000020',
													color: '#66cbff',
												},
											}}
											onClick={() => {
												setEditedGroupId(item.id);
												setOpenCommentEditDialog(true);
											}}
										>
											{item.comment}
										</StyledTableCell>

										<StyledTableCell align="center" colSpan={1}>
											<Switch
												color="secondary"
												checked={item.isControlledByBot}
												disabled={!item.isControlledByBot && !item.isBotHasRequiredPermissions}
												onChange={() => {
													item.isControlledByBot
														? resetGroupControlHandler(item.id)
														: setGroupControlHandler(item.id);
												}}
											/>
										</StyledTableCell>

										<StyledTableCell align="center" colSpan={1}>
											{item.isBotHasRequiredPermissions ? (
												<DoneAllIcon color="secondary" />
											) : (
												<RemoveDoneIcon color="primary" />
											)}
										</StyledTableCell>

										<StyledTableCell align="center" colSpan={3}>
											<LightTooltip title="Обновить информацию о группах" placement="top" arrow>
												<IconButton
													color="secondary"
													aria-label="button"
													component="label"
													onClick={() => groupsUpdateHandler(item.id)}
												>
													<GroupWorkIcon />
												</IconButton>
											</LightTooltip>
											<LightTooltip
												title="Обновить информацию о пользователях"
												placement="top"
												arrow
											>
												<IconButton
													color="secondary"
													aria-label="button"
													component="label"
													onClick={() => usersUpdateHandler(item.id)}
												>
													<SupervisedUserCircleIcon />
												</IconButton>
											</LightTooltip>
										</StyledTableCell>
									</StyledTableRow>
								))}
							</TableBody>
						)}
					</Table>
					{!((groupsFilter?.data?.length ?? 0) > 0) && !groupsFilterIsLoad && (
						<div className="empty-data">
							<p className="empty__text">Группы не найдены...</p>
						</div>
					)}
					{!!groupsFilterIsLoad && (
						<div
							style={{
								minHeight: '300px',
								minWidth: '100%',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								backgroundColor: '#016193',
							}}
						>
							<Loader />
						</div>
					)}
				</TableContainer>
			</Paper>
			{(groupsFilter?.totalCount ?? 0) > PAGE_SIZE && (
				<Stack spacing={2} marginTop={2} marginBottom={2} style={{ paddingBottom: '20px' }}>
					<Pagination
						count={Math.ceil((groupsFilter?.totalCount ?? 1) / PAGE_SIZE)}
						page={+(pageNumber ?? 1)}
						onChange={handlePageChange}
						// variant="outlined"
						shape="rounded"
						color="primary"
						sx={{
							'& .MuiPaginationItem-root': {
								color: '#fff',
							},
							'& .Mui-selected': {
								backgroundColor: '#016193!important',
							},
							mb: 2,
						}}
					/>
				</Stack>
			)}
			<EditDialog
				open={openChapterEditDialog}
				setClose={setClose}
				handleEdit={handleChapterEdit}
				text={`Изменить Чаптер для группы ${String(
					groupsFilter?.data?.find((item) => item.id === editedGroupId)?.name,
				)}`}
				items={groupsFilterValues?.chapters.map((item) => item.value) || []}
				selected={groupsFilter?.data?.find((item) => item.id === editedGroupId)?.chapter?.key ?? 0}
			/>
			<EditDialog
				open={openPurposeEditDialog}
				setClose={setClose}
				handleEdit={handlePurposeEdit}
				text={`Изменить Назначение для группы ${String(
					groupsFilter?.data?.find((item) => item.id === editedGroupId)?.name,
				)}`}
				items={groupsFilterValues?.purposes.map((item) => item.value) || []}
				selected={groupsFilter?.data?.find((item) => item.id === editedGroupId)?.purpose?.key ?? 0}
			/>
			<EditDialog
				open={openCategoryEditDialog}
				setClose={setClose}
				handleEdit={handleCategoryEdit}
				text={`Изменить Категорию для группы ${String(
					groupsFilter?.data?.find((item) => item.id === editedGroupId)?.name,
				)}`}
				items={groupsFilterValues?.categories.map((item) => item.value) || []}
				selected={groupsFilter?.data?.find((item) => item.id === editedGroupId)?.category?.key ?? 0}
			/>
			<EditDialog
				open={openUnitEditDialog}
				setClose={setClose}
				handleEdit={handleUnitEdit}
				text={`Изменить Юнит для группы ${String(
					groupsFilter?.data?.find((item) => item.id === editedGroupId)?.name,
				)}`}
				items={groupsFilterValues?.units.map((item) => item.value) || []}
				selected={groupsFilter?.data?.find((item) => item.id === editedGroupId)?.unit?.key ?? 0}
			/>
			<EditTextDialog
				open={openCommentEditDialog}
				setClose={setClose}
				handleEdit={handleCommentEdit}
				text={`Изменить Комментарий для группы ${String(
					groupsFilter?.data?.find((item) => item.id === editedGroupId)?.name,
				)}`}
				value={groupsFilter?.data?.find((item) => item.id === editedGroupId)?.comment || ''}
			/>
		</>
	);
};
