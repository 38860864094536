/* eslint-disable react-hooks/exhaustive-deps */
import { FC } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import { StyledTableCell, StyledTableRow } from 'ui/StyledTable';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { MarkdownToHtmlConverter } from 'components/OnboardingMessagesEditor/ConvertMarkdownToHtml/MarkdownToHtmlConverter';
import { TMessagesTableProp } from './types';

export const MessagesTable: FC<TMessagesTableProp> = ({
	messages,
	handleMassageDown,
	handleMassageUp,
	handleClickOpenDeleteDialog,
	setMessageId,
	handlerMessagesClick,
	type,
}) => {
	return (
		<Paper>
			<TableContainer>
				<Table stickyHeader aria-label="sticky table">
					<TableHead>
						<TableRow>
							<StyledTableCell align="center" colSpan={1}>
								id
							</StyledTableCell>
							<StyledTableCell align="left" colSpan={3}>
								Заголовок
							</StyledTableCell>
							<StyledTableCell align="left" colSpan={4}>
								Сообщение
							</StyledTableCell>

							<StyledTableCell align="center" colSpan={1}>
								Действие
							</StyledTableCell>
							<StyledTableCell align="center" colSpan={3}>
								Изменить позицию
							</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody style={{ background: '#00679eba' }}>
						{messages?.map((item, index) => (
							<StyledTableRow
								hover
								key={item.id}
								onClick={() => handlerMessagesClick(item.id, type)}
							>
								<StyledTableCell align="center" colSpan={1}>
									{item.id}
								</StyledTableCell>
								<StyledTableCell align="left" colSpan={3}>
									{item.title}
								</StyledTableCell>
								<StyledTableCell align="left" colSpan={4}>
									<MarkdownToHtmlConverter markdownText={item.body} />
								</StyledTableCell>

								<StyledTableCell align="center" colSpan={1}>
									<DeleteIcon
										sx={{ cursor: 'pointer' }}
										onClick={(e) => {
											e.stopPropagation();
											handleClickOpenDeleteDialog();
											// handleDeleteClient(item.id);
											setMessageId(item.id);
										}}
									/>
								</StyledTableCell>
								<StyledTableCell align="center" colSpan={3}>
									{index > 0 && (
										<ArrowUpwardIcon
											sx={{ cursor: 'pointer', mr: '20px' }}
											onClick={(e) => {
												e.stopPropagation();
												handleMassageUp(item.id);
											}}
										/>
									)}
									{index < (messages?.length ?? 0) - 1 && (
										<ArrowDownwardIcon
											sx={{ cursor: 'pointer' }}
											onClick={(e) => {
												e.stopPropagation();
												handleMassageDown(item.id);
											}}
										/>
									)}
								</StyledTableCell>
							</StyledTableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Paper>
	);
};
