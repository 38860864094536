import { type } from 'os';
import { mailingList } from './selectors';

export type TOnboardingMessagesStore = {
	onboardingMessages: TOnboardingMessagesData;
	onboardingMessagesLoader: boolean;
	postOnboardingMessages: TPostOnboardingMessagesData;
	postOnboardingMessagesLoader: boolean;
	onboardingMessagesTrySendLoader: boolean;
	onboardingMessagesTrySend: TOnboardingMessagesTrySendData;
	sendToAllLoader: boolean;
	getNPS: TGetNPSData;
	getNPSLoader: boolean;
	npsChapters: TNPSChaptersData;
	npsChaptersLoader: boolean;
	mailingList: TMailingListData;
	mailingListLoader: boolean;
	mailingListMessage: TMailingListMessageData;
	mailingListMessageLoader: boolean;
	mailingListHistory: TMailingListHistoryData;
	mailingListHistoryLoader: boolean;
};

export type TOnboardingMessagesRequestPayload = {
	pageNumber?: number | null;
	pageSize?: number;
};

export type TOnboardingMessagesItem = {
	id: number;
	title: string;
	body: string;
	fullBody: string;
	orderPosition: number;
};

export type TOnboardingMessages = {
	forOldUsers: TOnboardingMessagesItem[];
	forNewUsers: TOnboardingMessagesItem[];
};

// export type TOnboardingMessages = {
// 	items: TOnboardingMessagesItem[];
// 	itemsCount?: number;
// 	pageSize?: number;
// 	pageNumber?: number;
// };

export type TOnboardingMessagesData = TOnboardingMessages | null;

export type TOnboardingMessagesResponsePayload = TOnboardingMessagesData;

// ==========================================
export const MESSAGES_FOR_NEW_USERS = 0;
export const MESSAGES_FOR_OLD_USERS = 1;

export type TPostOnboardingMessagesRequestType =
	| typeof MESSAGES_FOR_NEW_USERS
	| typeof MESSAGES_FOR_OLD_USERS;

export type TPostOnboardingMessagesRequestPayload = {
	id: number;
	title: string;
	body: string;
	fullBody: string;
	type: TPostOnboardingMessagesRequestType;
};

export type TPostOnboardingMessagesItem = {
	id: number;
	title: string;
	body: string;
	foolBody: string;
	orderPosition: number;
};
export type TPostOnboardingMessages = TPostOnboardingMessagesItem[];

// export type TPostOnboardingMessages = {
// 	items: TPostOnboardingMessagesItem[];
// 	itemsCount?: number;
// 	pageSize?: number;
// 	pageNumber?: number;
// };

export type TPostOnboardingMessagesData = TPostOnboardingMessages | null;

export type TPostOnboardingMessagesResponsePayload = TPostOnboardingMessagesData;

// ==========================================

export type TOnboardingMessagesTrySendRequestPayload = {
	messageText: string;
};

export type TOnboardingMessagesTrySend = {
	message: string;
};

export type TOnboardingMessagesTrySendData = TOnboardingMessagesTrySend | null;

export type TOnboardingMessagesTrySendResponsePayload = TOnboardingMessagesTrySendData;

// ==========================================

export type TOnboardingMessagesUpRequestPayload = {
	messageId: number;
};

// ==========================================

export type TDeleteOnboardingMessagesRequestPayload = {
	messageId: number;
};

// ==========================================

export type TSendToAllRequestPayload = {
	message: string;
};
// ==========================================
export type TSendToUserRequestPayload = {
	message: string;
	userId: number;
};

// ==========================================

export type TGetNPSItem = {
	id: number;
	text: string;
};

export type TGetNPS = TGetNPSItem[];

export type TGetNPSData = TGetNPS | null;

export type TGetNPSResponsePayload = TGetNPSData;

// ==========================================

export type TSetNPSRequestPayload = {
	npsQuestionId: number;
	text: string;
};

// ==========================================

export type TYears =
	| 2023
	| 2023
	| 2024
	| 2025
	| 2026
	| 2027
	| 2028
	| 2029
	| 2030
	| 2031
	| 2032
	| 2033;

export type TMonths = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export type TStartNewSurveyRequestPayload = {
	year: TYears;
	month: TMonths;
	chapters: number[];
};

// ==========================================

export type TNpsChaptersItem = {
	id: number;
	name: string;
};

export type TNPSChapters = TNpsChaptersItem[];

export type TNPSChaptersData = TNPSChapters | null;
export type TNPSChaptersResponsePayload = TNPSChaptersData;

// ==========================================

export type TMailingListRequestPayload = {
	pageNumber?: number;
	pageSize?: number;
};

export type TMailingListDataItem = {
	id: number;
	title: string;
	body: string;
	fullBody: string;
	dateToSend: string;
	sender: 0 | 1;
	status: string;
};

export type TMailingList = {
	data: TMailingListDataItem[];
	pageSize?: number;
	pageNumber?: number;
	totalCount?: number;
};

export type TMailingListData = TMailingList | null;

export type TMailingListResponsePayload = TMailingListData;

// ==========================================

export const MAILING_LIST_SENDER_BOT = 0;
export const MAILING_LIST_SENDER_USER = 1;

export type TMailingListSender = typeof MAILING_LIST_SENDER_BOT | typeof MAILING_LIST_SENDER_USER;

export type TPostMailingListRequestPayload = {
	id: number;
	title: string;
	body: string;
	fullBody: string;
	dateToSend: string;
	sender: TMailingListSender;
};

export type TPostMailingListData = TMailingListDataItem | null;

export type TPostMailingListResponsePayload = TPostMailingListData;

// ==========================================

export type TDeleteMailingListRequestPayload = {
	mailListId: number;
};

// ==========================================

export type TMailingListMessageRequestPayload = {
	mailListId: number;
};

export type TMailingListMessageData = TMailingListDataItem | null;

export type TMailingListMessageResponsePayload = TMailingListMessageData;

// ==========================================

export type TMailingListHistoryRequestPayload = {
	mailListId: number;
};

export type TMailingListHistoryDataItem = {
	receiverName: string;
	date: string;
	phoneNumber: string;
	status: string;
};

export type TMailingListHistory = TMailingListHistoryDataItem[];

export type TMailingListHistoryData = TMailingListHistory | null;

export type TMailingListHistoryResponsePayload = TMailingListHistoryData;
