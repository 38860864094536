/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import {
	Box,
	Container,
	IconButton,
	Pagination,
	Paper,
	Stack,
	Switch,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import {
	getChapterListRequest,
	getCommunityManagerListRequest,
	getUsersRequest,
} from 'redux/reducers/users/reducer';
import {
	getChapterList,
	getCommunityManagerList,
	getUsersIsLoad,
	getUsersList,
} from 'redux/reducers/users/selectors';
import { Loader } from 'ui/Loader';
import { StyledTableCell, StyledTableRow } from 'ui/StyledTable';
import { useDebounce } from 'use-debounce';
import queryString from 'query-string';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { PAGE_SIZE, USER_ROLE_MANAGER, USER_ROLE_USER } from 'redux/reducers/users/constants';
import {
	TChapterListResponsePayload,
	TCommunityManagerListItem,
	TUsersRequestPayload,
} from 'redux/reducers/users/types';
import { SearchComponent } from '../../ui/SearchComponent';
import { Filter } from '../../ui/Filter';
import { AutocompleteTextField } from '../../ui/AutocompleteTextField';

export const Users: FC = () => {
	const stringToBoolean = (myValue: string) => myValue === 'true';
	const navigate = useNavigate();
	const location = useLocation();
	const { search } = useLocation();
	const communityManagerList = [
		...(useSelector(getCommunityManagerList) ?? []),
		// { name: 'все', id: 'all' },
	];
	const chapterList = [
		...(useSelector(getChapterList) ?? []),
		// { name: 'все' }
	];
	const { pageNumber, isUseRadndomCoffee, role, isActive, searchString } =
		queryString.parse(search);
	const [currentPage, setCurrentPage] = useState(Number(pageNumber) || 1);

	const [communityManagerSelected, setCommunityManagerSelected] =
		useState<TCommunityManagerListItem | null>({ id: 'all', name: 'все' });

	const [chapterSelected, setChapterSelected] = useState<TCommunityManagerListItem | null>({
		id: 'all',
		name: 'все',
	});

	const [searchStr, setSearchStr] = useState((searchString as string) || '');
	const [searchStrDebounce] = useDebounce(searchStr, 600);

	const randomCoffeeFilterItemsRu = ['все', 'подключенные', 'неподключенные'];
	const randomCoffeeFilterItems = [null, true, false];
	const [randomCoffeeFilter, setRandomCoffeeFilter] = useState(
		!isUseRadndomCoffee
			? 0
			: randomCoffeeFilterItems.indexOf(stringToBoolean(isUseRadndomCoffee as string)),
	);

	const roleFilterItemsRu = ['все', 'пользователь', 'менеджер'];
	const roleFilterItems = [null, USER_ROLE_USER, USER_ROLE_MANAGER];

	const [roleFilter, setRoleFilter] = useState(
		roleFilterItems.indexOf(role as unknown as string) >= 0
			? roleFilterItems.indexOf(role as unknown as string)
			: 0,
	);

	const activeFilterItemsRu = ['все', 'активные', 'неактивные'];
	const activeFilterItems = [null, true, false];
	const [activeFilter, setActiveFilter] = useState(
		!isActive ? 0 : activeFilterItems.indexOf(stringToBoolean(isActive as string)),
	);

	const isGuestMonthFilterItemsRu = ['все', 'да', 'нет'];
	const isGuestMonthFilterItems = [null, true, false];
	const [isGuestMonthFilter, setIsGuestMonthFilter] = useState(
		!isActive ? 0 : isGuestMonthFilterItems.indexOf(stringToBoolean(isActive as string)),
	);

	const handleIsGuestMonth = (index: number) => {
		setIsGuestMonthFilter(index);
		setCurrentPage(1);
		navigate({
			pathname: location.pathname,
			search: `?pageNumber=1${
				randomCoffeeFilter
					? `&isUseRadndomCoffee=${randomCoffeeFilterItems[randomCoffeeFilter]}`
					: ''
			}${roleFilter ? `&role=${roleFilterItems[roleFilter]}` : ''}${
				activeFilter ? `&isActive=${activeFilterItems[activeFilter]}` : ''
			}${index ? `&isGuestMonth=${isGuestMonthFilterItems[index]}` : ''}${
				searchStr !== '' ? `&searchString=${searchStr}` : ''
			}${communityManagerSelected ? `&communityManagerId=${communityManagerSelected.id}` : ''}${
				chapterSelected ? `&chapter=${chapterSelected.name}` : ''
			}`,
		});
	};

	const handleRandomCoffee = (index: number) => {
		setRandomCoffeeFilter(index);
		setCurrentPage(1);
		navigate({
			pathname: location.pathname,
			search: `?pageNumber=1${
				index ? `&isUseRadndomCoffee=${randomCoffeeFilterItems[index]}` : ''
			}${roleFilter ? `&role=${roleFilterItems[roleFilter]}` : ''}${
				activeFilter ? `&isActive=${activeFilterItems[activeFilter]}` : ''
			}${isGuestMonthFilter ? `&isGuestMonth=${isGuestMonthFilterItems[isGuestMonthFilter]}` : ''}${
				searchStr !== '' ? `&searchString=${searchStr}` : ''
			}${communityManagerSelected ? `&communityManagerId=${communityManagerSelected.id}` : ''}${
				chapterSelected ? `&chapter=${chapterSelected.name}` : ''
			}`,
		});
	};
	const handleRole = (index: number) => {
		setRoleFilter(index);
		setCurrentPage(1);
		navigate({
			pathname: location.pathname,
			search: `?pageNumber=1${
				randomCoffeeFilter
					? `&isUseRadndomCoffee=${randomCoffeeFilterItems[randomCoffeeFilter]}`
					: ''
			}${index ? `&role=${roleFilterItems[index]}` : ''}${
				activeFilter ? `&isActive=${activeFilterItems[activeFilter]}` : ''
			}${isGuestMonthFilter ? `&isGuestMonth=${isGuestMonthFilterItems[isGuestMonthFilter]}` : ''}${
				searchStr !== '' ? `&searchString=${searchStr}` : ''
			}${communityManagerSelected ? `&communityManagerId=${communityManagerSelected.id}` : ''}${
				chapterSelected ? `&chapter=${chapterSelected.name}` : ''
			}`,
		});
	};

	const handleActive = (index: number) => {
		setActiveFilter(index);
		setCurrentPage(1);
		navigate({
			pathname: location.pathname,
			search: `?pageNumber=1${
				randomCoffeeFilter
					? `&isUseRadndomCoffee=${randomCoffeeFilterItems[randomCoffeeFilter]}`
					: ''
			}${roleFilter ? `&role=${roleFilterItems[roleFilter]}` : ''}${
				index ? `&isActive=${activeFilterItems[index]}` : ''
			}${isGuestMonthFilter ? `&isGuestMonth=${isGuestMonthFilterItems[isGuestMonthFilter]}` : ''}${
				searchStr !== '' ? `&searchString=${searchStr}` : ''
			}${communityManagerSelected ? `&communityManagerId=${communityManagerSelected.id}` : ''}${
				chapterSelected ? `&chapter=${chapterSelected.name}` : ''
			}`,
		});
	};

	const users = useSelector(getUsersList);
	const usersIsLoad = useSelector(getUsersIsLoad);
	const dispatch = useDispatch();
	// useEffect(() => {
	// 	dispatch(getUsersRequest({ pageSize: PAGE_SIZE }));
	// }, [dispatch]);

	const setCurrentPageHandler = (page: number) => {
		setCurrentPage(page);
		const params: TUsersRequestPayload = {
			pageNumber: page || 1,
			pageSize: PAGE_SIZE,
			searchString: searchStr || undefined,
			isUseRadndomCoffee: randomCoffeeFilterItems[randomCoffeeFilter],
			role: roleFilterItems[roleFilter] || undefined,
			isActive: activeFilterItems[activeFilter],
			communityManagerId: communityManagerSelected?.id,
		};
		dispatch(getUsersRequest(params));
	};

	const handlePageChange = (event: unknown, page: number) => {
		if (page === currentPage) return;
		setCurrentPageHandler(page);
		navigate({
			pathname: location.pathname,
			search: `?pageNumber=${String(page)}${
				randomCoffeeFilter
					? `&isUseRadndomCoffee=${randomCoffeeFilterItems[randomCoffeeFilter]}`
					: ''
			}${roleFilter ? `&role=${roleFilterItems[roleFilter]}` : ''}${
				activeFilter ? `&isActive=${activeFilterItems[activeFilter]}` : ''
			}${searchStr !== '' ? `&searchString=${searchStr}` : ''}${
				communityManagerSelected ? `&communityManagerId=${communityManagerSelected.id}` : ''
			}`,
		});
	};

	const handleSearchStr = (value: string) => {
		setCurrentPage(1);
		setSearchStr(value);
		navigate({
			pathname: location.pathname,
			search: `?pageNumber=1${
				randomCoffeeFilter
					? `&isUseRadndomCoffee=${randomCoffeeFilterItems[randomCoffeeFilter]}`
					: ''
			}${roleFilter ? `&role=${roleFilterItems[roleFilter]}` : ''}${
				activeFilter ? `&isActive=${activeFilterItems[activeFilter]}` : ''
			}${value !== '' ? `&searchString=${value}` : ''}${
				communityManagerSelected ? `&communityManagerId=${communityManagerSelected.id}` : ''
			}${chapterSelected ? `&chapter=${chapterSelected.name}` : ''}`,
		});
	};

	const handleCommunityManagerSelected = (value: TCommunityManagerListItem | null) => {
		const newValues = value || { id: '00000000-0000-0000-0000-000000000001', name: 'все' };
		setCurrentPage(1);
		setCommunityManagerSelected(newValues);
		navigate({
			pathname: location.pathname,
			search: `?pageNumber=1${
				randomCoffeeFilter
					? `&isUseRadndomCoffee=${randomCoffeeFilterItems[randomCoffeeFilter]}`
					: ''
			}${roleFilter ? `&role=${roleFilterItems[roleFilter]}` : ''}${
				activeFilter ? `&isActive=${activeFilterItems[activeFilter]}` : ''
			}${newValues ? `&communityManagerId=${newValues.id}` : ''}${
				searchStr !== '' ? `&searchString=${searchStr}` : ''
			}${chapterSelected ? `&chapter=${chapterSelected.name}` : ''}`,
		});
	};

	const handleChapterSelected = (value: TCommunityManagerListItem | null) => {
		const newValues = value || { name: 'все', id: 'all' };
		setCurrentPage(1);
		setChapterSelected(newValues);
		navigate({
			pathname: location.pathname,
			search: `?pageNumber=1${
				randomCoffeeFilter
					? `&isUseRadndomCoffee=${randomCoffeeFilterItems[randomCoffeeFilter]}`
					: ''
			}${roleFilter ? `&role=${roleFilterItems[roleFilter]}` : ''}${
				activeFilter ? `&isActive=${activeFilterItems[activeFilter]}` : ''
			}${communityManagerSelected ? `&communityManagerId=${communityManagerSelected.id}` : ''}${
				searchStr !== '' ? `&searchString=${searchStr}` : ''
			}${newValues ? `&chapter=${newValues.name}` : ''}`,
		});
	};

	const handleClearAll = () => {
		setCurrentPage(1);
		setSearchStr('');
		setActiveFilter(0);
		setRoleFilter(0);
		setRandomCoffeeFilter(0);
		setIsGuestMonthFilter(0);
		setCommunityManagerSelected(null);
		setChapterSelected(null);
		navigate({
			pathname: location.pathname,
			search: ``,
		});
	};

	useEffect(() => {
		// setCurrentPage(1);

		const params: TUsersRequestPayload = {
			pageNumber: currentPage || 1,
			pageSize: PAGE_SIZE,
			searchString: searchStr || undefined,
			isUseRadndomCoffee: randomCoffeeFilterItems[randomCoffeeFilter],
			role: roleFilterItems[roleFilter] || undefined,
			isActive: activeFilterItems[activeFilter],
			isGuestMonth: isGuestMonthFilterItems[isGuestMonthFilter],
			communityManagerId: communityManagerSelected?.id,
			chapter: chapterSelected?.name === 'все' ? null : chapterSelected?.name,
		};
		dispatch(getUsersRequest(params));
	}, [
		dispatch,
		searchStrDebounce,
		randomCoffeeFilter,
		roleFilter,
		activeFilter,
		isGuestMonthFilter,
		communityManagerSelected,
		chapterSelected,
	]);

	useEffect(() => {
		setCurrentPage(+(pageNumber ?? 1));

		dispatch(getCommunityManagerListRequest());
		dispatch(getChapterListRequest());
	}, [dispatch, pageNumber]);

	const handleSelectUser = (id: number) => {
		navigate(`/users/${id}`);
	};

	return (
		<>
			<Paper>
				<TableContainer>
					<Box>
						<Box
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
								backgroundColor: '#016193',
								color: '#66cbff',
								paddingLeft: '10px',
							}}
						>
							<span color="#66cbff">
								{(users?.totalCount ?? 0) > 0 ? (
									<>
										Кол-во пользователей: <b>{users?.totalCount}</b>
									</>
								) : (
									'Пользователи не найдены...'
								)}
							</span>
							<Box
								style={{
									background: '#016193',
									padding: '10px 10px 10px 10px',
									display: 'flex',
									justifyContent: 'end',
									height: '70px',
								}}
							>
								{searchStr ||
								randomCoffeeFilter ||
								roleFilter ||
								activeFilter ||
								isGuestMonthFilter ||
								communityManagerSelected ? (
									<IconButton onClick={handleClearAll}>
										<FilterListOffIcon style={{ color: '#66cbff' }} fontSize="large" />
									</IconButton>
								) : (
									''
								)}
								<SearchComponent onChange={handleSearchStr} search={searchStr} />
							</Box>
						</Box>
						<Box
							style={{
								display: 'flex',
								justifyContent: 'end',
								alignItems: 'center',
								backgroundColor: '#016193',
								color: '#fff',
								paddingRight: '10px',
							}}
						>
							<AutocompleteTextField
								value={communityManagerSelected}
								onChange={handleCommunityManagerSelected}
								options={communityManagerList ?? []}
								name="Комьюнити Менеджер"
							/>
							<AutocompleteTextField
								value={chapterSelected}
								onChange={handleChapterSelected}
								options={
									chapterList?.map((chapter, index) => {
										return { name: chapter.name, id: String(index) };
									}) ?? []
								}
								name="Chapter"
							/>
							<Filter
								name="Роль"
								items={roleFilterItemsRu}
								onChange={handleRole}
								selectedIndex={roleFilter}
							/>
							<Filter
								name="Статус"
								items={activeFilterItemsRu}
								onChange={handleActive}
								selectedIndex={activeFilter}
							/>
							<Filter
								name="Random Coffee"
								items={randomCoffeeFilterItemsRu}
								onChange={handleRandomCoffee}
								selectedIndex={randomCoffeeFilter}
							/>
							<Filter
								name="Гостевой месяц"
								items={isGuestMonthFilterItemsRu}
								onChange={handleIsGuestMonth}
								selectedIndex={isGuestMonthFilter}
							/>
						</Box>
					</Box>
					{!users?.data?.length && !usersIsLoad && (
						<div className="empty-data">
							<p className="empty__text" />
						</div>
					)}
					{!!usersIsLoad && (
						<Box
							style={{
								minHeight: '300px',
								minWidth: '100%',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								backgroundColor: '#016193',
							}}
						>
							<Loader />
						</Box>
					)}
					{(users?.data?.length ?? 0) > 0 && !usersIsLoad && (
						<Table stickyHeader aria-label="sticky table">
							<TableHead>
								<TableRow>
									<StyledTableCell align="left" colSpan={2}>
										Имя в Telegram
									</StyledTableCell>
									<StyledTableCell align="left" colSpan={2}>
										Ник в Telegram
									</StyledTableCell>
									<StyledTableCell align="left" colSpan={2}>
										Telegram Id
									</StyledTableCell>
									<StyledTableCell align="left" colSpan={2}>
										Имя в МП
									</StyledTableCell>
									<StyledTableCell align="left" colSpan={2}>
										КМ
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={2}>
										Chapter
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={2}>
										Форум группа
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={2}>
										Роль
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={2}>
										Номер телефона
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={1}>
										Random Coffee
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={1}>
										Гостевой месяц
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={1}>
										Статус
									</StyledTableCell>
								</TableRow>
							</TableHead>
							<TableBody style={{ background: '#00679eba' }}>
								{users?.data.map((item) => (
									<StyledTableRow key={item.id} onClick={() => handleSelectUser(item.id)}>
										<StyledTableCell align="left" colSpan={2}>
											{item.telegramName}
										</StyledTableCell>
										<StyledTableCell align="left" colSpan={2}>
											{item.telegramUsername}
										</StyledTableCell>
										<StyledTableCell align="left" colSpan={2}>
											{item.telegramId}
										</StyledTableCell>
										<StyledTableCell align="left" colSpan={2}>
											{item.nameInMobileApp}
										</StyledTableCell>
										<StyledTableCell align="left" colSpan={2}>
											{item.communityManagerName}
										</StyledTableCell>
										<StyledTableCell align="center" colSpan={2}>
											{item.chapter}
										</StyledTableCell>
										<StyledTableCell align="center" colSpan={2}>
											{item.forumGroup}
										</StyledTableCell>
										<StyledTableCell align="center" colSpan={2}>
											{item.role}
										</StyledTableCell>
										<StyledTableCell align="center" colSpan={2}>
											{item.phoneNumber}
										</StyledTableCell>
										<StyledTableCell align="center" colSpan={1}>
											{item.isUseRandomCoffee ? 'Да' : 'Нет'}
										</StyledTableCell>
										<StyledTableCell align="center" colSpan={1}>
											{item.isGuestMonth === null ? '—' : item.isGuestMonth ? 'Да' : 'Нет'}
										</StyledTableCell>

										<StyledTableCell align="center" colSpan={1}>
											{item.isActive ? 'Активен' : 'Неактивен'}
										</StyledTableCell>
									</StyledTableRow>
								))}
							</TableBody>
						</Table>
					)}
				</TableContainer>
			</Paper>
			{(users?.totalCount ?? 0) > PAGE_SIZE && (
				<Stack spacing={2} marginTop={2} style={{ marginBottom: '20px' }}>
					<Pagination
						count={Math.ceil((users?.totalCount ?? 0) / PAGE_SIZE)}
						page={currentPage}
						onChange={handlePageChange}
						// variant="outlined"
						shape="rounded"
						color="primary"
						sx={{
							'& .MuiPaginationItem-root': {
								color: '#fff',
							},
							'& .Mui-selected': {
								backgroundColor: '#016193!important',
							},
							mb: 2,
						}}
					/>
				</Stack>
			)}
		</>
	);
};
