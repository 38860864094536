/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import {
	Button,
	Checkbox,
	Chip,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import { Loader } from 'ui/Loader';
import { StyledTableCell, StyledTableRow } from 'ui/StyledTable';
import { useNavigate, useParams } from 'react-router';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { history } from 'routes/history';
import ReplyIcon from '@mui/icons-material/Reply';
import { useDispatch, useSelector } from 'react-redux';
import {
	getMailingListHistoryRequest,
	getMailingListMessageRequest,
} from 'redux/reducers/onboardingMessages/reducer';
import {
	mailingList,
	mailingListHistory,
	mailingListHistoryIsLoad,
	mailingListIsLoad,
	mailingListMessage,
	mailingListMessageIsLoad,
} from 'redux/reducers/onboardingMessages/selectors';
import { DateTime } from 'luxon';

export const ToAllMessageStatistic: FC = () => {
	const params = useParams<{ messageId: string }>();
	const messagesId = params.messageId;
	const messages = useSelector(mailingListHistory);
	const messagesIsLoad = useSelector(mailingListHistoryIsLoad);

	const [checkboxAll, setCheckboxAll] = useState(true);
	const [checkboxArray, setCheckboxArray] = useState<boolean[]>(messages?.map(() => true) ?? []);

	useEffect(() => {
		setCheckboxArray(messages?.map(() => checkboxAll) ?? []);
	}, [messages]);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		if (!messagesId) return;
		dispatch(
			getMailingListHistoryRequest({
				mailListId: +messagesId,
			}),
		);
	}, [dispatch]);

	const handleToRedactor = () => {
		navigate({
			pathname: '/to-all-editor',
			search: `?type=0`,
		});
	};

	const handleReplayHistory = () => {
		history.back();
	};

	return (
		<>
			<div>
				<Chip
					onClick={handleReplayHistory}
					icon={<ReplyIcon color="primary" />}
					label="Назад"
					sx={{ mb: '20px', my: 2, bgcolor: '#00507a', color: '#66cbff' }}
				/>
				<h1 style={{ color: '#66cbff', marginBottom: '10px' }}>
					Статистика сообщения для всех пользователей
				</h1>
			</div>

			{!((messages?.length ?? 0) > 0) && !messagesIsLoad && (
				<div className="empty-data">
					<p className="empty__text">Сообщения не найдены...</p>
				</div>
			)}
			{!!messagesIsLoad && (
				<div
					style={{
						minHeight: '300px',
						minWidth: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						backgroundColor: '#016193',
					}}
				>
					<Loader />
				</div>
			)}
			{(messages?.length ?? 0) > 0 && !messagesIsLoad && (
				<Paper>
					<TableContainer>
						<Table stickyHeader aria-label="sticky table">
							<TableHead>
								<TableRow>
									<StyledTableCell align="center" colSpan={2}>
										<Checkbox
											defaultChecked
											onChange={(e) => {
												setCheckboxAll(e.target.checked);
												setCheckboxArray(messages?.map(() => e.target.checked) ?? []);
											}}
										/>
									</StyledTableCell>

									<StyledTableCell align="left" colSpan={2}>
										Имя
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={2}>
										Телефон
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={2}>
										Дата
									</StyledTableCell>

									<StyledTableCell align="left" colSpan={2}>
										Статус
									</StyledTableCell>
								</TableRow>
							</TableHead>
							<TableBody style={{ background: '#00679eba' }}>
								{messages?.map?.((item, index) => (
									<StyledTableRow key={item.phoneNumber}>
										<StyledTableCell align="center" colSpan={2}>
											<Checkbox
												checked={checkboxArray[index]}
												onChange={(e) => {
													setCheckboxArray((prev) => {
														const newArray = [...prev];
														newArray[index] = e.target.checked;
														return newArray;
													});
												}}
											/>
										</StyledTableCell>
										<StyledTableCell align="left" colSpan={2}>
											{item.receiverName}
										</StyledTableCell>
										<StyledTableCell align="center" colSpan={2}>
											{item.phoneNumber}
										</StyledTableCell>
										<StyledTableCell align="center" colSpan={2}>
											{DateTime.fromISO(item.date).toLocaleString(DateTime.DATETIME_SHORT)}
										</StyledTableCell>
										<StyledTableCell align="left" colSpan={2}>
											{item.status}
										</StyledTableCell>
									</StyledTableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Paper>
			)}
		</>
	);
};
