import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { FC } from 'react';
import { TAutocompleteTextFieldProps } from './types';

export const AutocompleteTextField: FC<TAutocompleteTextFieldProps> = ({
	value,
	onChange,
	options,
	name,
}) => {
	return (
		<div
			style={{
				backgroundColor: '#003a58',
				width: 300,
				borderRadius: '5px',
				color: '#3683ac',
				padding: '0px 5px 4px 5px',
				marginLeft: '10px',
			}}
		>
			<div>{name}</div>
			<Autocomplete
				value={value?.name ?? ''}
				onChange={(event: any, newValue: string | null) => {
					onChange(options.find((option) => option.name === newValue) ?? null);
				}}
				options={options.map((option) => option.name)}
				// renderInput={(params) => <TextField {...params} />}
				autoComplete={false}
				// sx={{ backgroundColor: '#003a58', width: 300, borderRadius: '5px' }}
				sx={{ left: '-4px', top: '7px', width: '100%', backgroundColor: '#003a58', border: 0 }}
				renderInput={(params) => (
					<div ref={params.InputProps.ref}>
						<input
							type="text"
							{...params.inputProps}
							autoComplete=""
							style={{
								width: '100%',
								backgroundColor: '#003a58',
								border: 0,
								borderRadius: '5px',
								color: '#66cbff',
							}}
						/>
					</div>
				)}
			/>
		</div>
	);
};
