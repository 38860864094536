import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { authInitState } from 'redux/reducers/auth/reducer';
import { getPermission, getUserLoginData } from 'redux/reducers/auth/selectors';
import {
	deleteTelegramConnectionRequest,
	getConnectedTelegramRequest,
} from 'redux/reducers/userProfile/reducer';
import { getConnectedTelegram } from 'redux/reducers/userProfile/selectors';
import { Divider } from '@mui/material';
import { PERMISSIONS_ITEM_LINKS, PermissionsItems, Routes } from 'routes/constants';
import { LogoSvg } from './LogoSvg';
import { AddUserDialog } from './AddUserDialog';

const ResponsiveAppBar = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const connectedTelegram = useSelector(getConnectedTelegram);
	const userLoginData = useSelector(getUserLoginData);

	const navArray = useSelector(getPermission)?.permissions;

	const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
	const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

	const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElNav(event.currentTarget);
	};
	const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const handleLogout = () => {
		setAnchorElUser(null);
		dispatch(authInitState());
		navigate(Routes.login);
	};

	const [openAddClientDialog, setOpenAddClientDialog] = useState(false);

	const handleOpenAddClientDialog = () => {
		handleCloseNavMenu();
		setOpenAddClientDialog(true);
	};

	const handleCloseAddClientDialog = () => {
		setOpenAddClientDialog(false);
	};

	const handleToClients = () => {
		navigate(Routes.onboardingMessages);
	};
	const handleToRedactor = () => {
		navigate(Routes.onboardingMessages);
	};

	const handleToGroups = () => {
		navigate(Routes.groups);
	};
	const handleToUsers = () => {
		navigate(Routes.users);
	};

	const handleToAll = () => {
		navigate(Routes.toAll);
	};
	const handleToNPS = () => {
		navigate(Routes.nps);
	};

	const [openAddUserDialog, setOpenAddUserDialog] = useState(false);

	const handleOpenAddUserDialog = () => {
		dispatch(deleteTelegramConnectionRequest());
		setOpenAddUserDialog(true);
	};

	const handleCloseAddUserDialog = () => {
		setOpenAddUserDialog(false);
	};

	const handleAddUser = () => {
		handleCloseAddUserDialog();
	};

	useEffect(() => {
		dispatch(getConnectedTelegramRequest());
	}, [dispatch]);

	const handleDeleteUser = () => {
		dispatch(deleteTelegramConnectionRequest());
	};

	return (
		<>
			<AppBar
				position="static"
				sx={{
					marginBottom: 4,
					backgroundColor: '#003a57',
					color: 'white',
					backgroundImage: 'none',
				}}
			>
				<Container maxWidth="xl">
					<Toolbar disableGutters>
						{/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
						{/* <Avatar
							alt="A"
							src={AtlasChat}
							sx={{ display: { xs: 'none', md: 'flex' }, mr: 1, bgcolor: 'primary' }}
							style={{
								fontFamily: 'monospace',
								fontWeight: 700,
								fontSize: '38px',
								background: 'primary',
							}}
						/> */}
						<LogoSvg />
						<Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
							<IconButton
								size="large"
								aria-label="account of current user"
								aria-controls="menu-appbar"
								aria-haspopup="true"
								onClick={handleOpenNavMenu}
								color="inherit"
							>
								<MenuIcon />
							</IconButton>
							<Menu
								id="menu-appbar"
								anchorEl={anchorElNav}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'left',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'left',
								}}
								open={Boolean(anchorElNav)}
								onClose={handleCloseNavMenu}
								sx={{
									display: { xs: 'block', md: 'none' },
								}}
							>
								<MenuItem onClick={handleOpenAddClientDialog}>
									{navArray?.includes(PermissionsItems.onboarding) && (
										<Button onClick={handleToClients}>Сообщения онбординга</Button>
									)}
									{navArray?.includes(PermissionsItems.groups) && (
										<Button onClick={handleToGroups}>Группы</Button>
									)}
									{navArray?.includes(PermissionsItems.users) && (
										<Button onClick={handleToUsers}>Пользователи</Button>
									)}
									{navArray?.includes(PermissionsItems.sendAll) && (
										<Button onClick={handleToAll}>Отправить всем</Button>
									)}
									{navArray?.includes(PermissionsItems.nps) && (
										<Button onClick={handleToNPS}>NPS</Button>
									)}
								</MenuItem>
								{/* <MenuItem onClick={handleToClients}>
								<Button onClick={handleToRedactor} sx={{ my: 2, color: 'white', display: 'block' }}>
									Добавить сообщение
								</Button>
							</MenuItem> */}
							</Menu>
						</Box>
						<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
							{navArray?.includes(PermissionsItems.onboarding) && (
								<Button
									onClick={handleToClients}
									sx={{ my: 2, display: 'block' }}
									color={
										location.pathname.startsWith(
											PERMISSIONS_ITEM_LINKS[PermissionsItems.onboarding],
										)
											? 'info'
											: 'secondary'
									}
								>
									Сообщения онбординга
								</Button>
							)}
							{navArray?.includes(PermissionsItems.groups) && (
								<Button
									onClick={handleToGroups}
									sx={{ my: 2, display: 'block' }}
									color={
										location.pathname === PERMISSIONS_ITEM_LINKS[PermissionsItems.groups]
											? 'info'
											: 'secondary'
									}
								>
									Группы
								</Button>
							)}
							{navArray?.includes(PermissionsItems.users) && (
								<Button
									onClick={handleToUsers}
									sx={{ my: 2, display: 'block' }}
									color={
										location.pathname.startsWith(PERMISSIONS_ITEM_LINKS[PermissionsItems.users])
											? 'info'
											: 'secondary'
									}
								>
									Пользователи
								</Button>
							)}
							{navArray?.includes(PermissionsItems.sendAll) && (
								<Button
									onClick={handleToAll}
									sx={{ my: 2, display: 'block' }}
									color={
										location.pathname === Routes.toAll ||
										location.pathname === Routes.toAllMessageIdEditor
											? 'info'
											: 'secondary'
									}
								>
									Отправить всем
								</Button>
							)}
							{navArray?.includes(PermissionsItems.nps) && (
								<Button
									onClick={handleToNPS}
									sx={{ my: 2, display: 'block' }}
									color={
										location.pathname.startsWith(PERMISSIONS_ITEM_LINKS[PermissionsItems.nps])
											? 'info'
											: 'secondary'
									}
								>
									NPS
								</Button>
							)}

							{/* <Button onClick={handleToRedactor} sx={{ my: 2, color: 'white', display: 'block' }}>
							Добавить сообщение
						</Button> */}
						</Box>

						<Box sx={{ flexGrow: 0 }}>
							<Tooltip title="Настройки Аккаунта">
								<IconButton onClick={handleOpenUserMenu} sx={{ p: 0, color: '#00507a' }}>
									<Avatar
										src={userLoginData?.photo_url ?? ''}
										alt="No Name"
										color="#00507a"
										sx={{ color: '#00507a', bgcolor: '#66cbff' }}
									/>
								</IconButton>
							</Tooltip>
							<Menu
								sx={{ mt: '45px' }}
								id="menu-appbar"
								anchorEl={anchorElUser}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								open={Boolean(anchorElUser)}
								onClose={handleCloseUserMenu}
								color="#016193"
							>
								{connectedTelegram?.telegramConnectorPhoneNumber && (
									<>
										<Typography
											textAlign="left"
											sx={{ m: 2, fontWeight: 'bold', color: 'primary.main', fontSize: '18px' }}
										>
											+{connectedTelegram.telegramConnectorPhoneNumber}
										</Typography>
										<Divider variant="middle" />
									</>
								)}
								{!connectedTelegram?.telegramConnectorPhoneNumber && (
									<MenuItem onClick={handleOpenAddUserDialog}>
										<Typography textAlign="center">Добавить телеграм аккаунт</Typography>
									</MenuItem>
								)}
								{connectedTelegram?.telegramConnectorPhoneNumber && (
									<MenuItem onClick={handleDeleteUser}>
										<Typography textAlign="center">Удалить телеграм аккаунт</Typography>
									</MenuItem>
								)}
								<Divider variant="middle" />

								<MenuItem onClick={handleLogout}>
									<Typography textAlign="center">Выйти</Typography>
								</MenuItem>
							</Menu>
						</Box>
					</Toolbar>
				</Container>
			</AppBar>
			<AddUserDialog
				open={openAddUserDialog}
				setClose={handleCloseAddUserDialog}
				handleAddUser={handleAddUser}
			/>
		</>
	);
};
export default ResponsiveAppBar;
