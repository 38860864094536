import { get } from 'http';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	TConnectedTelegramResponsePayload,
	TNewTelegramConnectionRequest,
	TNewTelegramConnectionResponsePayload,
	TTelegramConnectionStatusRequest,
	TTelegramConnectionStatusResponsePayload,
	TTelegramDoLoginRequest,
	TUserProfileStore,
} from './types';
// ==========================================:
export const initialState: TUserProfileStore = {
	connectedTelegram: null,
	connectedTelegramLoading: false,
	// phoneNumber: '',
	// phoneNumberLoading: false,
	sessionId: null,
	sessionIdLoading: false,
	newSessionId: null,
	newSessionIdLoading: false,
	isNeedAction: null,
	nextAction: null,
	telegramStatusLoading: false,
	telegramDoLoginLoading: false,
	telegramDoLogin: false,
};

// ==========================================:
const Groups = createSlice({
	name: '@@userProfile',
	initialState,
	reducers: {
		getConnectedTelegramRequest: (state: TUserProfileStore) => {
			const connectedTelegramRequestState = state;
			connectedTelegramRequestState.connectedTelegramLoading = true;
		},
		getConnectedTelegramSuccess: (
			state: TUserProfileStore,
			action: PayloadAction<TConnectedTelegramResponsePayload>,
		) => {
			const { payload } = action;
			const connectedTelegramSuccessState = state;
			connectedTelegramSuccessState.connectedTelegram = payload;
			connectedTelegramSuccessState.connectedTelegramLoading = false;
		},
		getNewTelegramConnectionRequest: (
			state: TUserProfileStore,
			action: PayloadAction<TNewTelegramConnectionRequest>,
		) => {
			const newTelegramConnectionRequestState = state;
			newTelegramConnectionRequestState.newSessionIdLoading = true;
		},
		getNewTelegramConnectionSuccess: (
			state: TUserProfileStore,
			action: PayloadAction<TNewTelegramConnectionResponsePayload>,
		) => {
			const { payload } = action;
			const newTelegramConnectionSuccessState = state;
			newTelegramConnectionSuccessState.newSessionId = payload?.newSessionId ?? null;
			newTelegramConnectionSuccessState.newSessionIdLoading = false;
		},
		getTelegramConnectionStatusRequest: (
			state: TUserProfileStore,
			action: PayloadAction<TTelegramConnectionStatusRequest>,
		) => {
			// const { payload } = action;
			const telegramConnectionStatusRequestState = state;
			telegramConnectionStatusRequestState.telegramStatusLoading = true;
		},
		getTelegramConnectionStatusSuccess: (
			state: TUserProfileStore,
			action: PayloadAction<TTelegramConnectionStatusResponsePayload>,
		) => {
			const { payload } = action;
			const telegramConnectionStatusSuccessState = state;
			telegramConnectionStatusSuccessState.isNeedAction = payload?.isNeedAction ?? null;
			telegramConnectionStatusSuccessState.nextAction = payload?.nextAction ?? null;
			telegramConnectionStatusSuccessState.telegramStatusLoading = false;
		},
		telegramDoLoginRequest: (
			state: TUserProfileStore,
			action: PayloadAction<TTelegramDoLoginRequest>,
		) => {
			const telegramDoLoginRequestState = state;
			telegramDoLoginRequestState.telegramDoLoginLoading = true;
		},
		telegramDoLoginSuccess: (state: TUserProfileStore) => {
			const telegramDoLoginSuccessState = state;
			telegramDoLoginSuccessState.telegramDoLoginLoading = false;
			telegramDoLoginSuccessState.telegramDoLogin = true;
		},
		deleteTelegramConnectionRequest: (state: TUserProfileStore) => {
			const deleteTelegramConnectionRequestState = state;
			deleteTelegramConnectionRequestState.newSessionId = null;
			deleteTelegramConnectionRequestState.nextAction = null;
			deleteTelegramConnectionRequestState.isNeedAction = null;
			deleteTelegramConnectionRequestState.telegramDoLogin = false;
		},

		userProfileInitState: () => initialState,
	},
});

export default Groups.reducer;
export const {
	getConnectedTelegramRequest,
	getConnectedTelegramSuccess,
	getNewTelegramConnectionRequest,
	getNewTelegramConnectionSuccess,
	getTelegramConnectionStatusRequest,
	getTelegramConnectionStatusSuccess,
	telegramDoLoginRequest,
	telegramDoLoginSuccess,
	deleteTelegramConnectionRequest,
	userProfileInitState,
} = Groups.actions;
