import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import React, { FC, useEffect, useState } from 'react';
import { Box, DialogContent, Menu, MenuItem, TextField } from '@mui/material';
import { TEditTextDialogProps } from './types';

export const EditTextDialog: FC<TEditTextDialogProps> = ({
	open,
	setClose,
	handleEdit,
	text,
	value,
}) => {
	const [inputText, setInputText] = useState(value);

	useEffect(() => {
		setInputText(value);
	}, [value]);

	return (
		<Dialog
			open={open}
			onClose={setClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			sx={{
				'& .MuiDialog-paper': {
					width: '400px',
					backgroundImage: 'none',
					backgroundColor: '#016193',
				},
			}}
		>
			<DialogTitle id="alert-dialog-title">{text}</DialogTitle>
			<DialogContent>
				<TextField
					fullWidth
					multiline
					rows={4}
					value={inputText}
					onChange={(e) => setInputText(e.target.value)}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={setClose}>Отмена</Button>
				<Button onClick={() => handleEdit(inputText)} autoFocus>
					Изменить
				</Button>
			</DialogActions>
		</Dialog>
	);
};
