import { combineReducers } from '@reduxjs/toolkit';
// import { connectRouter } from 'connected-react-router';
import { createRouterReducer } from '@lagunovsky/redux-react-router';
import { History } from 'history';
import groups from './reducers/groups/reducer';
import auth from './reducers/auth/reducer';
import onboardingMessages from './reducers/onboardingMessages/reducer';
import users from './reducers/users/reducer';
import userProfile from './reducers/userProfile/reducer';

const createRootReducer = (history: History) =>
	combineReducers({
		router: createRouterReducer(history),
		auth,
		onboardingMessages,
		groups,
		users,
		userProfile,
	});

export default createRootReducer;
