import { spawn } from 'redux-saga/effects';
import { authSaga } from './reducers/auth/saga';
import { groupsSaga } from './reducers/groups/saga';
import { onboardingMessagesSaga } from './reducers/onboardingMessages/saga';
import { usersSaga } from './reducers/users/saga';
import { userProfileSaga } from './reducers/userProfile/saga';

export function* rootSaga() {
	yield spawn(authSaga);
	yield spawn(onboardingMessagesSaga);
	yield spawn(groupsSaga);
	yield spawn(usersSaga);
	yield spawn(userProfileSaga);
}
