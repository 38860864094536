import {
	Chip,
	Container,
	Pagination,
	Paper,
	Stack,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, useNavigate } from 'react-router';
import { PAGE_SIZE } from 'redux/reducers/users/constants';
import { getUserRandomCoffeePairsRequest, getUserRequest } from 'redux/reducers/users/reducer';
import {
	getUser,
	getUserRandomCoffeePairsIsLoad,
	getUserRandomCoffeePairsList,
} from 'redux/reducers/users/selectors';
import { Loader } from 'ui/Loader';
import { StyledTableCell, StyledTableRow } from 'ui/StyledTable';
import queryString from 'query-string';
import { DateTime } from 'luxon';
import ReplyIcon from '@mui/icons-material/Reply';
import { history } from 'routes/history';
import HandshakeIcon from '@mui/icons-material/Handshake';
import { RandomCoffeeDialog } from './RandomCoffeeDialog';

export const RandomCoffeePairs: FC = () => {
	const { id } = useParams<{ id: string }>();
	const users = useSelector(getUserRandomCoffeePairsList);
	const usersIsLoad = useSelector(getUserRandomCoffeePairsIsLoad);
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const { search } = useLocation();
	const { pageNumber } = queryString.parse(search);
	const userData = useSelector(getUser);

	const [currentPage, setCurrentPage] = useState(Number(pageNumber) || 1);

	// const setCurrentPageHandler = (event: unknown, page: number) => {
	// 	if (!id) return;
	// 	setCurrentPage(page);
	// 	const params: TUserRandomCoffeePairsRequestPayload = {
	// 		userId: +id,
	// 		pageNumber: page || 1,
	// 		pageSize: PAGE_SIZE,
	// 	};
	// 	dispatch(dispatch(getUserRandomCoffeePairsRequest(params)));
	// };

	const handlePageChange = (event: unknown, page: number) => {
		if (page === currentPage) return;
		setCurrentPage(page);
		navigate({
			pathname: location.pathname,
			search: `?pageNumber=${String(page)}`,
		});
	};

	useEffect(() => {
		if (!id) return;
		dispatch(
			getUserRandomCoffeePairsRequest({
				userId: +id,
				pageNumber: currentPage || 1,
				pageSize: PAGE_SIZE,
			}),
		);
	}, [currentPage, dispatch, id]);

	useEffect(() => {
		if (!id) return;
		dispatch(getUserRequest({ userId: +id }));
	}, [dispatch, id]);

	const handleReplayHistory = () => {
		history.back();
	};

	const [openDialog, setDialog] = useState(false);
	const handleOpenDialog = () => {
		setDialog(true);
	};
	const handleCloseDialog = () => {
		setDialog(false);
	};

	return (
		<>
			<Chip
				onClick={handleReplayHistory}
				icon={<ReplyIcon color="primary" />}
				label="Назад"
				sx={{ mb: '0px', my: 2, bgcolor: '#00507a', color: '#66cbff' }}
			/>
			<Chip
				onClick={handleOpenDialog}
				icon={<HandshakeIcon color="primary" />}
				label="Познакомить"
				// variant="outlined"
				color="primary"
				sx={{ bgcolor: '#00507a', color: '#66cbff', mb: '10px', ml: '20px' }}
			/>
			<div>
				<Table stickyHeader aria-label="sticky table">
					<TableHead>
						<TableRow>
							<StyledTableCell align="left" colSpan={2}>
								Имя в Telegram
							</StyledTableCell>
							<StyledTableCell align="left" colSpan={2}>
								Ник в Telegram
							</StyledTableCell>
							<StyledTableCell align="left" colSpan={2}>
								Telegram Id
							</StyledTableCell>
							<StyledTableCell align="left" colSpan={2}>
								Имя в МП
							</StyledTableCell>
							<StyledTableCell align="left" colSpan={2}>
								КМ
							</StyledTableCell>
							<StyledTableCell align="center" colSpan={2}>
								Роль
							</StyledTableCell>
							<StyledTableCell align="center" colSpan={2}>
								Номер телефона
							</StyledTableCell>
							<StyledTableCell align="center" colSpan={1}>
								Random Coffee
							</StyledTableCell>
							<StyledTableCell align="center" colSpan={1}>
								Статус
							</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody style={{ background: '#00679eba' }}>
						<StyledTableRow>
							<StyledTableCell align="left" colSpan={2}>
								{userData?.telegramName}
							</StyledTableCell>
							<StyledTableCell align="left" colSpan={2}>
								{userData?.telegramUsername}
							</StyledTableCell>
							<StyledTableCell align="left" colSpan={2}>
								{userData?.telegramId}
							</StyledTableCell>
							<StyledTableCell align="left" colSpan={2}>
								{userData?.nameInMobileApp}
							</StyledTableCell>
							<StyledTableCell align="left" colSpan={2}>
								{userData?.communityManagerName}
							</StyledTableCell>
							<StyledTableCell align="center" colSpan={2}>
								{userData?.role}
							</StyledTableCell>
							<StyledTableCell align="center" colSpan={2}>
								{userData?.phoneNumber}
							</StyledTableCell>
							<StyledTableCell align="center" colSpan={1}>
								{userData?.isUseRandomCoffee ? 'Да' : 'Нет'}
							</StyledTableCell>
							<StyledTableCell align="center" colSpan={1}>
								{userData?.isActive ? 'Активен' : 'Неактивен'}
							</StyledTableCell>
						</StyledTableRow>
					</TableBody>
				</Table>
			</div>

			<h1 style={{ color: '#66cbff', marginBottom: '10px', marginTop: '50px' }}>
				Состоявшиеся Знакомства Random Coffee
			</h1>

			<Paper>
				<TableContainer>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							backgroundColor: '#016193',
							color: '#fff',
							paddingLeft: '10px',
						}}
					>
						<span>
							{(users?.totalCount ?? 0) > 0 ? (
								<>
									Кол-во пользователей: <b>{users?.totalCount}</b>
								</>
							) : (
								''
							)}
						</span>
					</div>
					{!((users?.data?.length ?? 0) > 0) && !usersIsLoad && (
						<div className="empty-data" style={{ height: '400px' }}>
							<p className="empty__text">Состоявшиеся знакомства не найдены...</p>
						</div>
					)}
					{!!usersIsLoad && (
						<div
							style={{
								minHeight: '300px',
								minWidth: '100%',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								backgroundColor: '#016193',
							}}
						>
							<Loader />
						</div>
					)}

					{(users?.data?.length ?? 0) > 0 && !usersIsLoad && (
						<Table stickyHeader aria-label="sticky table">
							<TableHead>
								<TableRow>
									<StyledTableCell align="left" colSpan={2} style={{ minWidth: '30%' }}>
										Ник в Telegram
									</StyledTableCell>
									<StyledTableCell align="left" colSpan={2} style={{ minWidth: '30%' }}>
										Имя в МП
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={2} style={{ minWidth: '40%' }}>
										Знакомство состоялось
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={2} style={{ minWidth: '10%' }}>
										Обратная связь
									</StyledTableCell>
								</TableRow>
							</TableHead>
							<TableBody style={{ background: '#00679eba' }}>
								{users?.data.map((item) => (
									<StyledTableRow key={item.userId}>
										<StyledTableCell align="left" colSpan={2}>
											{item.telegramUsername}
										</StyledTableCell>
										<StyledTableCell align="left" colSpan={2}>
											{item.nameInMobileApp}
										</StyledTableCell>
										<StyledTableCell align="center" colSpan={2}>
											{DateTime.fromISO(item.createdAt).toFormat('dd.MM.yyyy HH:mm')}
										</StyledTableCell>
										<StyledTableCell align="center" colSpan={2}>
											{item.feedback}
										</StyledTableCell>
									</StyledTableRow>
								))}
							</TableBody>
						</Table>
					)}
				</TableContainer>
			</Paper>
			{(users?.totalCount ?? 0) > PAGE_SIZE && (
				<Stack spacing={2} marginTop={2} style={{ marginBottom: '20px' }}>
					<Pagination
						count={Math.ceil((users?.totalCount ?? 0) / PAGE_SIZE)}
						page={currentPage}
						onChange={handlePageChange}
						// variant="outlined"
						shape="rounded"
						color="primary"
						sx={{
							'& .MuiPaginationItem-root': {
								color: '#fff',
							},
							'& .Mui-selected': {
								backgroundColor: '#016193!important',
							},
							mb: 2,
						}}
					/>
				</Stack>
			)}
			<RandomCoffeeDialog open={openDialog} setClose={handleCloseDialog} />
		</>
	);
};
