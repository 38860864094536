import { Box } from '@mui/material';
import { LoginButton } from '@telegram-auth/react';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { getIsAuth } from 'redux/reducers/auth/selectors';

export const TelegramLoginPage: FC = () => {
	const navigate = useNavigate();

	const ifAuth = useSelector(getIsAuth);
	useEffect(() => {
		if (ifAuth) navigate('/');
	});

	return (
		<Box
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: '60vh',
				color: 'white',
			}}
		>
			<LoginButton
				botUsername={process.env.BOT_USERNAME ?? 'atlantybot'}
				authCallbackUrl="/telegram-auth"
				buttonSize="large"
				cornerRadius={5}
				showAvatar
				lang="en"
			/>
		</Box>
	);
};
