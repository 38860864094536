import { Box, Button, Paper, TextField, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { loginRequest } from 'redux/reducers/auth/reducer';

export const LoginForm: FC = () => {
	const dispatch = useDispatch();

	const [login, setLogin] = useState<string>('');
	const [password, setPassword] = useState<string>('');

	// const EMAIL_REGEXP =
	// 	/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;

	// const isEmailValid = () => {
	// 	if (email === null) return true;
	// 	return EMAIL_REGEXP.test(email ?? '');
	// };

	const handleLoginSubmit = () => {
		dispatch(loginRequest({ login: login ?? '', password: password ?? '' }));
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexWrap: 'wrap',
				justifyContent: 'center',
				backgroundColor: '#016193',
				height: '100vh',

				'& > :not(style)': {
					m: 1,
					width: '300px',
					height: '420px',
					marginTop: '20vh',
					padding: '20px',
				},
			}}
		>
			<Paper
				sx={{
					backgroundColor: '#004368',
					backgroundImage: 'none',
					boxShadow: '0 0 10px 0 #004368',
					textAlign: 'center',
				}}
			>
				<Typography
					variant="h1"
					noWrap
					sx={{
						ml: 1,
						fontFamily: 'monospace',
						fontWeight: 700,
						letterSpacing: '.3rem',
						textDecoration: 'none',
						fontSize: 30,
						color: '#66cbff',
						textAlign: 'center',
					}}
				>
					Atlas chat
				</Typography>
				<Typography
					variant="h6"
					noWrap
					component="a"
					sx={{
						ml: 1,
						fontFamily: 'monospace',
						fontWeight: 700,
						letterSpacing: '.3rem',
						textDecoration: 'none',
						fontSize: 20,
						color: '#66cbff',
					}}
				>
					admin panel
				</Typography>
				<TextField
					label="login"
					type="text"
					variant="outlined"
					sx={{ marginTop: '80px' }}
					value={login}
					onChange={(e) => setLogin(e.target.value)}
					fullWidth
					// error={!isEmailValid()}
					error={login.length > 0 && login.length < 4}
				/>
				<TextField
					label="password"
					type="password"
					variant="outlined"
					sx={{ marginTop: '20px' }}
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					fullWidth
					error={password.length > 0 && password.length < 4}
				/>
				<Button
					size="large"
					sx={{ marginTop: '50px', width: '100%', color: '#016193' }}
					variant="contained"
					color="primary"
					autoFocus
					onClick={handleLoginSubmit}
					disabled={login.length < 4 || password.length < 4}
				>
					Вход
				</Button>
			</Paper>
		</Box>
	);
};
