/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import {
	Button,
	Checkbox,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import { Loader } from 'ui/Loader';
import { StyledTableCell, StyledTableRow } from 'ui/StyledTable';
import { useNavigate } from 'react-router';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { useDispatch, useSelector } from 'react-redux';
import {
	deleteMailingListRequest,
	getMailingListRequest,
} from 'redux/reducers/onboardingMessages/reducer';
import { mailingList, mailingListIsLoad } from 'redux/reducers/onboardingMessages/selectors';
import { MAILING_LIST_SENDER_BOT } from 'redux/reducers/onboardingMessages/types';
import { DeleteDialog } from 'ui/DeleteDialog';
import { DateTime } from 'luxon';

export const ToAllMessage: FC = () => {
	// const messages = [
	// 	{
	// 		id: 5,
	// 		title: 'Тест',
	// 		body: '*Тестовое* _сообщение_ еще __одно__',
	// 		fullBody: 'Тест *Тестовое* _сообщение_ еще __одно__',
	// 		dateToSend: '2023-09-27T12:57:26.431Z',
	// 		sender: 0,
	// 		status: 'Ожидает отправки',
	// 	},
	// 	{
	// 		id: 4,
	// 		title: 'Привет!',
	// 		body: 'Привет!',
	// 		fullBody: 'Привет!',
	// 		dateToSend: '2023-09-27T12:49:16.872Z',
	// 		sender: 1,
	// 		status: 'Ожидает отправки',
	// 	},
	// ];
	// const messagesIsLoad = false;
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		dispatch(getMailingListRequest({}));
	}, [dispatch]);

	const handleToRedactor = (messageId: number) => {
		navigate({
			pathname: `/to-all/${messageId}/editor`,
			// search: `?type=0`,
		});
	};
	const handleToStatistic = (messageId: number) => {
		navigate({
			pathname: `/to-all/${messageId}/statistic`,
		});
	};

	const messages = useSelector(mailingList)?.data;
	const messagesIsLoad = useSelector(mailingListIsLoad);

	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [messageId, setMessageId] = useState(0);

	const handleClickOpenDeleteDialog = (id: number) => {
		setMessageId(id);
		setOpenDeleteDialog(true);
	};

	const handleCloseDeleteDialog = () => {
		setOpenDeleteDialog(false);
	};

	const handleDeleteMessage = () => {
		handleCloseDeleteDialog();
		dispatch(deleteMailingListRequest({ mailListId: messageId }));
	};

	return (
		<>
			<Button
				variant="contained"
				onClick={() => handleToRedactor(0)}
				sx={{ my: 2, color: 'white', display: 'block', backgroundColor: '#00507a' }}
			>
				Добавить сообщение
			</Button>
			{!((messages?.length ?? 0) > 0) && !messagesIsLoad && (
				<div className="empty-data">
					<p className="empty__text">Сообщения не найдены...</p>
				</div>
			)}
			{!!messagesIsLoad && (
				<div
					style={{
						minHeight: '300px',
						minWidth: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						backgroundColor: '#016193',
					}}
				>
					<Loader />
				</div>
			)}
			{(messages?.length ?? 0) > 0 && !messagesIsLoad && (
				<Paper>
					<TableContainer>
						<Table stickyHeader aria-label="sticky table">
							<TableHead>
								<TableRow>
									<StyledTableCell align="left" colSpan={2}>
										Отправитель
									</StyledTableCell>
									<StyledTableCell align="left" colSpan={2}>
										Заголовок
									</StyledTableCell>
									<StyledTableCell align="left" colSpan={2}>
										Текст
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={2}>
										Дата
									</StyledTableCell>

									<StyledTableCell align="left" colSpan={2}>
										Статус
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={2}>
										Действия
									</StyledTableCell>
								</TableRow>
							</TableHead>
							<TableBody style={{ background: '#00679eba' }}>
								{messages?.map?.((item) => (
									<StyledTableRow key={item.id}>
										<StyledTableCell align="left" colSpan={2}>
											{item.sender === MAILING_LIST_SENDER_BOT ? 'Бот' : 'Пользователь'}
										</StyledTableCell>
										<StyledTableCell align="left" colSpan={2}>
											{item.title}
										</StyledTableCell>
										<StyledTableCell align="left" colSpan={2}>
											{item.body}
										</StyledTableCell>
										<StyledTableCell align="center" colSpan={2}>
											{DateTime?.fromISO(item.dateToSend).toLocaleString(DateTime.DATETIME_SHORT) ??
												''}
										</StyledTableCell>
										<StyledTableCell align="left" colSpan={2}>
											{item.status}
										</StyledTableCell>
										<StyledTableCell align="center" colSpan={2}>
											<IconButton
												onClick={(e) => {
													e.stopPropagation();
													handleToStatistic(item.id);
												}}
											>
												<QueryStatsIcon sx={{ cursor: 'pointer', color: '#fff' }} />
											</IconButton>
											<IconButton
												onClick={(e) => {
													e.stopPropagation();
													handleToRedactor(item.id);
												}}
											>
												<EditIcon sx={{ cursor: 'pointer', color: '#fff' }} />
											</IconButton>
											<IconButton
												onClick={(e) => {
													e.stopPropagation();
													handleClickOpenDeleteDialog(item.id);
												}}
											>
												<DeleteIcon sx={{ cursor: 'pointer', color: '#fff' }} />
											</IconButton>
										</StyledTableCell>
									</StyledTableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Paper>
			)}
			<DeleteDialog
				open={openDeleteDialog}
				setClose={handleCloseDeleteDialog}
				handleDelete={handleDeleteMessage}
				text="Вы действительно хотите удалить это сообщение?"
			/>
		</>
	);
};
