import { get } from 'http';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	TUsersStore,
	TUsersRequestPayload,
	TUsersResponsePayload,
	TUserRandomCoffeePairsResponsePayload,
	TUserRandomCoffeePairsRequestPayload,
	TSetUserRandomCoffeePairsRequestPayload,
	TUserRequestPayload,
	TUserResponsePayload,
	TUserRandomCoffeePairsFeatureRequestPayload,
	TUserRandomCoffeePairsFeatureResponsePayload,
	TDeleteUserRandomCoffeePairsRequestPayload,
	TChangeRandomCoffeeRequestPayload,
	TUserGroupsRequestPayload,
	TUserGroupsResponsePayload,
	TDeleteUserGroupRequestPayload,
	TCommunityManagerListResponsePayload,
} from './types';
// ==========================================:
export const initialState: TUsersStore = {
	users: null,
	usersLoader: false,
	user: null,
	userLoader: false,
	userRandomCoffeePairs: null,
	userRandomCoffeePairsLoader: false,
	userRandomCoffeePairsFeature: null,
	userRandomCoffeePairsFeatureLoader: false,
	userGroups: null,
	userGroupsLoader: false,
	communityManagerList: null,
	communityManagerListLoader: false,
	chapterList: null,
	chapterListLoader: false,
};

// ==========================================:
const Users = createSlice({
	name: '@@users',
	initialState,
	reducers: {
		getUsersRequest: (state: TUsersStore, action: PayloadAction<TUsersRequestPayload>) => {
			const usersRequestState = state;
			usersRequestState.users = null;
			usersRequestState.usersLoader = true;
		},
		getUsersSuccess: (state: TUsersStore, action: PayloadAction<TUsersResponsePayload>) => {
			const { payload } = action;
			const usersSuccessState = state;
			usersSuccessState.users = payload;
			usersSuccessState.usersLoader = false;
		},
		getUserRequest: (state: TUsersStore, action: PayloadAction<TUserRequestPayload>) => {
			const usersRequestState = state;
			usersRequestState.user = null;
			usersRequestState.userLoader = true;
		},
		getUserSuccess: (state: TUsersStore, action: PayloadAction<TUserResponsePayload>) => {
			const { payload } = action;
			const usersSuccessState = state;
			usersSuccessState.user = payload;
			usersSuccessState.userLoader = false;
		},

		getUserRandomCoffeePairsRequest: (
			state: TUsersStore,
			action: PayloadAction<TUserRandomCoffeePairsRequestPayload>,
		) => {
			const usersRequestState = state;
			usersRequestState.userRandomCoffeePairsLoader = true;
		},
		getUserRandomCoffeePairsSuccess: (
			state: TUsersStore,
			action: PayloadAction<TUserRandomCoffeePairsResponsePayload>,
		) => {
			const { payload } = action;
			const usersSuccessState = state;
			usersSuccessState.userRandomCoffeePairs = payload;
			usersSuccessState.userRandomCoffeePairsLoader = false;
		},
		setUserRandomCoffeePairsRequest: (
			state: TUsersStore,
			action: PayloadAction<TSetUserRandomCoffeePairsRequestPayload>,
		) => {
			const { payload } = action;
			// const usersRequestState = state;
			// usersRequestState.userRandomCoffeePairsLoader = true;
		},
		getUserRandomCoffeePairsFeatureRequest: (
			state: TUsersStore,
			action: PayloadAction<TUserRandomCoffeePairsFeatureRequestPayload>,
		) => {
			const usersRequestState = state;
			usersRequestState.userRandomCoffeePairsFeatureLoader = true;
		},
		getUserRandomCoffeePairsFeatureSuccess: (
			state: TUsersStore,
			action: PayloadAction<TUserRandomCoffeePairsFeatureResponsePayload>,
		) => {
			const { payload } = action;
			const usersSuccessState = state;
			usersSuccessState.userRandomCoffeePairsFeature = payload;
			usersSuccessState.userRandomCoffeePairsFeatureLoader = false;
		},
		deleteUserRandomCoffeePairsFeatureRequest: (
			state: TUsersStore,
			action: PayloadAction<TDeleteUserRandomCoffeePairsRequestPayload>,
		) => {
			const { payload } = action;
		},
		changeRandomCoffeeRequest: (
			state: TUsersStore,
			action: PayloadAction<TChangeRandomCoffeeRequestPayload>,
		) => {},
		getUserGroupsRequest: (
			state: TUsersStore,
			action: PayloadAction<TUserGroupsRequestPayload>,
		) => {
			const usersRequestState = state;
			usersRequestState.userGroups = null;
			usersRequestState.userGroupsLoader = true;
		},
		getUserGroupsSuccess: (
			state: TUsersStore,
			action: PayloadAction<TUserGroupsResponsePayload>,
		) => {
			const { payload } = action;
			const usersSuccessState = state;
			usersSuccessState.userGroups = payload;
			usersSuccessState.userGroupsLoader = false;
		},
		deleteUserGroupRequest: (
			state: TUsersStore,
			action: PayloadAction<TDeleteUserGroupRequestPayload>,
		) => {},
		getCommunityManagerListRequest: (state: TUsersStore) => {
			const usersRequestState = state;
			usersRequestState.communityManagerList = null;
			usersRequestState.communityManagerListLoader = true;
		},
		getCommunityManagerListSuccess: (
			state: TUsersStore,
			action: PayloadAction<TCommunityManagerListResponsePayload>,
		) => {
			const { payload } = action;
			const usersSuccessState = state;
			usersSuccessState.communityManagerList = payload;
			usersSuccessState.communityManagerListLoader = false;
		},
		getChapterListRequest: (state: TUsersStore) => {
			const usersRequestState = state;
			usersRequestState.chapterList = null;
			usersRequestState.chapterListLoader = true;
		},
		getChapterListSuccess: (
			state: TUsersStore,
			action: PayloadAction<TCommunityManagerListResponsePayload>,
		) => {
			const { payload } = action;
			const usersSuccessState = state;
			usersSuccessState.chapterList = payload;
			usersSuccessState.chapterListLoader = false;
		},

		assetsInitState: () => initialState,
	},
});

export default Users.reducer;
export const {
	getUsersRequest,
	getUsersSuccess,
	getUserRequest,
	getUserSuccess,
	getUserRandomCoffeePairsRequest,
	getUserRandomCoffeePairsSuccess,
	setUserRandomCoffeePairsRequest,
	getUserRandomCoffeePairsFeatureRequest,
	getUserRandomCoffeePairsFeatureSuccess,
	deleteUserRandomCoffeePairsFeatureRequest,
	changeRandomCoffeeRequest,
	getUserGroupsRequest,
	getUserGroupsSuccess,
	deleteUserGroupRequest,
	getCommunityManagerListRequest,
	getCommunityManagerListSuccess,
	getChapterListRequest,
	getChapterListSuccess,
	assetsInitState,
} = Users.actions;
