import { createSelector } from '@reduxjs/toolkit';
import { TStoreState } from 'redux/types';
import { TConnectedTelegramResponsePayload, TUserProfileStore } from './types';

// ==========================================:
const getUserProfileState = (state: TStoreState): TUserProfileStore => state.userProfile;
export const getUserProfile = createSelector(
	[getUserProfileState],
	(userProfile: TUserProfileStore) => userProfile,
);

// ====================================================:
export const getConnectedTelegramIsLoad = createSelector(
	[getUserProfile],
	(userProfile: TUserProfileStore): boolean => userProfile.connectedTelegramLoading,
);

// ====================================================:
export const getConnectedTelegram = createSelector(
	[getUserProfile],
	(userProfile: TUserProfileStore): TConnectedTelegramResponsePayload =>
		userProfile.connectedTelegram,
);

// ====================================================:

export const getNewTelegramConnectionIsLoad = createSelector(
	[getUserProfile],
	(userProfile: TUserProfileStore): boolean => userProfile.newSessionIdLoading,
);

// ====================================================:

// export const getNewTelegramConnection = createSelector(
// 	[getUserProfile],
// 	(userProfile: TUserProfileStore): string | null => userProfile.newSessionId,
// );

// ====================================================:

export const getTelegramConnectionStatusIsLoad = createSelector(
	[getUserProfile],
	(userProfile: TUserProfileStore): boolean => userProfile.telegramStatusLoading,
);

// ====================================================:

export const getTelegramIsNeedAction = createSelector(
	[getUserProfile],
	(userProfile: TUserProfileStore): boolean | null => userProfile.isNeedAction,
);

// ====================================================:

export const getTelegramNextAction = createSelector(
	[getUserProfile],
	(userProfile: TUserProfileStore): string | null => userProfile.nextAction,
);

// ====================================================:

// export const getTelegramStatusIsLoad = createSelector(
// 	[getUserProfile],
// 	(userProfile: TUserProfileStore): boolean => userProfile.telegramStatusLoading,
// );

// ====================================================:

export const getSessionId = createSelector(
	[getUserProfile],
	(userProfile: TUserProfileStore): string | null => userProfile.sessionId,
);

// ====================================================:

export const getNewSessionIdIsLoad = createSelector(
	[getUserProfile],
	(userProfile: TUserProfileStore): boolean => userProfile.newSessionIdLoading,
);

// ====================================================:

export const getNewSessionId = createSelector(
	[getUserProfile],
	(userProfile: TUserProfileStore): string | null => userProfile.newSessionId,
);

// ====================================================:

export const getTelegramDoLoginIsLoad = createSelector(
	[getUserProfile],
	(userProfile: TUserProfileStore): boolean => userProfile.telegramDoLoginLoading,
);

// ====================================================:
