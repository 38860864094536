/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import queryString from 'query-string';
import { Loader } from 'ui/Loader';
import { DeleteDialog } from 'ui/DeleteDialog';
import {
	getOnboardingMessages,
	getOnboardingMessagesIsLoad,
} from 'redux/reducers/onboardingMessages/selectors';
import {
	getOnboardingMessagesRequest,
	onboardingMessagesDeleteRequest,
	onboardingMessagesDownRequest,
	onboardingMessagesUpRequest,
} from 'redux/reducers/onboardingMessages/reducer';
import {
	TOnboardingMessagesRequestPayload,
	TPostOnboardingMessagesRequestType,
} from 'redux/reducers/onboardingMessages/types';
import { Button } from '@mui/material';
import { MessagesTable } from './MessagesTable';

export const OnboardingMessages: FC = () => {
	const dispatch = useDispatch();
	const onboardingMessages = useSelector(getOnboardingMessages);
	const onboardingMessagesIsLoad = useSelector(getOnboardingMessagesIsLoad);

	const navigate = useNavigate();
	const { search } = useLocation();
	const { pageNumber } = queryString.parse(search);

	const handleMassageUp = (messageId: number) => {
		dispatch(onboardingMessagesUpRequest({ messageId }));
	};
	const handleMassageDown = (messageId: number) => {
		dispatch(onboardingMessagesDownRequest({ messageId }));
	};

	useEffect(() => {
		const params: TOnboardingMessagesRequestPayload = {
			pageNumber: +(pageNumber ?? 1),
			pageSize: 10,
		};
		dispatch(getOnboardingMessagesRequest(params));
	}, [dispatch]);

	const handlerMessagesClick = (messagesId: number, type: TPostOnboardingMessagesRequestType) => {
		navigate({
			pathname: `/onboarding-messages/${String(messagesId)}`,
			search: `?type=${String(type)}`,
		});
	};

	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [messageId, setMessageId] = useState(0);

	const handleClickOpenDeleteDialog = () => {
		setOpenDeleteDialog(true);
	};

	const handleCloseDeleteDialog = () => {
		setOpenDeleteDialog(false);
	};

	const handleDeleteMessage = () => {
		handleCloseDeleteDialog();
		dispatch(onboardingMessagesDeleteRequest({ messageId }));
	};

	const handleToRedactor = () => {
		navigate({
			pathname: '/onboarding-messages/new',
			search: `?type=0`,
		});
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const methodDoesNotExist = (): void => {
		throw new Error('Function not implemented.');
	};

	return (
		<>
			<Button
				variant="contained"
				onClick={handleToRedactor}
				sx={{ my: 2, color: 'white', display: 'block', backgroundColor: '#00507a' }}
			>
				Добавить сообщение
			</Button>
			{!((onboardingMessages?.onboardingMessages?.forNewUsers?.length ?? 0) > 0) &&
				!onboardingMessagesIsLoad && (
					<div className="empty-data">
						<p className="empty__text">Сообщения не найдены...</p>
					</div>
				)}
			{!!onboardingMessagesIsLoad && (
				<div
					style={{
						minHeight: '300px',
						minWidth: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						backgroundColor: '#016193',
					}}
				>
					<Loader />
				</div>
			)}
			{(onboardingMessages?.onboardingMessages?.forNewUsers?.length ?? 0) > 0 &&
				!onboardingMessagesIsLoad && (
					<>
						<h2 style={{ color: '#ffffff', margin: '60px 0 20px 0' }}>
							Сообщения для новых пользователей
						</h2>
						<MessagesTable
							messages={onboardingMessages?.onboardingMessages?.forNewUsers}
							handleMassageDown={handleMassageDown}
							handleMassageUp={handleMassageUp}
							handleClickOpenDeleteDialog={handleClickOpenDeleteDialog}
							setMessageId={setMessageId}
							handlerMessagesClick={handlerMessagesClick}
							type={0}
						/>
						<h2 style={{ color: '#ffffff', margin: '60px 0 20px 0' }}>
							Сообщения для существующих пользователей
						</h2>
						<MessagesTable
							messages={onboardingMessages?.onboardingMessages?.forOldUsers}
							handleMassageDown={handleMassageDown}
							handleMassageUp={handleMassageUp}
							handleClickOpenDeleteDialog={handleClickOpenDeleteDialog}
							setMessageId={setMessageId}
							handlerMessagesClick={handlerMessagesClick}
							type={1}
						/>

						<DeleteDialog
							open={openDeleteDialog}
							setClose={handleCloseDeleteDialog}
							handleDelete={handleDeleteMessage}
							text="Вы действительно хотите удалить это сообщение?"
						/>
						{/* <button type="button" onClick={methodDoesNotExist}>
							Break the world
						</button> */}
					</>
				)}
		</>
	);
};
