import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { TGroupsApi } from './types';

export const groups: TGroupsApi = {
	getGroups: (params) =>
		http.get(endpoint.groups.GET_GROUPS, { params }).then((response) => response.data),
	updateGroup: (params) => http.post(endpoint.groups.GROUP_UPDATE(params.groupId)),
	updateUsers: (params) => http.post(endpoint.groups.USERS_UPDATE(params.groupId)),
	updateAllUsers: () => http.post(endpoint.groups.ALL_USERS_UPDATE),
	updateAllGroups: () => http.post(endpoint.groups.ALL_GROUPS_UPDATE),
	setGroupControl: (params) => http.post(endpoint.groups.GROUP_CONTROL(params.groupId)),
	resetGroupControl: (params) => http.delete(endpoint.groups.GROUP_CONTROL(params.groupId)),
	getGroupsFilter: (params) =>
		http.post(endpoint.groups.GET_GROUPS_FILTER, params).then((response) => response.data),
	getGroupsFilterValues: () =>
		http.get(endpoint.groups.GET_GROUP_FILTER_VALUES).then((response) => response.data),
	editGroup: (params) => http.post(endpoint.groups.UPDATE_GROUP, params),
};
