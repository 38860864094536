/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { notificationContainer } from 'utils/notificationContainer';
import {
	deleteTelegramConnectionRequest,
	getConnectedTelegramRequest,
	getConnectedTelegramSuccess,
	getNewTelegramConnectionRequest,
	getNewTelegramConnectionSuccess,
	getTelegramConnectionStatusRequest,
	getTelegramConnectionStatusSuccess,
	telegramDoLoginRequest,
	telegramDoLoginSuccess,
	userProfileInitState,
} from './reducer';
import {
	TConnectedTelegramResponsePayload,
	TNewTelegramConnectionRequest,
	TNewTelegramConnectionResponsePayload,
	TTelegramConnectionStatusRequest,
	TTelegramConnectionStatusResponsePayload,
	TTelegramDoLoginRequest,
} from './types';

function* getConnectedTelegramRequestWorker() {
	try {
		const response: TConnectedTelegramResponsePayload = yield call(
			api.userProfile.getConnectedTelegram,
		);
		yield put(getConnectedTelegramSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error.response?.data ?? error), 'error');
		}
		yield put(userProfileInitState());
	}
}

function* getNewTelegramConnectionRequestWorker(
	action: PayloadAction<TNewTelegramConnectionRequest>,
) {
	try {
		// yield put(deleteTelegramConnectionRequest());
		const response: TNewTelegramConnectionResponsePayload = yield call(
			api.userProfile.getNewTelegramConnection,
			action.payload,
		);
		yield put(getNewTelegramConnectionSuccess(response));
		if (response?.newSessionId)
			yield put(getTelegramConnectionStatusRequest({ sessionId: response?.newSessionId }));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error.response?.data ?? error), 'error');
		}
		yield put(userProfileInitState());
	}
}

function* deleteTelegramConnectionRequestWorker() {
	try {
		yield call(api.userProfile.deleteTelegramConnection);
		yield put(getConnectedTelegramSuccess(null));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error.response?.data ?? error), 'error');
		}
		yield put(userProfileInitState());
	}
}

function* getTelegramConnectionStatusRequestWorker(
	action: PayloadAction<TTelegramConnectionStatusRequest>,
) {
	try {
		const response: TTelegramConnectionStatusResponsePayload = yield call(
			api.userProfile.getTelegramConnectionStatus,
			action.payload,
		);
		yield put(getTelegramConnectionStatusSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error.response?.data ?? error), 'error');
		}
		yield put(userProfileInitState());
	}
}

function* telegramDoLoginRequestWorker(action: PayloadAction<TTelegramDoLoginRequest>) {
	const { payload } = action;
	try {
		yield call(api.userProfile.telegramDoLogin, payload);
		yield put(getTelegramConnectionStatusRequest({ sessionId: payload.sessionId }));
		yield put(telegramDoLoginSuccess());
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error.response?.data ?? error), 'error');
		}
		yield put(userProfileInitState());
	}
}

export function* userProfileSaga() {
	yield takeEvery(getConnectedTelegramRequest.type, getConnectedTelegramRequestWorker);
	yield takeEvery(getNewTelegramConnectionRequest.type, getNewTelegramConnectionRequestWorker);
	yield takeEvery(deleteTelegramConnectionRequest.type, deleteTelegramConnectionRequestWorker);
	yield takeEvery(
		getTelegramConnectionStatusRequest.type,
		getTelegramConnectionStatusRequestWorker,
	);
	yield takeEvery(telegramDoLoginRequest.type, telegramDoLoginRequestWorker);
}
