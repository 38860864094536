import { Page } from 'layouts/Page';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { getIsAuth } from 'redux/reducers/auth/selectors';
import { LoginPage } from 'pages/LoginPage';
import { ToAllMessageEditor } from 'components/ToAllMessageEditor';

export const ToAllMessageEditorPage: FC = () => {
	const ifAuth = useSelector(getIsAuth);

	return ifAuth ? (
		<Page title="To All">
			<ToAllMessageEditor />
		</Page>
	) : (
		<LoginPage />
	);
};
