import { TApi } from './types';
import { auth } from './auth';
import { groups } from './groups';
import { messages } from './message';
import { onboardingMessages } from './onboardingMessages';
import { users } from './users';
import { userProfile } from './userProfile';

const api: TApi = {
	auth,
	onboardingMessages,
	groups,
	messages,
	users,
	userProfile,
};

export { api };
