/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { Loader } from 'ui/Loader';
import {
	Button,
	Paper,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import { StyledTableCell, StyledTableRow } from 'ui/StyledTable';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
	getNPS,
	getNPSLoader,
	npsChapters,
	npsChaptersIsLoad,
} from 'redux/reducers/onboardingMessages/selectors';
import {
	getNPSChapters,
	getNPSRequest,
	sendNotificationRequest,
	startNewSurveyRequest,
} from 'redux/reducers/onboardingMessages/reducer';
import { escapeMarkdown } from 'components/OnboardingMessagesEditor/utils';
import { MarkdownToHtmlConverter } from 'components/OnboardingMessagesEditor/ConvertMarkdownToHtml/MarkdownToHtmlConverter';
import { TMonths, TNpsChaptersItem, TYears } from 'redux/reducers/onboardingMessages/types';
import { DateDialog } from './DateDialog';
// /import nps from './nps.json';

export const NPS: FC = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	// const npsIsLoad = false;
	const handlerEditClick = (index: number, id: number) => {
		navigate(`/nps/${id}/${index}`);
	};

	const nps = useSelector(getNPS);
	const npsIsLoad = useSelector(getNPSLoader);
	const chapters = useSelector(npsChapters);
	const chaptersIsLoad = useSelector(npsChaptersIsLoad);

	const [selectedChapters, setSelectedChapters] = useState<string[]>([]);

	useEffect(() => {
		dispatch(getNPSRequest());
		dispatch(getNPSChapters());
	}, [dispatch]);

	useEffect(() => {
		if (chapters) {
			setSelectedChapters(chapters?.map?.((chapter) => chapter.name) ?? []);
		}
	}, [chapters]);

	const [openStartNewDialog, setStartNewDialog] = useState(false);
	const handleOpenStartNewDialog = () => {
		setStartNewDialog(true);
	};
	const handleCloseStartNewDialog = () => {
		setStartNewDialog(false);
	};
	const [openSendNotificationsDialog, setSendNotificationsDialog] = useState(false);
	const handleOpenSendNotificationsDialog = () => {
		setSendNotificationsDialog(true);
	};
	const handleCloseSendNotificationsDialog = () => {
		setSendNotificationsDialog(false);
	};

	const handleStartNew = (month: TMonths, year: TYears) => {
		const chaptersTmp: number[] =
			chapters
				?.filter?.((chapter) => {
					if (selectedChapters.some((item) => item === chapter.name)) return chapter.id;
					return null;
				})
				?.map((i) => i.id) ?? [];
		dispatch(startNewSurveyRequest({ month, year, chapters: chaptersTmp }));
		handleCloseStartNewDialog();
	};

	const handleSendNotifications = (month: TMonths, year: TYears) => {
		const chaptersTmp: number[] =
			chapters
				?.filter?.((chapter) => {
					if (selectedChapters.some((item) => item === chapter.name)) return chapter.id;
					return null;
				})
				?.map((i) => i.id) ?? [];

		dispatch(sendNotificationRequest({ month, year, chapters: chaptersTmp }));
		handleCloseSendNotificationsDialog();
	};

	return (
		<>
			<h1 style={{ color: '#66cbff', marginBottom: '10px' }}>Обратная связь</h1>

			<div style={{ marginBottom: '20px' }}>
				<Button
					variant="contained"
					sx={{ color: 'white', backgroundColor: '#00507a', mr: '20px' }}
					onClick={handleOpenStartNewDialog}
				>
					Начать новый опрос
				</Button>

				<Button
					variant="contained"
					sx={{ color: 'white', backgroundColor: '#00507a' }}
					onClick={handleOpenSendNotificationsDialog}
				>
					Отправить уведомления
				</Button>
			</div>

			{!((nps?.length ?? 0) > 0) && !npsIsLoad && (
				<div className="empty-data">
					<p className="empty__text">Сообщения не найдены...</p>
				</div>
			)}
			{!!npsIsLoad && (
				<div
					style={{
						minHeight: '300px',
						minWidth: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						backgroundColor: '#016193',
					}}
				>
					<Loader />
				</div>
			)}
			{(nps?.length ?? 0) > 0 && !npsIsLoad && (
				<div className="table">
					<Paper>
						<TableContainer>
							<Table stickyHeader aria-label="sticky table">
								<TableHead>
									<TableRow>
										<StyledTableCell align="center" colSpan={1}>
											id
										</StyledTableCell>
										<StyledTableCell align="left" colSpan={4}>
											Сообщение
										</StyledTableCell>
									</TableRow>
								</TableHead>
								<TableBody style={{ background: '#00679eba' }}>
									{nps?.map((item, index) => (
										<StyledTableRow
											hover
											key={item.id}
											onClick={() => handlerEditClick(index, item.id)}
										>
											<StyledTableCell align="center" colSpan={1}>
												{item.id}
											</StyledTableCell>

											<StyledTableCell align="left" colSpan={3}>
												{/* {escapeMarkdown(item.text)} */}
												<MarkdownToHtmlConverter markdownText={item.text} />
											</StyledTableCell>
										</StyledTableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Paper>
				</div>
			)}
			<DateDialog
				open={openStartNewDialog}
				setClose={handleCloseStartNewDialog}
				handle={handleStartNew}
				text="Начать новый опрос?"
				chapters={chapters?.map?.((chapter) => chapter.name) ?? []}
				selectedChapters={selectedChapters}
				setSelectedChapters={setSelectedChapters}
			/>
			<DateDialog
				open={openSendNotificationsDialog}
				setClose={handleCloseSendNotificationsDialog}
				handle={handleSendNotifications}
				text="Отправить уведомление?"
				chapters={chapters?.map?.((chapter) => chapter.name) ?? []}
				selectedChapters={selectedChapters}
				setSelectedChapters={setSelectedChapters}
			/>
		</>
	);
};
