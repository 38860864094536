export const endpoint = {
	auth: {
		LOGIN: '/auth/token',
		TELEGRAM_LOGIN: '/auth/telegram',
		LOGOUT: '/auth/logout',
		PERMISSIONS: '/auth/permissions',
	},
	onboardingMessages: {
		GET_ONBOARDING_MESSAGES: '/onboardingmessages',
		ADD_ONBOARDING_MESSAGES: '/onboardingmessages',
		ONBOARDING_MESSAGES_TRYSEND: '/onboardingmessages/trysend',
		DELETE_ONBOARDING_MESSAGES: (messageId: number) => `/onboardingmessages/${messageId}`,
		ONBOARDING_MESSAGE_UP: (messageId: number) => `/onboardingmessages/${messageId}/up`,
		ONBOARDING_MESSAGE_DOWN: (messageId: number) => `/onboardingmessages/${messageId}/down`,
	},
	groups: {
		GET_GROUPS: '/group',
		GROUP_UPDATE: (groupeId: number) => `/group/${groupeId}/updateInfo`,
		USERS_UPDATE: (groupeId: number) => `/group/${groupeId}/updateUsers`,
		ALL_USERS_UPDATE: `/group/updateAllGroupUsersInfo`,
		ALL_GROUPS_UPDATE: `/group/updateAllGroupsInfo`,
		GROUP_CONTROL: (groupeId: number) => `/group/${groupeId}/control`,
		GET_GROUPS_FILTER: '/group/filter',
		GET_GROUP_FILTER_VALUES: '/group/filter_values',
		UPDATE_GROUP: `/group/update`,
	},
	message: {
		SEND_TO_ALL: '/messages/sendToAll',
		SEND_TO_USER: (userId: number) => `/messages/send/${userId}`,
		GET_NPS: '/nps/questions',
		SET_NPS: (npsQuestionId: number) => `/nps/questions/${npsQuestionId}`,
		GET_NPS_CHAPTERS: '/nps/chapters',
		START_NEW_SURVEY: '/nps/startNew',
		SEND_NOTIFICATION: '/nps/sendNotification',
		GET_MAILING_LIST: '/mailngList',
		DELETE_MAILING_LIST: (mailListId: number) => `/mailngList/${mailListId}`,
		POST_MAILING_LIST: '/mailngList',
		GET_MAILING_LIST_MESSAGE: (mailListId: number) => `/mailngList/${mailListId}`,
		GET_MAILING_LIST_HISTORY: (mailListId: number) => `/mailngList/${mailListId}/history`,
	},
	users: {
		GET_USERS: '/users_ui',
		GET_USER: (userId: number) => `/users_ui/${userId}`,
		GET_USER_RANDOM_COFFEE_PAIRS: (userId: number) => `/randomcoffee/${userId}/randomCoffeePairs`,
		POST_USER_RANDOM_COFFEE_PAIRS: (userId: number) => `/randomcoffee/${userId}/randomCoffeePairs`,
		GET_USER_RANDOM_COFFEE_PAIRS_FEATURE: (userId: number) =>
			`/randomcoffee/${userId}/randomCoffeePairs/feature`,
		DELETE_USER_RANDOM_COFFEE_PAIRS_FEATURE: (id: number) => `/randomcoffee/${id}`,
		CHANGE_RANDOM_COFFEE: (userId: number) => `/users_ui/${userId}/changeRandomCoffee`,
		GET_USER_GROUPS: (userId: number) => `/users_ui/${userId}/groups`,
		DELETE_USER_GROUP: (userId: number, groupId: number) => `/users_ui/${userId}/groups/${groupId}`,
		COMMUNITY_MANAGER_LIST: '/users_ui/community_manager_list',
		CHAPTER_LIST: '/users_ui/chapter_list',
	},
	userProfile: {
		CONNECTED_TELEGRAM: '/user_profile/connected_telegram',
		DELETE_TELEGRAM_CONNECTION: '/user_profile/delete_telegram_connection',
		NEW_TELEGRAM_CONNECTION: '/user_profile/new_telegram_connection',
		TELEGRAM_CONNECTION_STATUS: '/user_profile/telegram_connection_status',
		TELEGRAM_DO_LOGIN: '/user_profile/telegram_do_login',
	},
};
