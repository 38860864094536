import { createSelector } from '@reduxjs/toolkit';
import { TStoreState } from 'redux/types';
import {
	TUsersStore,
	TUsersData,
	TUserRandomCoffeePairsData,
	TUserData,
	TUserGroupsData,
	TCommunityManagerListResponsePayload,
	TChapterListResponsePayload,
} from './types';

// ==========================================:
const getUsersState = (state: TStoreState): TUsersStore => state.users;
export const getUsers = createSelector([getUsersState], (users: TUsersStore) => users);

// ====================================================:
export const getUsersIsLoad = createSelector(
	[getUsers],
	(users: TUsersStore): boolean => users.usersLoader,
);

// ====================================================:
export const getUsersList = createSelector(
	[getUsers],
	(users: TUsersStore): TUsersData => users.users,
);

// ====================================================:
export const getUserRandomCoffeePairsIsLoad = createSelector(
	[getUsers],
	(users: TUsersStore): boolean => users.userRandomCoffeePairsLoader,
);

// ====================================================:
export const getUserRandomCoffeePairsList = createSelector(
	[getUsers],
	(users: TUsersStore): TUserRandomCoffeePairsData => users.userRandomCoffeePairs,
);

// ====================================================:
export const getUser = createSelector([getUsers], (users: TUsersStore): TUserData => users.user);

// ====================================================:
export const getUserIsLoad = createSelector(
	[getUsers],
	(users: TUsersStore): boolean => users.userLoader,
);

// ====================================================:
export const getUserRandomCoffeePairsFeatureIsLoad = createSelector(
	[getUsers],
	(users: TUsersStore): boolean => users.userRandomCoffeePairsFeatureLoader,
);

// ====================================================:
export const getUserRandomCoffeePairsFeatureList = createSelector(
	[getUsers],
	(users: TUsersStore): TUserRandomCoffeePairsData => users.userRandomCoffeePairsFeature,
);

// ====================================================:

export const getUserGroupsIsLoad = createSelector(
	[getUsers],
	(users: TUsersStore): boolean => users.userGroupsLoader,
);

// ====================================================:
export const getUserGroupsList = createSelector(
	[getUsers],
	(users: TUsersStore): TUserGroupsData => users.userGroups,
);

// ====================================================:

export const getCommunityManagerListIsLoad = createSelector(
	[getUsers],
	(users: TUsersStore): boolean => users.communityManagerListLoader,
);

// ====================================================:

export const getCommunityManagerList = createSelector(
	[getUsers],
	(users: TUsersStore): TCommunityManagerListResponsePayload => users.communityManagerList,
);

// ====================================================:

export const getChapterListIsLoad = createSelector(
	[getUsers],
	(users: TUsersStore): boolean => users.chapterListLoader,
);

// ====================================================:

export const getChapterList = createSelector(
	[getUsers],
	(users: TUsersStore): TChapterListResponsePayload => users.chapterList,
);
