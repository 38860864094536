import { Chip, Switch, Table, TableBody, TableHead, TableRow } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReplyIcon from '@mui/icons-material/Reply';
import { history } from 'routes/history';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, getUserGroupsIsLoad, getUserGroupsList } from 'redux/reducers/users/selectors';
import {
	changeRandomCoffeeRequest,
	deleteUserGroupRequest,
	getUserGroupsRequest,
	getUserRequest,
} from 'redux/reducers/users/reducer';
import { StyledTableCell, StyledTableRow } from 'ui/StyledTable';
import DeleteIcon from '@mui/icons-material/Delete';
import { DeleteDialog } from 'ui/DeleteDialog';

export const UserGroups: FC = () => {
	const params = useParams<{ id: string }>();
	const { id } = params;
	const dispatch = useDispatch();

	const userData = useSelector(getUser);

	const userGroups = useSelector(getUserGroupsList);
	const userGroupsListIsLoad = useSelector(getUserGroupsIsLoad);

	const [checked, setChecked] = useState(userData?.isUseRandomCoffee);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!id) return;

		setChecked(event.target.checked);
		dispatch(changeRandomCoffeeRequest({ userId: +id, enabled: event.target.checked }));
	};

	useEffect(() => {
		if (!userData) return;
		setChecked(userData.isUseRandomCoffee);
	}, [userData]);

	const handleReplayHistory = () => {
		history.back();
	};

	useEffect(() => {
		if (!id) return;
		dispatch(getUserRequest({ userId: +id }));
		dispatch(getUserGroupsRequest({ userId: +id }));
	}, [dispatch, id]);

	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [groupId, setGroupId] = useState(0);

	const handleClickOpenDeleteDialog = () => {
		setOpenDeleteDialog(true);
	};

	const handleCloseDeleteDialog = () => {
		setOpenDeleteDialog(false);
	};

	const handleDeleteMessage = () => {
		if (!id) return;
		handleCloseDeleteDialog();
		dispatch(deleteUserGroupRequest({ userId: +id, groupId }));
	};

	return (
		<>
			<div>
				<Chip
					onClick={handleReplayHistory}
					icon={<ReplyIcon color="primary" />}
					label="Назад"
					sx={{ mb: '20px', my: 2, bgcolor: '#00507a', color: '#66cbff' }}
				/>
				<h1 style={{ color: '#66cbff', marginBottom: '10px' }}>Группы пользователя</h1>
				<div>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow>
								<StyledTableCell align="left" colSpan={2}>
									Имя в Telegram
								</StyledTableCell>
								<StyledTableCell align="left" colSpan={2}>
									Ник в Telegram
								</StyledTableCell>
								<StyledTableCell align="left" colSpan={2}>
									Telegram Id
								</StyledTableCell>
								<StyledTableCell align="left" colSpan={2}>
									Имя в МП
								</StyledTableCell>
								<StyledTableCell align="left" colSpan={2}>
									КМ
								</StyledTableCell>
								<StyledTableCell align="center" colSpan={2}>
									Роль
								</StyledTableCell>
								<StyledTableCell align="center" colSpan={2}>
									Номер телефона
								</StyledTableCell>
								<StyledTableCell align="center" colSpan={1}>
									Random Coffee
								</StyledTableCell>
								<StyledTableCell align="center" colSpan={1}>
									Статус
								</StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody style={{ background: '#00679eba' }}>
							<StyledTableRow>
								<StyledTableCell align="left" colSpan={2}>
									{userData?.telegramName}
								</StyledTableCell>
								<StyledTableCell align="left" colSpan={2}>
									{userData?.telegramUsername}
								</StyledTableCell>
								<StyledTableCell align="left" colSpan={2}>
									{userData?.telegramId}
								</StyledTableCell>
								<StyledTableCell align="left" colSpan={2}>
									{userData?.nameInMobileApp}
								</StyledTableCell>
								<StyledTableCell align="left" colSpan={2}>
									{userData?.communityManagerName}
								</StyledTableCell>
								<StyledTableCell align="center" colSpan={2}>
									{userData?.role}
								</StyledTableCell>
								<StyledTableCell align="center" colSpan={2}>
									{userData?.phoneNumber}
								</StyledTableCell>
								<StyledTableCell align="center" colSpan={1}>
									<Switch
										// checked={userData?.isUseRandomCoffee}
										checked={checked}
										onChange={handleChange}
										inputProps={{ 'aria-label': 'controlled' }}
									/>
									{/* {userData?.isUseRandomCoffee ? 'Да' : 'Нет'} */}
								</StyledTableCell>
								<StyledTableCell align="center" colSpan={1}>
									{userData?.isActive ? 'Активен' : 'Неактивен'}
								</StyledTableCell>
							</StyledTableRow>
						</TableBody>
					</Table>
				</div>
			</div>

			<div style={{ marginTop: '40px', color: '#fff' }}>
				{!((userGroups?.length ?? 0) > 0) && !userGroupsListIsLoad && (
					<div>У пользователя нет групп</div>
				)}
				{!!userGroupsListIsLoad && <div>Загрузка...</div>}
				{(userGroups?.length ?? 0) > 0 && (
					<div>
						<Table stickyHeader aria-label="sticky table">
							<TableHead>
								<TableRow>
									<StyledTableCell align="center" colSpan={1}>
										Id
									</StyledTableCell>
									<StyledTableCell align="left" colSpan={4}>
										Название группы
									</StyledTableCell>
									<StyledTableCell align="center" colSpan={1} />
								</TableRow>
							</TableHead>
							<TableBody style={{ background: '#00679eba' }}>
								{userGroups?.map?.((item) => (
									<StyledTableRow key={item.id}>
										<StyledTableCell align="center" colSpan={1}>
											{item.id}
										</StyledTableCell>
										<StyledTableCell align="left" colSpan={4}>
											{item.name}
										</StyledTableCell>
										<StyledTableCell align="center" colSpan={1}>
											<DeleteIcon
												sx={{ cursor: 'pointer' }}
												onClick={(e) => {
													e.stopPropagation();
													handleClickOpenDeleteDialog();
													// handleDeleteClient(item.id);
													setGroupId(item.id);
												}}
											/>
										</StyledTableCell>
									</StyledTableRow>
								))}
							</TableBody>
						</Table>
					</div>
				)}
			</div>
			<DeleteDialog
				open={openDeleteDialog}
				setClose={handleCloseDeleteDialog}
				handleDelete={handleDeleteMessage}
				text="Вы действительно хотите удалить пользователя из этой группы?"
			/>
		</>
	);
};
