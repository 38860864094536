export const LogoSvg = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlSpace="preserve"
			width="100%"
			height="100%"
			version="1.1"
			viewBox="0 0 3386.66 1269.98"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			style={{ height: '40px', width: '80px', color: 'white', marginRight: '20px' }}
			fill="#66cbff"
		>
			<g id="Слой_x0020_1">
				<metadata id="CorelCorpID_0Corel-Layer" />
				<path
					className="fil0"
					d="M1441.78 1061.64c-27.76,16.66 -77.73,33.31 -119.37,33.31 -66.63,0 -84.67,-33.31 -84.67,-97.16l0 -323.4 191.55 0 0 -152.68 -191.55 0 0 -241.52 -209.59 27.76 0 213.76 -108.26 0 0 152.68 108.26 0 0 352.55c0,165.18 79.12,234.58 230.41,234.58 91.61,0 176.28,-27.76 212.37,-51.36l-29.15 -148.52z"
				/>
				<polygon
					id="_1"
					className="fil0"
					points="1774.9,196.91 1565.31,196.91 1565.31,1244.86 1774.9,1244.86 "
				/>
				<path
					id="_2"
					className="fil0"
					d="M2521.65 761.83c0,-180.44 -97.16,-255.39 -290.09,-255.39 -197.1,0 -283.16,80.5 -305.36,216.53l177.66 34.7c13.88,-70.79 40.25,-99.94 109.65,-99.94 91.61,0 102.72,49.97 102.72,131.86l0 26.37c-186,-1.38 -415.02,31.93 -415.02,252.62 0,72.18 34.7,192.94 215.14,192.94 99.94,0 169.34,-33.32 215.15,-93 15.26,68.01 65.23,91.61 148.51,91.61 44.42,0 105.49,-13.88 133.25,-27.76l-5.55 -120.76c-18.04,5.55 -30.54,8.33 -43.03,8.33 -36.09,0 -43.03,-16.66 -43.03,-55.52l0 -302.59zm-204.03 303.98c-22.21,22.2 -70.79,47.19 -120.76,47.19 -66.63,0 -84.67,-37.48 -84.67,-79.12 0,-73.56 72.18,-92.99 172.11,-95.77l33.32 0 0 127.7z"
				/>
				<path
					id="_3"
					className="fil0"
					d="M2965.82 1261.52c179.05,0 319.24,-79.12 319.24,-242.91 0,-169.33 -134.64,-197.09 -254.01,-219.3 -90.22,-16.66 -148.51,-27.76 -148.51,-84.67 0,-40.25 29.14,-69.4 98.54,-69.4 76.35,0 106.88,43.03 123.54,105.49l167.95 -49.97c-34.7,-122.14 -124.92,-194.32 -288.71,-194.32 -162.4,0 -301.2,76.34 -301.2,234.57 0,176.28 145.74,202.65 260.95,224.86 81.89,15.27 138.8,30.54 138.8,86.06 0,47.19 -38.87,70.79 -109.65,70.79 -98.55,0 -127.7,-54.14 -144.36,-111.05l-169.33 58.3c23.59,120.76 122.14,191.55 306.75,191.55z"
				/>
				<path
					className="fil0"
					d="M2236.7 254.13l-96.92 0c-2.04,-13.65 -6.14,-25.93 -13.65,-36.85 -10.92,-15.02 -27.3,-21.84 -49.82,-21.84 -32.08,0 -53.92,15.69 -65.53,47.77 -6.14,16.38 -9.55,38.91 -9.55,66.89 0,26.62 3.41,48.46 9.55,64.16 10.92,30.72 32.76,45.73 64.16,45.73 21.84,0 37.54,-6.14 47.1,-18.43 9.55,-11.6 15.01,-27.3 17.06,-46.41l96.92 0c-2.05,28.67 -12.97,55.97 -31.4,81.9 -30.03,41.64 -73.71,62.8 -132.41,62.8 -58.01,0 -101.01,-17.75 -129,-52.56 -27.3,-34.12 -40.95,-79.17 -40.95,-134.46 0,-62.79 15.01,-111.25 45.73,-146.06 30.03,-34.81 72.35,-51.87 125.59,-51.87 45.73,0 82.58,10.23 111.93,30.71 28.67,20.48 46.42,56.65 51.19,108.52z"
				/>
				<path
					id="_1_0"
					className="fil0"
					d="M2630.65 273.24l0 213.64 -96.92 0 0 -221.14c0,-19.11 -3.42,-34.81 -10.24,-47.1 -8.87,-17.06 -25.26,-25.25 -49.14,-25.25 -25.26,0 -44.37,8.19 -57.34,25.25 -12.97,16.38 -19.11,40.27 -19.11,71.67l0 196.57 -94.87 0 0 -489.38 94.87 0 0 173.36c13.65,-21.15 29.35,-35.49 47.78,-44.36 17.74,-8.19 36.86,-12.29 56.65,-12.29 22.52,0 43,4.1 61.43,11.61 18.43,8.19 33.44,19.79 45.05,35.49 10.23,13.65 16.38,27.98 18.42,42.32 2.05,14.33 3.42,37.54 3.42,69.61z"
				/>
				<path
					id="_2_1"
					className="fil0"
					d="M2732.92 162.67c25.25,-31.39 68.25,-47.77 129,-47.77 40.27,0 75.08,8.19 106.47,23.88 30.72,15.7 46.41,45.05 46.41,89.42l0 166.54c0,11.6 0,25.25 0.69,41.63 0.68,12.29 2.05,21.16 5.46,25.26 2.73,4.77 7.51,8.19 13.65,10.92l0 14.33 -103.06 0c-2.74,-7.51 -4.78,-14.33 -6.15,-20.48 -0.68,-6.82 -2.05,-13.65 -2.73,-22.52 -12.97,14.33 -27.98,26.62 -45.05,36.86 -20.47,11.6 -43.68,17.74 -69.61,17.74 -32.77,0 -60.07,-9.55 -81.23,-28.66 -21.16,-18.43 -32.08,-45.05 -32.08,-79.86 0,-44.37 17.07,-76.45 51.19,-96.92 19.12,-10.92 47.1,-18.43 83.96,-23.21l32.08 -4.09c17.74,-2.05 30.03,-4.78 37.54,-8.19 13.65,-5.46 20.47,-15.02 20.47,-26.62 0,-15.02 -5.46,-25.26 -15.7,-31.4 -10.23,-5.46 -25.25,-8.19 -45.04,-8.19 -22.53,0 -38.91,5.46 -47.78,16.38 -6.83,8.19 -11.61,19.79 -13.65,33.45l-91.46 0c2.04,-31.4 10.92,-58.02 26.62,-78.5zm70.3 255.27c8.87,7.51 19.79,11.61 32.76,11.61 20.48,0 39.59,-6.15 56.65,-18.43 17.06,-11.61 25.94,-33.45 26.62,-65.53l0 -35.49c-5.46,4.1 -11.6,6.83 -17.75,9.56 -6.14,2.05 -14.33,4.09 -25.25,6.14l-21.16 4.1c-20.48,3.41 -34.81,8.19 -43,12.96 -15.02,8.88 -22.52,21.85 -22.52,40.27 0,16.38 4.77,27.99 13.65,34.81z"
				/>
				<path
					id="_3_2"
					className="fil0"
					d="M3265.41 419.31l0 70.98 -45.04 1.37c-45.05,2.05 -75.76,-6.15 -92.15,-23.21 -10.92,-10.92 -16.38,-27.3 -16.38,-50.51l0 -223.19 -50.5 0 0 -67.57 50.5 0 0 -101.7 94.19 0 0 101.7 59.38 0 0 67.57 -59.38 0 0 191.8c0,15.01 2.05,23.89 5.46,27.98 4.1,3.41 15.7,5.46 34.81,5.46 2.73,0 6.15,0 9.56,0 2.73,-0.68 6.14,-0.68 9.55,-0.68z"
				/>
				<path
					className="fil0"
					d="M812.5 611.56c0,-234.7 -126.38,-332.19 -377.33,-332.19 -256.37,0 -368.3,104.71 -397.19,281.64l231.09 45.13c18.06,-92.07 52.36,-129.98 142.63,-129.98 119.16,0 133.6,64.99 133.6,171.51l0 34.3c-241.93,-1.8 -539.82,41.53 -539.82,328.58 0,93.88 45.14,250.95 279.84,250.95 129.99,0 220.26,-43.32 279.84,-120.96 19.86,88.47 84.85,119.16 193.18,119.16 57.77,0 137.21,-18.05 173.31,-36.11l-7.22 -157.07c-23.47,7.22 -39.72,10.83 -55.97,10.83 -46.94,0 -55.96,-21.66 -55.96,-72.21l0 -393.58zm-265.4 395.38c-28.88,28.89 -92.07,61.39 -157.07,61.39 -86.66,0 -110.13,-48.75 -110.13,-102.91 0,-95.69 93.89,-120.96 223.87,-124.57l43.33 0 0 166.09z"
				/>
			</g>
		</svg>
	);
};
