import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import CloseIcon from '@mui/icons-material/Close';
import { FC } from 'react';
import { Box } from '@mui/material';
import { TSearchComponentProps } from './types';

export const SearchComponent: FC<TSearchComponentProps> = ({ onChange, search }) => {
	return (
		<Box
			component="form"
			color="#3683ac"
			sx={{
				p: '2px 4px',
				display: 'flex',
				alignItems: 'center',
				width: 600,
				height: '100%',
				backgroundColor: '#003a58',
				borderRadius: '5px',
			}}
		>
			<SearchIcon sx={{ color: '#3683ac' }} />{' '}
			<InputBase
				sx={{ ml: 1, flex: 1, color: 'primary.main' }}
				placeholder="Поиск"
				inputProps={{ 'aria-label': 'search' }}
				value={search}
				onChange={(e) => onChange(e.target.value)}
			/>
			{/* <IconButton sx={{ p: '10px' }} aria-label="search">
				<SearchIcon />
			</IconButton> */}
			<Divider sx={{ height: 28, m: 0.5, borderColor: '#3683ac' }} orientation="vertical" />
			<IconButton
				// color="primary"
				sx={{ p: '10px' }}
				aria-label="directions"
				onClick={() => onChange('')}
			>
				<CloseIcon sx={{ color: '#3683ac' }} />
			</IconButton>
		</Box>
	);
};
