/* eslint-disable react/no-array-index-key */
/* eslint-disable no-useless-escape */
import { FC } from 'react';
import { unEscapeMarkdown } from 'components/OnboardingMessagesEditor/utils';
import { TMarkdownToHtmlConverterProps } from './types';

export const MarkdownToHtmlConverter: FC<TMarkdownToHtmlConverterProps> = ({ markdownText }) => {
	const boldRegex = /(\*{1}[^*]+\*{1})/g;
	const underlineRegex = /(=={1}[^_]+=={1})/g;
	const italicRegex = /(_{1}[^_]+_{1})/g;
	const strikethroughRegex = /(~{1}[^~]+~{1})/g;
	const linkRegex = /(\[[^\]]+\]\([^\)]+\))/g;
	const spoilerRegex = /(\|{2}[^||]+\|{2})/g;
	const lineTranslation = /(\r\n|\n|\r)/gm;

	const escapedMarkdown = unEscapeMarkdown(markdownText).replace(/[<>&]/g, (tag: string) => {
		const tagsToReplace = (t: string) => {
			if (t === '<') return '&lt;';
			if (t === '>') return '&gt;';
			if (t === '&') return '&amp;';
			return t;
		};
		return tagsToReplace(tag);
	});

	const replaceUnderline = (text: string) => {
		return text.replace(/__{1}([^_]+)__{1}/g, '==$1==');
	};

	const replaceLineTranslation = (text: string) => {
		return text.replace(/(\r\n|\n|\r)/gm, '<br />');
	};

	const parts = replaceUnderline(escapedMarkdown)
		.split(boldRegex)
		.map((part) => part.split(underlineRegex))
		.flat()
		.map((part) => part.split(italicRegex))
		.flat()
		.map((part) => part.split(strikethroughRegex))
		.flat()
		.map((part) => part.split(linkRegex))
		.flat()
		.map((part) => part.split(spoilerRegex))
		.flat()
		.map((part) => part.split(lineTranslation))
		.flat();
	return (
		<div>
			{parts.map((part, index) => {
				if (part.match(boldRegex)) {
					return <b key={`parts${index}`}>{part.replace(/\*{1}/g, '')}</b>;
				}
				if (part.match(underlineRegex)) {
					return <u key={`parts${index}`}>{part.replace(/=={1}/g, '')}</u>;
				}
				if (part.match(italicRegex)) {
					return <i key={`parts${index}`}>{part.replace(/_{1}/g, '')}</i>;
				}
				if (part.match(strikethroughRegex)) {
					return <s key={`parts${index}`}>{part.replace(/~{1}/g, '')}</s>;
				}
				if (part.match(linkRegex)) {
					const link = part.match(/\([^\)]+\)/g);
					const text = part.match(/\[[^\]]+\]/g);
					return (
						<a key={`parts${index}`} href={link ? link[0].replace(/\(|\)/g, '') : ''}>
							{text ? text[0].replace(/\[|\]/g, '') : ''}
						</a>
					);
				}
				if (part.match(spoilerRegex)) {
					return (
						<span key={`parts${index}`} className="spoiler">
							{part.replace(/\|{2}/g, '')}
						</span>
					);
				}
				if (part.match(lineTranslation)) {
					return <br key={`parts${index}`} />;
				}

				return part;
			})}
		</div>
	);
};
