/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { notificationContainer } from 'utils/notificationContainer';
import {
	TChangeRandomCoffeeRequestPayload,
	TDeleteUserGroupRequestPayload,
	TDeleteUserRandomCoffeePairsRequestPayload,
	TSetUserRandomCoffeePairsRequestPayload,
	TUserGroupsRequestPayload,
	TUserGroupsResponsePayload,
	TUserRandomCoffeePairsFeatureRequestPayload,
	TUserRandomCoffeePairsFeatureResponsePayload,
	TUserRandomCoffeePairsRequestPayload,
	TUserRandomCoffeePairsResponsePayload,
	TUserRequestPayload,
	TUserResponsePayload,
	TUsersRequestPayload,
	TUsersResponsePayload,
	TCommunityManagerListResponsePayload,
} from './types';
import {
	assetsInitState,
	getUserRandomCoffeePairsRequest,
	getUserRandomCoffeePairsSuccess,
	getUserRequest,
	getUserSuccess,
	getUsersRequest,
	getUsersSuccess,
	setUserRandomCoffeePairsRequest,
	getUserRandomCoffeePairsFeatureRequest,
	getUserRandomCoffeePairsFeatureSuccess,
	deleteUserRandomCoffeePairsFeatureRequest,
	changeRandomCoffeeRequest,
	getUserGroupsRequest,
	getUserGroupsSuccess,
	deleteUserGroupRequest,
	getCommunityManagerListRequest,
	getCommunityManagerListSuccess,
	getChapterListRequest,
	getChapterListSuccess,
} from './reducer';

function* getUsersWorker(action: PayloadAction<TUsersRequestPayload>) {
	const { payload } = action;

	try {
		const response: TUsersResponsePayload = yield call(api.users.getUsers, payload);
		yield put(getUsersSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(assetsInitState());
	}
}

function* getUserWorker(action: PayloadAction<TUserRequestPayload>) {
	const { payload } = action;

	try {
		const response: TUserResponsePayload = yield call(api.users.getUser, payload);
		yield put(getUserSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(assetsInitState());
	}
}

function* getUserRandomCoffeePairsWorker(
	action: PayloadAction<TUserRandomCoffeePairsRequestPayload>,
) {
	const { payload } = action;

	try {
		const response: TUserRandomCoffeePairsResponsePayload = yield call(
			api.users.getUserRandomCoffeePairs,
			payload,
		);
		yield put(getUserRandomCoffeePairsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(assetsInitState());
	}
}
function* setUserRandomCoffeePairsWorker(
	action: PayloadAction<TSetUserRandomCoffeePairsRequestPayload>,
) {
	const { payload } = action;

	try {
		yield call(api.users.setUserRandomCoffeePairs, payload);
		notificationContainer('Пара образована', 'success');
		yield put(getUserRandomCoffeePairsFeatureRequest(payload));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(assetsInitState());
	}
}

function* getUserRandomCoffeePairsFeatureWorker(
	action: PayloadAction<TUserRandomCoffeePairsFeatureRequestPayload>,
) {
	const { payload } = action;

	try {
		const response: TUserRandomCoffeePairsFeatureResponsePayload = yield call(
			api.users.getUserRandomCoffeePairsFeature,
			payload,
		);
		yield put(getUserRandomCoffeePairsFeatureSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(assetsInitState());
	}
}

function* deleteUserRandomCoffeePairsFeatureWorker(
	action: PayloadAction<TDeleteUserRandomCoffeePairsRequestPayload>,
) {
	const { payload } = action;

	try {
		yield call(api.users.deleteUserRandomCoffeePairsFeature, payload);
		notificationContainer('Пара удалена', 'success');
		yield put(getUserRandomCoffeePairsFeatureRequest({ userId: payload.userId }));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(assetsInitState());
	}
}

function* changeRandomCoffeeWorker(action: PayloadAction<TChangeRandomCoffeeRequestPayload>) {
	const { payload } = action;

	try {
		yield call(api.users.changeRandomCoffee, payload);
		notificationContainer(
			`Random Coffee успешно ${payload.enabled ? 'включен' : 'выключен'}`,
			'success',
		);
		yield put(getUserRandomCoffeePairsFeatureRequest({ userId: payload.userId }));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(assetsInitState());
	}
}

function* getUserGroupsRequestWorker(action: PayloadAction<TUserGroupsRequestPayload>) {
	const { payload } = action;

	try {
		const response: TUserGroupsResponsePayload = yield call(api.users.getUserGroups, payload);
		yield put(getUserGroupsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(assetsInitState());
	}
}

function* deleteUserGroupWorker(action: PayloadAction<TDeleteUserGroupRequestPayload>) {
	const { payload } = action;

	try {
		yield call(api.users.deleteUserGroup, payload);
		yield put(getUserGroupsRequest({ userId: payload.userId }));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(assetsInitState());
	}
}

function* getCommunityManagerListWorker() {
	try {
		const response: TCommunityManagerListResponsePayload = yield call(
			api.users.getCommunityManagerList,
		);
		yield put(getCommunityManagerListSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(assetsInitState());
	}
}

export function* chapterListWorker() {
	try {
		const response: TCommunityManagerListResponsePayload = yield call(api.users.getChapterList);
		yield put(getChapterListSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(assetsInitState());
	}
}

export function* usersSaga() {
	yield takeEvery(getUsersRequest.type, getUsersWorker);
	yield takeEvery(getUserRequest.type, getUserWorker);
	yield takeEvery(getUserRandomCoffeePairsRequest.type, getUserRandomCoffeePairsWorker);
	yield takeEvery(setUserRandomCoffeePairsRequest.type, setUserRandomCoffeePairsWorker);
	yield takeEvery(
		getUserRandomCoffeePairsFeatureRequest.type,
		getUserRandomCoffeePairsFeatureWorker,
	);
	yield takeEvery(
		deleteUserRandomCoffeePairsFeatureRequest.type,
		deleteUserRandomCoffeePairsFeatureWorker,
	);
	yield takeEvery(changeRandomCoffeeRequest.type, changeRandomCoffeeWorker);
	yield takeEvery(getUserGroupsRequest.type, getUserGroupsRequestWorker);
	yield takeEvery(deleteUserGroupRequest.type, deleteUserGroupWorker);
	yield takeEvery(getCommunityManagerListRequest.type, getCommunityManagerListWorker);
	yield takeEvery(getChapterListRequest.type, chapterListWorker);
}
