/* eslint-disable react-hooks/exhaustive-deps */
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { FC, useEffect, useState } from 'react';
import { DialogContent, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
	getConnectedTelegramRequest,
	getNewTelegramConnectionRequest,
	getTelegramConnectionStatusRequest,
	telegramDoLoginRequest,
} from 'redux/reducers/userProfile/reducer';
import {
	getNewSessionId,
	getNewSessionIdIsLoad,
	getSessionId,
	getTelegramDoLoginIsLoad,
	getTelegramIsNeedAction,
	getTelegramNextAction,
	getTelegramConnectionStatusIsLoad,
} from 'redux/reducers/userProfile/selectors';
import { Loader } from 'ui/Loader';
import { TAddUserDialogProps } from './types';

export const AddUserDialog: FC<TAddUserDialogProps> = ({ open, setClose }) => {
	const dispatch = useDispatch();
	const sessionId = useSelector(getSessionId);
	const newSessionId = useSelector(getNewSessionId);
	const telegramIsNeedAction = useSelector(getTelegramIsNeedAction);
	const telegramNextAction = useSelector(getTelegramNextAction);
	const telegramStatusLoading = useSelector(getTelegramConnectionStatusIsLoad);
	const telegramDoLoginLoading = useSelector(getTelegramDoLoginIsLoad);
	const newSessionIdLoading = useSelector(getNewSessionIdIsLoad);

	const [phone, setPhone] = useState('');
	const handleAddUser = () => {
		if (!phone) return;
		dispatch(getNewTelegramConnectionRequest({ phoneNumber: phone }));
		setPhone('');
		// setClose();
	};

	const [verificationCode, setVerificationCode] = useState('');

	const handleAddVerificationsCode = () => {
		if (!verificationCode || !newSessionId) return;
		dispatch(telegramDoLoginRequest({ sessionId: newSessionId, loginData: verificationCode }));
		setVerificationCode('');
		// dispatch(getTelegramConnectionStatusRequest({ sessionId: newSessionId }));
		// setClose();
	};

	useEffect(() => {
		if (!telegramIsNeedAction) {
			dispatch(getConnectedTelegramRequest());
			setClose();
		}
	}, [dispatch, telegramIsNeedAction]);

	const text = 'Введите телефон пользователя';
	return (
		<Dialog
			open={open}
			// onClose={setClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			sx={{ minWidth: '500px', minHeight: '300px' }}
		>
			{(telegramStatusLoading || telegramDoLoginLoading || newSessionIdLoading) && (
				<div
					style={{
						height: '200px',
						width: '300px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						backgroundColor: '#016193',
					}}
				>
					<Loader />
				</div>
			)}
			{!telegramStatusLoading &&
				!telegramDoLoginLoading &&
				!newSessionIdLoading &&
				!(telegramNextAction === 'verification_code') &&
				!(telegramNextAction === 'password') && (
					<>
						<DialogTitle id="alert-dialog-title">Введите телефон пользователя</DialogTitle>
						<DialogContent>
							<TextField
								autoFocus
								margin="dense"
								id="name"
								label="Телефон"
								type="text"
								fullWidth
								variant="outlined"
								value={phone}
								onChange={(e) => setPhone(e.target.value.replaceAll(/\D/g, ''))}
							/>
						</DialogContent>
						<DialogActions>
							<Button onClick={setClose}>Отмена</Button>
							<Button onClick={handleAddUser} autoFocus>
								Добавить Пользователя
							</Button>{' '}
						</DialogActions>
					</>
				)}
			{!telegramStatusLoading &&
				!telegramDoLoginLoading &&
				!newSessionIdLoading &&
				(telegramNextAction === 'verification_code' || telegramNextAction === 'password') && (
					<>
						<DialogTitle id="alert-dialog-title">
							{telegramNextAction === 'verification_code'
								? 'Введите код верификации'
								: 'Введите пароль'}
						</DialogTitle>
						<DialogContent>
							<TextField
								autoFocus
								margin="dense"
								id="name"
								label={telegramNextAction === 'verification_code' ? 'Код верификации' : 'Пароль'}
								type="text"
								fullWidth
								variant="outlined"
								value={verificationCode}
								onChange={(e) => setVerificationCode(e.target.value.replaceAll(' ', ''))}
							/>
						</DialogContent>
						<DialogActions>
							<Button onClick={setClose}>Отмена</Button>
							<Button onClick={handleAddVerificationsCode} autoFocus>
								Добавить Пользователя
							</Button>{' '}
						</DialogActions>
					</>
				)}
		</Dialog>
	);
};
