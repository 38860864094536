/* eslint-disable no-nested-ternary */
import { Button, Chip, Switch, Table, TableBody, TableHead, TableRow } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ReplyIcon from '@mui/icons-material/Reply';
import { history } from 'routes/history';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, getUserGroupsIsLoad, getUserGroupsList } from 'redux/reducers/users/selectors';
import {
	changeRandomCoffeeRequest,
	getUserGroupsRequest,
	getUserRequest,
} from 'redux/reducers/users/reducer';
import { StyledTableCell, StyledTableRow } from 'ui/StyledTable';

export const User: FC = () => {
	const params = useParams<{ id: string }>();
	const { id } = params;
	const navigator = useNavigate();
	const dispatch = useDispatch();

	const userData = useSelector(getUser);

	const userGroups = useSelector(getUserGroupsList);
	const userGroupsListIsLoad = useSelector(getUserGroupsIsLoad);

	const [checked, setChecked] = useState(userData?.isUseRandomCoffee);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!id) return;

		setChecked(event.target.checked);
		dispatch(changeRandomCoffeeRequest({ userId: +id, enabled: event.target.checked }));
	};

	useEffect(() => {
		if (!userData) return;
		setChecked(userData.isUseRandomCoffee);
	}, [userData]);

	const handleRandomCoffee = () => {
		if (!id) return;
		navigator(`/users/${id}/randomCoffeePairs`);
	};
	const handleRandomCoffeeFeature = () => {
		if (!id) return;
		navigator(`/users/${id}/randomCoffeePairsFeature`);
	};

	const handleReplayHistory = () => {
		history.back();
	};

	useEffect(() => {
		if (!id) return;
		dispatch(getUserRequest({ userId: +id }));
		dispatch(getUserGroupsRequest({ userId: +id }));
	}, [dispatch, id]);

	const handleToRedactor = () => {
		if (!id) return;
		navigator({
			pathname: `/message-to-user/${id}`,
		});
	};

	const handleGroups = () => {
		if (!id) return;

		navigator({
			pathname: `/users/${id}/groups`,
		});
	};

	return (
		<>
			<div>
				<Chip
					onClick={handleReplayHistory}
					icon={<ReplyIcon color="primary" />}
					label="Назад"
					sx={{ mb: '20px', my: 2, bgcolor: '#00507a', color: '#66cbff' }}
				/>
				<h1 style={{ color: '#66cbff', marginBottom: '10px' }}>Профиль пользователя</h1>
				<div>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow>
								<StyledTableCell align="left" colSpan={2}>
									Имя в Telegram
								</StyledTableCell>
								<StyledTableCell align="left" colSpan={2}>
									Ник в Telegram
								</StyledTableCell>
								<StyledTableCell align="left" colSpan={2}>
									Telegram Id
								</StyledTableCell>
								<StyledTableCell align="left" colSpan={2}>
									Имя в МП
								</StyledTableCell>
								<StyledTableCell align="left" colSpan={2}>
									КМ
								</StyledTableCell>
								<StyledTableCell align="center" colSpan={2}>
									Роль
								</StyledTableCell>
								<StyledTableCell align="center" colSpan={2}>
									Номер телефона
								</StyledTableCell>
								<StyledTableCell align="center" colSpan={1}>
									Random Coffee
								</StyledTableCell>
								<StyledTableCell align="center" colSpan={1}>
									Гостевой месяц
								</StyledTableCell>

								<StyledTableCell align="center" colSpan={1}>
									Статус
								</StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody style={{ background: '#00679eba' }}>
							<StyledTableRow>
								<StyledTableCell align="left" colSpan={2}>
									{userData?.telegramName}
								</StyledTableCell>
								<StyledTableCell align="left" colSpan={2}>
									{userData?.telegramUsername}
								</StyledTableCell>
								<StyledTableCell align="left" colSpan={2}>
									{userData?.telegramId}
								</StyledTableCell>
								<StyledTableCell align="left" colSpan={2}>
									{userData?.nameInMobileApp}
								</StyledTableCell>
								<StyledTableCell align="left" colSpan={2}>
									{userData?.communityManagerName}
								</StyledTableCell>
								<StyledTableCell align="center" colSpan={2}>
									{userData?.role}
								</StyledTableCell>
								<StyledTableCell align="center" colSpan={2}>
									{userData?.phoneNumber}
								</StyledTableCell>
								<StyledTableCell align="center" colSpan={1}>
									<Switch
										// checked={userData?.isUseRandomCoffee}
										checked={checked}
										onChange={handleChange}
										inputProps={{ 'aria-label': 'controlled' }}
									/>
									{/* {userData?.isUseRandomCoffee ? 'Да' : 'Нет'} */}
								</StyledTableCell>
								<StyledTableCell align="center" colSpan={1}>
									{userData?.isGuestMonth === null ? '—' : userData?.isGuestMonth ? 'Да' : 'Нет'}
								</StyledTableCell>

								<StyledTableCell align="center" colSpan={1}>
									{userData?.isActive ? 'Активен' : 'Неактивен'}
								</StyledTableCell>
							</StyledTableRow>
						</TableBody>
					</Table>
				</div>
			</div>

			{/* <div style={{ display: 'flex', justifyContent: 'space-between' }}> */}
			<Button
				variant="contained"
				onClick={handleRandomCoffee}
				sx={{ my: 2, color: 'white', display: 'block', backgroundColor: '#00507a' }}
			>
				Состоявшиеся Знакомства Random Coffee
			</Button>
			<Button
				variant="contained"
				onClick={handleRandomCoffeeFeature}
				sx={{ my: 2, color: 'white', display: 'block', backgroundColor: '#00507a' }}
			>
				Запланированные Знакомства Random Coffee
			</Button>
			<Button
				variant="contained"
				onClick={handleToRedactor}
				sx={{ my: 2, color: 'white', display: 'block', backgroundColor: '#00507a' }}
			>
				Отправить сообщение пользователю
			</Button>
			<Button
				variant="contained"
				onClick={handleGroups}
				sx={{ my: 2, color: 'white', display: 'block', backgroundColor: '#00507a' }}
			>
				Показать Группы пользователя
			</Button>
			{/* </div> */}
		</>
	);
};
