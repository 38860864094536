import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { FC } from 'react';
import { TTrySendDialogProps } from './types';

export const TrySendDialog: FC<TTrySendDialogProps> = ({ open, setClose, text }) => {
	return (
		<Dialog
			open={open}
			onClose={setClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{text}</DialogTitle>
			<DialogActions>
				<Button onClick={setClose}>Закрыть</Button>
			</DialogActions>
		</Dialog>
	);
};
