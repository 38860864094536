import { get } from 'http';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	TOnboardingMessagesStore,
	TOnboardingMessagesResponsePayload,
	TOnboardingMessagesRequestPayload,
	TPostOnboardingMessagesRequestPayload,
	TPostOnboardingMessagesResponsePayload,
	TOnboardingMessagesUpRequestPayload,
	TOnboardingMessagesTrySendRequestPayload,
	TDeleteOnboardingMessagesRequestPayload,
	TOnboardingMessagesTrySendResponsePayload,
	TSendToAllRequestPayload,
	TGetNPSResponsePayload,
	TSetNPSRequestPayload,
	TStartNewSurveyRequestPayload,
	TSendToUserRequestPayload,
	TNPSChaptersResponsePayload,
	TMailingListRequestPayload,
	TMailingListResponsePayload,
	TPostMailingListRequestPayload,
	TDeleteMailingListRequestPayload,
	TMailingListMessageRequestPayload,
	TMailingListMessageResponsePayload,
	TMailingListHistoryRequestPayload,
	TMailingListHistoryResponsePayload,
} from './types';
// ==========================================:
export const initialState: TOnboardingMessagesStore = {
	onboardingMessages: null,
	onboardingMessagesLoader: false,
	postOnboardingMessages: null,
	postOnboardingMessagesLoader: false,
	onboardingMessagesTrySendLoader: false,
	onboardingMessagesTrySend: null,
	sendToAllLoader: false,
	getNPS: null,
	getNPSLoader: false,
	npsChapters: null,
	npsChaptersLoader: false,
	mailingList: null,
	mailingListLoader: false,
	mailingListMessage: null,
	mailingListMessageLoader: false,
	mailingListHistory: null,
	mailingListHistoryLoader: false,
};

// ==========================================:
const OnboardingMessages = createSlice({
	name: '@@onboardingMessages',
	initialState,
	reducers: {
		getOnboardingMessagesRequest: (
			state: TOnboardingMessagesStore,
			action: PayloadAction<TOnboardingMessagesRequestPayload>,
		) => {
			const onboardingMessagesRequestState = state;
			onboardingMessagesRequestState.onboardingMessagesLoader = true;
		},
		getOnboardingMessagesSuccess: (
			state: TOnboardingMessagesStore,
			action: PayloadAction<TOnboardingMessagesResponsePayload>,
		) => {
			const { payload } = action;
			const OnboardingMessagesSuccessState = state;
			OnboardingMessagesSuccessState.onboardingMessages = payload;
			OnboardingMessagesSuccessState.onboardingMessagesLoader = false;
		},
		postOnboardingMessagesRequest: (
			state: TOnboardingMessagesStore,
			action: PayloadAction<TPostOnboardingMessagesRequestPayload>,
		) => {
			const postOnboardingMessagesRequestState = state;
			postOnboardingMessagesRequestState.postOnboardingMessagesLoader = true;
		},
		postOnboardingMessagesSuccess: (
			state: TOnboardingMessagesStore,
			action: PayloadAction<TPostOnboardingMessagesResponsePayload>,
		) => {
			const { payload } = action;
			const postOnboardingMessagesSuccessState = state;
			postOnboardingMessagesSuccessState.postOnboardingMessages = payload;
			postOnboardingMessagesSuccessState.postOnboardingMessagesLoader = false;
		},
		onboardingMessagesTrySendRequest: (
			state: TOnboardingMessagesStore,
			action: PayloadAction<TOnboardingMessagesTrySendRequestPayload>,
		) => {
			const onboardingMessagesTrySendRequestState = state;
			onboardingMessagesTrySendRequestState.onboardingMessagesTrySendLoader = true;
		},

		onboardingMessagesTrySendSuccess: (
			state: TOnboardingMessagesStore,
			action: PayloadAction<TOnboardingMessagesTrySendResponsePayload>,
		) => {
			const { payload } = action;
			const OnboardingMessagesTrySendSuccessState = state;
			OnboardingMessagesTrySendSuccessState.onboardingMessagesTrySend = payload;
			OnboardingMessagesTrySendSuccessState.onboardingMessagesTrySendLoader = false;
		},

		onboardingMessagesDeleteRequest: (
			state: TOnboardingMessagesStore,
			action: PayloadAction<TDeleteOnboardingMessagesRequestPayload>,
		) => {},

		onboardingMessagesUpRequest: (
			state: TOnboardingMessagesStore,
			action: PayloadAction<TOnboardingMessagesUpRequestPayload>,
		) => {},
		onboardingMessagesDownRequest: (
			state: TOnboardingMessagesStore,
			action: PayloadAction<TOnboardingMessagesUpRequestPayload>,
		) => {},
		sendToAllRequest: (
			state: TOnboardingMessagesStore,
			action: PayloadAction<TSendToAllRequestPayload>,
		) => {
			const sendToAllRequestState = state;
			sendToAllRequestState.sendToAllLoader = true;
		},
		sendToAllSuccess: (state: TOnboardingMessagesStore) => {
			const sendToAllSuccessState = state;
			sendToAllSuccessState.sendToAllLoader = false;
		},
		sendToUserRequest: (
			state: TOnboardingMessagesStore,
			action: PayloadAction<TSendToUserRequestPayload>,
		) => {},

		getNPSRequest: (state: TOnboardingMessagesStore) => {
			const getNPSRequestState = state;
			getNPSRequestState.getNPSLoader = true;
		},
		getNPSSuccess: (
			state: TOnboardingMessagesStore,
			action: PayloadAction<TGetNPSResponsePayload>,
		) => {
			const { payload } = action;
			const getNPSSuccessState = state;
			getNPSSuccessState.getNPS = payload;
			getNPSSuccessState.getNPSLoader = false;
		},
		setNPSRequest: (
			state: TOnboardingMessagesStore,
			action: PayloadAction<TSetNPSRequestPayload>,
		) => {},
		getNPSChapters: (state: TOnboardingMessagesStore) => {
			const getNPSChaptersState = state;
			getNPSChaptersState.getNPSLoader = true;
		},
		getNPSChaptersSuccess: (
			state: TOnboardingMessagesStore,
			action: PayloadAction<TNPSChaptersResponsePayload>,
		) => {
			const { payload } = action;
			const getNPSChaptersSuccessState = state;
			getNPSChaptersSuccessState.npsChapters = payload;
			getNPSChaptersSuccessState.npsChaptersLoader = false;
		},
		startNewSurveyRequest: (
			state: TOnboardingMessagesStore,
			action: PayloadAction<TStartNewSurveyRequestPayload>,
		) => {},
		sendNotificationRequest: (
			state: TOnboardingMessagesStore,
			action: PayloadAction<TStartNewSurveyRequestPayload>,
		) => {},
		getMailingListRequest: (
			state: TOnboardingMessagesStore,
			action: PayloadAction<TMailingListRequestPayload>,
		) => {
			const getMailingListRequestState = state;
			getMailingListRequestState.mailingListLoader = true;
		},
		getMailingListSuccess: (
			state: TOnboardingMessagesStore,
			action: PayloadAction<TMailingListResponsePayload>,
		) => {
			const { payload } = action;
			const getMailingListSuccessState = state;
			getMailingListSuccessState.mailingList = payload;
			getMailingListSuccessState.mailingListLoader = false;
		},
		postMailingListRequest: (
			state: TOnboardingMessagesStore,
			action: PayloadAction<TPostMailingListRequestPayload>,
		) => {},
		deleteMailingListRequest: (
			state: TOnboardingMessagesStore,
			action: PayloadAction<TDeleteMailingListRequestPayload>,
		) => {},
		getMailingListMessageRequest: (
			state: TOnboardingMessagesStore,
			action: PayloadAction<TMailingListMessageRequestPayload>,
		) => {
			const getMailingListMessageRequestState = state;
			getMailingListMessageRequestState.mailingListMessageLoader = true;
		},
		getMailingListMessageSuccess: (
			state: TOnboardingMessagesStore,
			action: PayloadAction<TMailingListMessageResponsePayload>,
		) => {
			const { payload } = action;
			const getMailingListMessageSuccessState = state;
			getMailingListMessageSuccessState.mailingListMessage = payload;
			getMailingListMessageSuccessState.mailingListMessageLoader = false;
		},
		getMailingListHistoryRequest: (
			state: TOnboardingMessagesStore,
			action: PayloadAction<TMailingListHistoryRequestPayload>,
		) => {
			const getMailingListHistoryRequestState = state;
			getMailingListHistoryRequestState.mailingListHistoryLoader = true;
		},
		getMailingListHistorySuccess: (
			state: TOnboardingMessagesStore,
			action: PayloadAction<TMailingListHistoryResponsePayload>,
		) => {
			const { payload } = action;
			const getMailingListHistorySuccessState = state;
			getMailingListHistorySuccessState.mailingListHistory = payload;
			getMailingListHistorySuccessState.mailingListHistoryLoader = false;
		},

		assetsInitState: () => initialState,
	},
});

export default OnboardingMessages.reducer;
export const {
	getOnboardingMessagesRequest,
	getOnboardingMessagesSuccess,
	postOnboardingMessagesRequest,
	postOnboardingMessagesSuccess,
	onboardingMessagesTrySendRequest,
	onboardingMessagesTrySendSuccess,
	onboardingMessagesUpRequest,
	onboardingMessagesDownRequest,
	onboardingMessagesDeleteRequest,
	sendToAllRequest,
	sendToAllSuccess,
	sendToUserRequest,
	getNPSRequest,
	getNPSSuccess,
	setNPSRequest,
	getNPSChapters,
	getNPSChaptersSuccess,
	startNewSurveyRequest,
	sendNotificationRequest,
	assetsInitState,
	getMailingListRequest,
	getMailingListSuccess,
	postMailingListRequest,
	deleteMailingListRequest,
	getMailingListMessageRequest,
	getMailingListMessageSuccess,
	getMailingListHistoryRequest,
	getMailingListHistorySuccess,
} = OnboardingMessages.actions;
