import { createSelector } from '@reduxjs/toolkit';
import { TStoreState } from 'redux/types';
import {
	IAuthStore,
	IUserLoginData,
	TAuthPermissionsResponsePayload,
} from 'redux/reducers/auth/types';
import { TUserData } from '../users/types';

// ==========================================:
const getAuthState = (state: TStoreState): IAuthStore => state.auth;
export const getAuth = createSelector([getAuthState], (auth: IAuthStore) => auth);

// ====================================================:
export const getIsAuth = createSelector([getAuth], (auth: IAuthStore): boolean => {
	return !!(auth.isAuthenticated && auth.accessToken);
});

// ====================================================:
export const getAuthIsAuthenticated = createSelector(
	[getAuth],
	(auth: IAuthStore): boolean => !!(auth.isAuthenticated && auth.accessToken),
);

// ====================================================:
export const getAuthIsNotAuthenticated = createSelector(
	[getAuth],
	(auth: IAuthStore): boolean => !auth.isAuthenticated && !auth.accessToken,
);
export const getSocketToken = createSelector(
	[getAuth],
	(auth: IAuthStore): string | null => auth.socketToken,
);
// ====================================================:
export const getAdminEmail = createSelector(
	[getAuth],
	(auth: IAuthStore): null | string => auth.email,
);

// ====================================================:
export const getIsLoad = createSelector([getAuth], (auth: IAuthStore): boolean => auth.loginLoader);

// ====================================================:
export const getPermission = createSelector(
	[getAuth],
	(auth: IAuthStore): TAuthPermissionsResponsePayload => auth.permissions,
);

// ====================================================:

export const getPermissionLoader = createSelector(
	[getAuth],
	(auth: IAuthStore): boolean => auth.permissionLoader,
);

// ====================================================:

export const getUserLoginData = createSelector(
	[getAuth],
	(auth: IAuthStore): null | IUserLoginData => auth.userLoginData,
);
