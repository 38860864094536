/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services/api';
import { PayloadAction } from '@reduxjs/toolkit';
import { notificationContainer } from 'utils/notificationContainer';
import {
	TDeleteMailingListRequestPayload,
	TDeleteOnboardingMessagesRequestPayload,
	TGetNPSResponsePayload,
	TMailingListHistoryRequestPayload,
	TMailingListHistoryResponsePayload,
	TMailingListMessageRequestPayload,
	TMailingListMessageResponsePayload,
	TMailingListRequestPayload,
	TMailingListResponsePayload,
	TNPSChaptersResponsePayload,
	TOnboardingMessagesRequestPayload,
	TOnboardingMessagesResponsePayload,
	TOnboardingMessagesTrySendRequestPayload,
	TOnboardingMessagesTrySendResponsePayload,
	TOnboardingMessagesUpRequestPayload,
	TPostMailingListRequestPayload,
	TPostMailingListResponsePayload,
	TPostOnboardingMessagesRequestPayload,
	TPostOnboardingMessagesResponsePayload,
	TSendToAllRequestPayload,
	TSendToUserRequestPayload,
	TSetNPSRequestPayload,
	TStartNewSurveyRequestPayload,
} from './types';
import {
	assetsInitState,
	deleteMailingListRequest,
	getMailingListHistoryRequest,
	getMailingListHistorySuccess,
	getMailingListMessageRequest,
	getMailingListMessageSuccess,
	getMailingListRequest,
	getMailingListSuccess,
	getNPSChapters,
	getNPSChaptersSuccess,
	getNPSRequest,
	getNPSSuccess,
	getOnboardingMessagesRequest,
	getOnboardingMessagesSuccess,
	onboardingMessagesDeleteRequest,
	onboardingMessagesDownRequest,
	onboardingMessagesTrySendRequest,
	onboardingMessagesTrySendSuccess,
	onboardingMessagesUpRequest,
	postMailingListRequest,
	postOnboardingMessagesRequest,
	postOnboardingMessagesSuccess,
	sendNotificationRequest,
	sendToAllRequest,
	sendToAllSuccess,
	sendToUserRequest,
	setNPSRequest,
	startNewSurveyRequest,
} from './reducer';

function* getOnboardingMessagesWorker(action: PayloadAction<TOnboardingMessagesRequestPayload>) {
	const { payload } = action;

	try {
		const response: TOnboardingMessagesResponsePayload = yield call(
			api.onboardingMessages.getOnboardingMessages,
			payload,
		);
		yield put(getOnboardingMessagesSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(assetsInitState());
	}
}

function* postOnboardingMessagesWorker(
	action: PayloadAction<TPostOnboardingMessagesRequestPayload>,
) {
	const { payload } = action;
	try {
		const response: TPostOnboardingMessagesResponsePayload = yield call(
			api.onboardingMessages.postOnboardingMessages,
			payload,
		);
		yield put(postOnboardingMessagesSuccess(response));
		notificationContainer('сообщение успешно сохранено!', 'success');

		yield put(getOnboardingMessagesRequest({}));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(assetsInitState());
	}
}

function* deleteOnboardingMessagesWorker(
	action: PayloadAction<TDeleteOnboardingMessagesRequestPayload>,
) {
	const { payload } = action;
	try {
		const response: TPostOnboardingMessagesResponsePayload = yield call(
			api.onboardingMessages.deleteOnboardingMessages,
			payload,
		);
		yield put(postOnboardingMessagesSuccess(response));
		notificationContainer('сообщение успешно удалено!', 'success');

		yield put(getOnboardingMessagesRequest({}));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(assetsInitState());
	}
}

function* onboardingMessagesTrySendWorker(
	action: PayloadAction<TOnboardingMessagesTrySendRequestPayload>,
) {
	const { payload } = action;
	try {
		const response: TOnboardingMessagesTrySendResponsePayload = yield call(
			api.onboardingMessages.onboardingMessagesTrySend,
			payload,
		);
		yield put(onboardingMessagesTrySendSuccess(response));
		// notificationContainer('сообщение отправлено успешно!', 'success');

		yield put(getOnboardingMessagesRequest({}));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(assetsInitState());
	}
}

function* onboardingMessagesUpWorker(action: PayloadAction<TOnboardingMessagesUpRequestPayload>) {
	const { payload } = action;

	try {
		yield call(api.onboardingMessages.onboardingMessagesUp, payload);
		yield put(getOnboardingMessagesRequest({}));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(assetsInitState());
	}
}

function* onboardingMessagesDownWorker(action: PayloadAction<TOnboardingMessagesUpRequestPayload>) {
	const { payload } = action;

	try {
		yield call(api.onboardingMessages.onboardingMessagesDown, payload);
		yield put(getOnboardingMessagesRequest({}));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(assetsInitState());
	}
}

function* sendToAllWorker(action: PayloadAction<TSendToAllRequestPayload>) {
	const { payload } = action;
	try {
		yield call(api.messages.sendToAll, payload);
		yield put(sendToAllSuccess());
		notificationContainer('сообщение успешно сохранено!', 'success');

		yield put(getOnboardingMessagesRequest({}));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(assetsInitState());
	}
}

function* getNPSRequestWorker() {
	try {
		const response: TGetNPSResponsePayload = yield call(api.messages.getNPS);
		yield put(getNPSSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(assetsInitState());
	}
}

function* setNPSRequestWorker(action: PayloadAction<TSetNPSRequestPayload>) {
	const { payload } = action;
	try {
		yield call(api.messages.setNPS, payload);
		notificationContainer('сообщение nps успешно сохранено!', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
		yield put(assetsInitState());
	}
}

function* startNewSurveyWorker(action: PayloadAction<TStartNewSurveyRequestPayload>) {
	const { payload } = action;
	try {
		yield call(api.messages.startNewSurvey, payload);
		notificationContainer('опрос успешно начат!', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
	}
}

function* sendNotificationWorker(action: PayloadAction<TStartNewSurveyRequestPayload>) {
	const { payload } = action;
	try {
		yield call(api.messages.sendNotification, payload);
		notificationContainer('уведомление успешно отправлено!', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
	}
}

function* sendToUserWorker(action: PayloadAction<TSendToUserRequestPayload>) {
	const { payload } = action;
	try {
		yield call(api.messages.sendToUser, payload);
		notificationContainer('сообщение успешно отправлено!', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			notificationContainer(String(error), 'error');
		}
	}
}

function* getNPSChaptersWorker() {
	try {
		const response: TNPSChaptersResponsePayload = yield call(api.messages.getNpsChapters);
		yield put(getNPSChaptersSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (axios.isAxiosError(error)) {
				notificationContainer(String(error), 'error');
			}
		}
	}
}

function* getMailingListRequestWorker(action: PayloadAction<TMailingListRequestPayload>) {
	const { payload } = action;

	try {
		const response: TMailingListResponsePayload = yield call(api.messages.getMailingList, payload);
		yield put(getMailingListSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (axios.isAxiosError(error)) {
				notificationContainer(String(error), 'error');
			}
		}
	}
}

function* getMailingListMessageRequestWorker(
	action: PayloadAction<TMailingListMessageRequestPayload>,
) {
	const { payload } = action;

	try {
		const response: TMailingListMessageResponsePayload = yield call(
			api.messages.getMailingListMessage,
			payload,
		);
		yield put(getMailingListMessageSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (axios.isAxiosError(error)) {
				notificationContainer(String(error), 'error');
			}
		}
	}
}

function* getMailingListHistoryRequestWorker(
	action: PayloadAction<TMailingListHistoryRequestPayload>,
) {
	const { payload } = action;

	try {
		const response: TMailingListHistoryResponsePayload = yield call(
			api.messages.getMailingListHistory,
			payload,
		);
		yield put(getMailingListHistorySuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (axios.isAxiosError(error)) {
				notificationContainer(String(error), 'error');
			}
		}
	}
}

function* postMailingListRequestWorker(action: PayloadAction<TPostMailingListRequestPayload>) {
	const { payload } = action;

	try {
		const response: TPostMailingListResponsePayload = yield call(
			api.messages.postMailingList,
			payload,
		);
		// yield put(postMailingListMessagesSuccess(response));
		notificationContainer('сообщение успешно сохранено!', 'success');

		yield put(getMailingListRequest({}));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (axios.isAxiosError(error)) {
				notificationContainer(String(error), 'error');
			}
		}
	}
}

function* deleteMailingListRequestWorker(action: PayloadAction<TDeleteMailingListRequestPayload>) {
	const { payload } = action;

	try {
		yield call(api.messages.deleteMailingList, payload);
		notificationContainer('сообщение успешно удалено!', 'success');

		yield put(getMailingListRequest({}));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (axios.isAxiosError(error)) {
				notificationContainer(String(error), 'error');
			}
		}
	}
}

export function* onboardingMessagesSaga() {
	yield takeEvery(getOnboardingMessagesRequest.type, getOnboardingMessagesWorker);
	yield takeEvery(postOnboardingMessagesRequest.type, postOnboardingMessagesWorker);
	yield takeEvery(onboardingMessagesTrySendRequest.type, onboardingMessagesTrySendWorker);

	yield takeEvery(onboardingMessagesUpRequest.type, onboardingMessagesUpWorker);
	yield takeEvery(onboardingMessagesDownRequest.type, onboardingMessagesDownWorker);
	yield takeEvery(onboardingMessagesDeleteRequest.type, deleteOnboardingMessagesWorker);

	yield takeEvery(getNPSRequest.type, getNPSRequestWorker);
	yield takeEvery(setNPSRequest.type, setNPSRequestWorker);

	yield takeEvery(getNPSChapters.type, getNPSChaptersWorker);

	yield takeEvery(startNewSurveyRequest.type, startNewSurveyWorker);
	yield takeEvery(sendNotificationRequest.type, sendNotificationWorker);

	yield takeEvery(sendToAllRequest.type, sendToAllWorker);
	yield takeEvery(sendToUserRequest.type, sendToUserWorker);

	yield takeEvery(getMailingListRequest.type, getMailingListRequestWorker);
	yield takeEvery(getMailingListMessageRequest.type, getMailingListMessageRequestWorker);
	yield takeEvery(getMailingListHistoryRequest.type, getMailingListHistoryRequestWorker);
	yield takeEvery(postMailingListRequest.type, postMailingListRequestWorker);
	yield takeEvery(deleteMailingListRequest.type, deleteMailingListRequestWorker);
}
