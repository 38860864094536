import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get } from 'http';
import { stat } from 'fs';
import {
	TGroupsStore,
	TGroupsRequestPayload,
	TGroupsResponsePayload,
	TUpdatesGroupRequestPayload,
	TSetGroupControlRequestPayload,
	TGroupsFilterRequestPayload,
	TGroupsFilterResponsePayload,
	TGroupFilterValuesResponsePayload,
	TGroupEditRequestPayload,
} from './types';
// ==========================================:
export const initialState: TGroupsStore = {
	groups: null,
	groupsLoader: false,
	groupsFilter: null,
	groupsFilterLoader: false,
	groupFilterValues: null,
	editGroupLoader: false,
};

// ==========================================:
const Groups = createSlice({
	name: '@@groups',
	initialState,
	reducers: {
		getGroupsRequest: (state: TGroupsStore, action: PayloadAction<TGroupsRequestPayload>) => {
			const groupsRequestState = state;
			groupsRequestState.groupsLoader = true;
		},
		getGroupsSuccess: (state: TGroupsStore, action: PayloadAction<TGroupsResponsePayload>) => {
			const { payload } = action;
			const groupsSuccessState = state;
			groupsSuccessState.groups = payload;
			groupsSuccessState.groupsLoader = false;
		},
		updateGroupRequest: (
			state: TGroupsStore,
			action: PayloadAction<TUpdatesGroupRequestPayload>,
		) => {},
		updateUsersRequest: (
			state: TGroupsStore,
			action: PayloadAction<TUpdatesGroupRequestPayload>,
		) => {},
		updateAllUsersRequest: (state: TGroupsStore) => {},
		updateAllGroupsRequest: (state: TGroupsStore) => {},
		setGroupControlRequest: (
			state: TGroupsStore,
			action: PayloadAction<TSetGroupControlRequestPayload>,
		) => {},
		resetGroupControlRequest: (
			state: TGroupsStore,
			action: PayloadAction<TSetGroupControlRequestPayload>,
		) => {},
		getGroupsFilterRequest: (
			state: TGroupsStore,
			action: PayloadAction<TGroupsFilterRequestPayload>,
		) => {
			const groupsFilterRequestState = state;
			groupsFilterRequestState.groupsFilterLoader = true;
		},
		getGroupsFilterSuccess: (
			state: TGroupsStore,
			action: PayloadAction<TGroupsFilterResponsePayload>,
		) => {
			const { payload } = action;
			const groupsFilterSuccessState = state;
			groupsFilterSuccessState.groupsFilter = payload;
			groupsFilterSuccessState.groupsFilterLoader = false;
		},
		getGroupsFilterValuesRequest: (state: TGroupsStore) => {},
		getGroupsFilterValuesSuccess: (
			state: TGroupsStore,
			action: PayloadAction<TGroupFilterValuesResponsePayload>,
		) => {
			const { payload } = action;
			const groupsFilterValuesSuccessState = state;
			groupsFilterValuesSuccessState.groupFilterValues = payload;
		},
		editGroupRequest: (state: TGroupsStore, action: PayloadAction<TGroupEditRequestPayload>) => {
			const editGroupRequestState = state;
			editGroupRequestState.editGroupLoader = true;
		},
		editGroupSuccess: (state: TGroupsStore) => {
			const editGroupSuccessState = state;
			editGroupSuccessState.editGroupLoader = false;
		},
		assetsInitState: () => initialState,
	},
});

export default Groups.reducer;
export const {
	getGroupsRequest,
	getGroupsSuccess,
	updateGroupRequest,
	updateUsersRequest,
	updateAllUsersRequest,
	updateAllGroupsRequest,
	setGroupControlRequest,
	resetGroupControlRequest,
	getGroupsFilterRequest,
	getGroupsFilterSuccess,
	getGroupsFilterValuesRequest,
	getGroupsFilterValuesSuccess,
	editGroupRequest,
	editGroupSuccess,
	assetsInitState,
} = Groups.actions;
